import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useLinesInfo = (currentPage, pageSize, searchValue, projectId) => {
  const data = useQuery(
    ["linesAllInfoList", currentPage, pageSize, searchValue, projectId],
    () =>
      axiosClient.get(
        `/Line/GetLinesAllInfoList?currentPage=${currentPage}&pageSize=${pageSize}&searchValue=${searchValue}&projectId=${projectId}`
      )
  );

  return { data };
};
export default useLinesInfo;
