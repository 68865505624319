import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useOptions = (id) => {
  const data = useQuery(
    ["options", id],
    () => axiosClient.get(`/Setting/GetSettingList?groupSettingId=${id}`),
    { enabled: id !== null }
  );

  return { data: data?.data?.data };
};
export default useOptions;
