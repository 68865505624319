import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useStates = (countryId) => {
  const data = useQuery(["statesList", countryId], () =>
    axiosClient.get(`/State/GetStateListByCountryId?countryId=${countryId}`)
  );

  return { data: data.data?.data };
};
export default useStates;
