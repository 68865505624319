import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

const { TextArea } = Input;

export const CustomTextArea = styled(TextArea)`
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || "50px"};
  border: 1px solid ${theme.colors.light4};
  border-radius: ${({ radius }) => radius || "12px"};
  padding: 8px 16px;
  opacity: 1;

  && {
    &.ant-input,
    &.ant-input-affix-wrapper {
      color: ${theme.colors.grey};
      size: 16px;

      &::placeholder {
        color: ${theme.colors.grey1};
      }

      &:focus {
        outline: 0;
        border: 1px solid ${theme.colors.light4} !important;
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${theme.colors.light4} !important;
        box-shadow: initial;
      }
    }

    &.ant-input-affix-wrapper {
      &-focused {
        outline: 0;
        border: 1px solid ${theme.colors.light4} !important;
        box-shadow: initial;
      }

      input {
        &::placeholder {
          color: ${theme.colors.grey1};
        }
      }
    }
  }
`;
