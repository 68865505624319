import React from "react";
import { Div } from "../../../styles/Common";
import trash from "../../../assets/icons/trash-salmon.svg";

export const TrashAllButton = ({ handleDelete }) => {
  return (
    <Div
      width="max-content" 
      onClick={(event) => {
        event.stopPropagation();
        handleDelete();
      }} 
      style={{ cursor: "pointer" }}
    >
      <img alt="trash" src={trash} />
    </Div>
  )
};