import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Div, Text } from "../../styles/Common";
import { ModalNotes } from "../../components/Documents/ModalNotes";
import { Link } from "../../components/Link";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import back from "../../assets/icons/arrow-left.svg";
import notesIcon from "../../assets/icons/Notes.svg";
import { dateFormat } from "../../utilities/helpers";
import { Collapse } from "../../components/Collapse";
import useUpdateDocumentValue from "./hooks/useUpdateDocumentValues";
import useGetDocumentDetail from "./hooks/useGetDocumentDetail";
import useGetDocumentTypeInfo from "./hooks/useGetDocumentTypeInfo";
import { GeneralPanel } from "./components/generalPanel";
import { ItemsPanel } from "./components/itemsPanel";
import { ItemsSubPanel } from "./components/itemsSubPanel";
import { TrashButton } from "./components/trashButton";
import { ModalSuccess } from "./components/modalSuccess";
import { PdfViewer } from "./components/pdfViewer";
import { ContentContainer, FloatingCard, MainContainer } from "./styles";
import { TrashAllButton } from "./components/trashAllButton";

export const DocumentsDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id, docId } = useParams();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectAFE = new URLSearchParams(search).get("projectAFE");
  const docTypeId = new URLSearchParams(search).get("docTypeId");
  const update = useUpdateDocumentValue();
  const { data, isSuccess, isLoading } = useGetDocumentDetail(docId);
  const {
    data: dataDocType,
    isSuccess: isSuccessDocType,
    isLoading: isLoadingDocType,
  } = useGetDocumentTypeInfo(docTypeId);
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState(null);
  const [documentUrl, setDocumentUrl] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [documentFlag, setDocumentFlag] = useState(false);
  const [documentTypeInfo, setDocumentTypeInfo] = useState({});
  const [documentTypeValues, setDocumentTypeValues] = useState({});
  const [itemPanels, setItemPanels] = useState([]);
  const [addItem, setAddItem] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [panels, setPanels] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleBack = () => {
    navigate(
      `/documents/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  const openNotes = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (isSuccess) {
      const resp = data?.data.documentValues;
      setDocumentUrl(resp.documentUrl);
      setCreationDate(resp.creationDate);
      setDocumentFlag(resp.documentFlag);
      setNotes(resp.notes);
      setDocumentTypeValues(resp);
    }
  }, [isSuccess, data]);

  const handleDelete = (index) => {
    const newDocumentTypeInfo = { ...documentTypeInfo };
    const panels = [...itemPanels];
    const newItems = newDocumentTypeInfo.itemsInformation.filter(
      (ele, indx) => indx !== index
    );
    const newPanels = panels.filter((ele, indx) => indx !== index);

    setDocumentTypeInfo({ ...newDocumentTypeInfo, itemsInformation: newItems });
    setItemPanels([...newPanels]);
  };

  const handleChangeItems = (event, index, indxArr) => {
    const { value } = event.target;
    const newDocumentTypeInfo = JSON.parse(JSON.stringify(documentTypeInfo));
    const newItemsInfo = newDocumentTypeInfo.itemsInformation;
    const sortedArrayOfArrays = newItemsInfo?.map(innerArray => _.orderBy(innerArray, ['order'], ['asc']));

    sortedArrayOfArrays[indxArr][index]["value"] = value;

    setDocumentTypeInfo((prevState) => ({
      ...prevState,
      itemsInformation: sortedArrayOfArrays,
    }));

    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    const sortedArrayOfArrays = documentTypeInfo?.itemsInformation?.map(innerArray => _.orderBy(innerArray, ['order'], ['asc']));
    const itemsArray = sortedArrayOfArrays || [];
    const panels = itemsArray.map((item, index) => ({
      key: (index + 1).toString(),
      header: `Item ${index + 1}`,
      extra: (
        <TrashButton handleDelete={handleDelete} index={index} />
      ),
      panel: (
        <ItemsSubPanel
          itemsInfo={item}
          handleChangeItems={handleChangeItems}
          indx={index}
        />
      ),
    }));

    setItemPanels(panels);
  }, [documentTypeInfo]);

  useEffect(() => {
    if (addItem) {
      const sortedArrayOfArrays = documentTypeInfo?.itemsInformation?.map(innerArray => _.orderBy(innerArray, ['order'], ['asc']));
      const itemsArray = sortedArrayOfArrays || [];
      const panels = itemsArray.map((item, index) => ({
        key: (index + 1).toString(),
        header: `Item ${index + 1}`,
        extra: (
          <TrashButton handleDelete={handleDelete} index={index} />
        ),
        panel: (
          <ItemsSubPanel
            itemsInfo={item}
            handleChangeItems={handleChangeItems}
            indx={index}
          />
        ),
      }));

      setItemPanels(panels);
      setAddItem(false);
    }
  }, [addItem, documentTypeInfo]);

  const handleChangeGeneral = (event, index) => {
    const { value } = event.target;
    const newDocumentTypeInfo = JSON.parse(JSON.stringify(documentTypeInfo));
    const newGeneralInfo = [...newDocumentTypeInfo.generalInformation];
    const orderArray = _.orderBy(newGeneralInfo, ['order'], ['asc']);

    orderArray[index]["value"] = value;
    setDocumentTypeInfo({
      ...newDocumentTypeInfo,
      generalInformation: orderArray,
    });
  };

  const handleDeleteAll = () => {
    const newDocumentTypeInfo = { ...documentTypeInfo };
    const firstItem = newDocumentTypeInfo.itemsInformation[0];

    setDocumentTypeInfo({ ...newDocumentTypeInfo, itemsInformation: [firstItem] });
  };

  useEffect(() => {
    if (isSuccess && isSuccessDocType) {
      const respValues = data?.data.documentValues;
      const resp = dataDocType?.data.documentTypeValue;
      const emptyGeneral = respValues.documentGeneralItems?.length === 0 || respValues.documentGeneralItems === null;
      const emptyItems = (respValues.documentItems?.length === 1 && respValues.documentItems[0]?.length === 0) || 
        respValues.documentItems === null;

      setDocumentTypeInfo({
        ...resp,
        generalInformation:
          emptyGeneral
            ? resp.generalInformation
            : respValues.documentGeneralItems,
        itemsInformation:
          emptyItems
            ? [resp.itemsInformation]
            : respValues.documentItems,
      });

      if (!emptyItems) {
        const sortedArrayOfArrays = respValues.documentItems?.map(innerArray => _.orderBy(innerArray, ['order'], ['asc']));
        const itemsArray = sortedArrayOfArrays || [];
        const panels = itemsArray.map((itemArray, index) => ({
          key: (index + 1).toString(),
          header: `Item ${index + 1}`,
          extra: (
            <TrashButton handleDelete={handleDelete} index={index} />
          ),
          panel: (
            <ItemsSubPanel
              itemsInfo={itemArray}
              handleChangeItems={handleChangeItems}
              indx={index}
            />
          ),
        }));

        setItemPanels(panels);
      }
    }
  }, [isSuccess, isSuccessDocType, data, dataDocType]);

  useEffect(() => {
    if (Object.keys(documentTypeInfo).length > 0) {
      const filteredPanels = [];
      const orderArray = _.orderBy(documentTypeInfo.generalInformation, ['order'], ['asc']);
      const orderArrayOfArrays = documentTypeInfo.itemsInformation?.map(innerArray => _.orderBy(innerArray, ['order'], ['asc']));

      if (documentTypeInfo.generalInformation?.length > 0) {
        filteredPanels.push({
          key: "MainPanel-1",
          header: "General Information",
          panel: (
            <GeneralPanel
              generalInfo={orderArray}
              handleChangeGeneral={handleChangeGeneral}
            />
          ),
        });
      };

      if (documentTypeInfo.itemsInformation[0]?.length > 0) {
        filteredPanels.push({
          key: "MainPanel-2",
          header: "Items Information",
          extra: documentTypeInfo.itemsInformation?.length > 1 && (
            <TrashAllButton handleDelete={handleDeleteAll} />
          ),
          panel: (
            <ItemsPanel
              itemsInfo={orderArrayOfArrays}
              handleChangeItems={handleChangeItems}
              handleAddItem={handleAddItem}
              itemPanels={itemPanels}
            />
          ),
        });
      };

      setPanels(filteredPanels);
    }
  }, [documentTypeInfo, itemPanels]);

  const handleAddItem = () => {
    setAddItem(true);
    const newDocumentTypeInfo = JSON.parse(JSON.stringify(documentTypeInfo));
    const newItem = newDocumentTypeInfo.itemsInformation[0].map((ele) => ({
      itemId: ele.itemId,
      value: "",
      name: ele.name,
    }));
    const newItemsInfo = [
      ...newDocumentTypeInfo.itemsInformation,
      [...newItem],
    ];
    setDocumentTypeInfo({
      ...newDocumentTypeInfo,
      itemsInformation: newItemsInfo,
    });
  };

  const handleUpdate = () => {
    const payload = {
      ...documentTypeValues,
      documentFlag: documentFlag,
      documentGeneralItems: [...documentTypeInfo?.generalInformation],
      documentItems: [...documentTypeInfo?.itemsInformation],
    };

    update.reset();
    update.mutate(
      { ...payload },
      {
        onSuccess: () => {
          setOpenModalSuccess(true);
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      }
    );
  };

  const handleClose = () => {
    setOpenModalSuccess(false);
    navigate(
      `/documents/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  if (isLoading || isLoadingDocType) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.dark} />
      </Div>
    );
  }

  const emptyPanels = documentTypeInfo.generalInformation?.length === 0 && documentTypeInfo.itemsInformation[0]?.length === 0;

  return (
    <Container>
      <ModalNotes
        openModal={openModal}
        setOpenModal={setOpenModal}
        notes={notes}
        docId={docId}
      />
      <ModalSuccess openModal={openModalSuccess} handleClose={handleClose} />
      <Div m="0 0 24px 0">
        <Link
          onClick={handleBack}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div direction="column">
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.dark}
          weight={theme.fonts.weight.bold}
        >
          Document Detail
        </Text>
        <Div style={{ alignSelf: "end" }}>
          <Link
            icon={
              <img alt="notes" src={notesIcon} style={{ marginRight: "8px" }} />
            }
            onClick={openNotes}
          >
            Notes
          </Link>
        </Div>
      </Div>
      <MainContainer>
        <Div width="70%" style={{ position: "relative" }}>
          <FloatingCard>
            <Card
              width="100%"
              height="65vh"
              radius="15px"
              shadow={"0px 9px 20px #00000008"}
            >
              <Div width="100%" direction="column" gap="12px">
                <Div width="100%" height="10%">
                  <Text color={theme.colors.grey2} size={theme.fonts.size.sm}>
                    Upload Date:{" "}
                    <span style={{ color: theme.colors.dark }}>
                      {dateFormat(creationDate)}
                    </span>
                  </Text>
                </Div>
                <PdfViewer documentUrl={documentUrl} />
              </Div>
            </Card>
          </FloatingCard>
        </Div>
        <ContentContainer>
          <Collapse
            panels={panels}
            detail={true}
            documentFlag={documentFlag}
            setDocumentFlag={setDocumentFlag}
            handleUpdate={handleUpdate}
            isLoading={update.isLoading}
          />
        </ContentContainer>
      </MainContainer>
    </Container>
  );
};
