import styled from "styled-components";
import { theme } from "../../styles/theme";

export const WrapperLayout = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
`;

export const Navbar = styled.div`
  display: flex;
  height: 70px;
  width: 100%;
  padding: 0 97px 0 97px;
  justify-content: space-between;
  align-items: center;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000008;
  border-radius: 0px 0px 20px 20px;
  opacity: 1;
`;
