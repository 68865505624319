import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateDocumentValue = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put("/DocumentType/UpdateDocumentValue", formData);
  });
  return mutation;
};
export default useUpdateDocumentValue;
