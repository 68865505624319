import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSheetNumber = (lineId) => {
  const data = useQuery(
    ["sheetNumber", lineId],
    () =>
      axiosClient.get(`/Line/GetSheetNumberAndSheetsByLineId?lineId=${lineId}`),
    { enabled: Boolean(lineId) }
  );

  return data;
};
export default useSheetNumber;
