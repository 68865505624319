import React, { useState } from "react";
import { Text } from "../../styles/Common";
import { CustomSelect } from "./styles";
import { theme } from "../../styles/theme";
import arrowDown from "../../assets/icons/arrow-down.svg";

export const Select = ({
  options,
  defaultValue,
  placeholder,
  helper,
  noborder,
  paddingx,
  islangselector,
  showSearch,
  notFoundContent,
  filterOption,
  optionFilterProp,
  optionLabelProp,
  error,
  disabled,
  onChange,
  onSearch,
  height,
  ...rest
}) => {
  const [iconName, setIconName] = useState("arrow-down");

  const handleFocusOnSearch = () => showSearch && setIconName("search");
  const handleBlurOnSearch = () => showSearch && setIconName("arrow-down");

  return (
    <div>
      <CustomSelect
        options={options}
        status={error ? "error" : ""}
        defaultValue={defaultValue}
        placeholder={placeholder}
        suffixIcon={<img alt="arrow" src={arrowDown} />}
        noborder={noborder}
        paddingx={paddingx}
        islangselector={islangselector ? 1 : 0}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        disabled={disabled ? 1 : 0}
        onChange={onChange}
        onSearch={onSearch}
        height={height}
        onFocus={handleFocusOnSearch}
        onBlur={handleBlurOnSearch}
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.sm} color={theme.colors.salmon} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
