import { useQueries } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const usePropertiesList = (items) => {
  const queries = items.map((item) => ({
    queryKey: ["propertiesList", item],
    queryFn: () => axiosClient.get(`/Item/GetPropertiesList?item=${item}`),
  }));
  const data = useQueries(queries);

  return data;
};
export default usePropertiesList;
