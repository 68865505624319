import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateTemplate = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put("/DocumentType/UpdateDocumentType", formData);
  });
  return mutation;
};
export default useUpdateTemplate;
