import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginWrapper } from "./styles";
import { Row, Col, Div, Text } from "../../styles/Common";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Password } from "../../components/Password";
import { theme } from "../../styles/theme";
import { validateLoginForm } from "../../utilities/validations";
import logo from "../../assets/images/logo.png";
import useAuth from "./hooks/useAuth";
import { Alert } from "antd";
import { setLocalUser } from "../../core/services/axiosInstance";
import { Link } from "../../components/Link";

export const Login = () => {
  const navigate = useNavigate();
  const authentication = useAuth();
  const { isLoading } = authentication;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [errorForm, setErrorForm] = useState({
    email: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newLoginForm = loginForm;
    newLoginForm[id] = value;
    setLoginForm(newLoginForm);
    setForceUpdate(!forceUpdate);
  };

  const handleLogin = () => {
    const validation = validateLoginForm.validate(loginForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      authentication.reset();
      authentication.mutate(loginForm, {
        onSuccess: (res) => {
          setLocalUser(res.data.token);
          setErrorMessage("");
          navigate("/projects");
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      });
    }
  };

  const handleReset = () => {
    navigate("/reset-password-request");
  };

  return (
    <LoginWrapper>
      <Row width="1200px" height="700px">
        <Col width="50%" height="100%">
          <img alt="logo" src={logo} width="336px" height="143px" />
        </Col>
        <Col width="50%" height="100%" align="center" justify="center">
          <Card width="380px">
            <Div direction="column">
              <Div direction="column" align="center" gap="5px" m="0 0 32px 0">
                <Text
                  color={theme.colors.dark2}
                  weight={theme.fonts.weight.bold}
                  size={theme.fonts.size.h3}
                >
                  Welcome
                </Text>
                <Text
                  color={theme.colors.grey}
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.default}
                >
                  Login to manage your projects
                </Text>
              </Div>
              <Div direction="column" gap="16px" width="287px">
                <Input
                  placeholder="Username"
                  id="email"
                  onChange={handleChange}
                  error={errorForm.email.error}
                  helper={errorForm.email.error ? errorForm.email.message : ""}
                />
                <Password
                  placeholder="Password"
                  id="password"
                  onChange={handleChange}
                  error={errorForm.password.error}
                  helper={
                    errorForm.password.error ? errorForm.password.message : ""
                  }
                  onPressEnter={handleLogin}
                />
              </Div>
              <Div justify="flex-end">
                <Link
                  size={theme.fonts.size.sm}
                  style={{
                    marginBottom: "35px",
                  }}
                  onClick={handleReset}
                >
                  Reset Password
                </Link>
              </Div>
              {errorMessage !== "" && (
                <Div m="0 0 10px 0" justify="flex-end">
                  <Alert closable message={`${errorMessage}`} type="error" />
                </Div>
              )}
              <Div>
                <Button
                  onClick={handleLogin}
                  width="100%"
                  background={theme.colors.salmon}
                  loading={isLoading}
                >
                  Login
                </Button>
              </Div>
            </Div>
          </Card>
        </Col>
      </Row>
    </LoginWrapper>
  );
};
