import React from "react";
import { CustomLink } from "./styles";

export const Link = ({ children, color, onClick, ...rest }) => {
  return (
    <CustomLink type="link" color={color} onClick={onClick} {...rest}>
      {children}
    </CustomLink>
  );
};
