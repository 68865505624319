import React from "react";
import PropTypes from "prop-types";

import DrawerHeader from "./Header";

import { CustomDrawer } from "./styles";
import close from "../../assets/icons/close.svg";

export const Drawer = ({ closelabel, children, ...rest }) => {
  return (
    <CustomDrawer
      placement="left"
      closelabel={closelabel}
      closeIcon={<img alt="close" src={close} />}
      {...rest}
    >
      {children}
    </CustomDrawer>
  );
};

Drawer.propTypes = {
  closelabel: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Drawer.Header = DrawerHeader;
