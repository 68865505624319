import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 100%; 
  margin: 29px 0 18px 0; 
  gap: 26px;
`;

export const FloatingCard = styled.div`
  position: fixed;
  left: 97px;
  z-index: 5;
  min-width: 60%;
`;

export const ContentContainer = styled.div`
  max-width: 30%;
  flex: 1;
  overflow-y: auto;
`;