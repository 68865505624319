import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDocumentTypeInfo = (documentId) => {
  const data = useQuery(
    ["documentTypeInfo", documentId],
    () =>
      axiosClient.get(`/DocumentType/GetDocumentTypeValuesById?documentTypeId=${documentId}`),
    { enabled: !!documentId, refetchOnWindowFocus: false }
  );

  return data;
};
export default useGetDocumentTypeInfo;
