import React from "react";
import { Text } from "../../styles/Common";
import { CustomInput } from "./styles";
import { theme } from "../../styles/theme";

export const Input = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  border,
  placeColor,
  label,
  value,
  ...rest
}) => {
  return (
    <div>
      {label && value !== null && value !== "" && (
        <Text mb="2px">
          {label}
        </Text>
      )}
      <CustomInput
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        width={width}
        autoComplete="off"
        border={border}
        placeColor={placeColor}
        value={value}
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.sm} color={theme.colors.salmon} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
