import React from "react";
import { Div } from "../../../styles/Common";
import { Input } from "../../../components/Input";

export const ItemsSubPanel = ({ itemsInfo, handleChangeItems, indx }) => {
  return (
    <Div direction="column" gap="16px">
      {itemsInfo.map((item, index) => (
        <Input
          key={`${indx}-${index}`}
          id={`${item.name}`}
          label={`${item.name}`}
          placeholder={`${item.name}`}
          value={item.value || ""}
          onChange={(e) => handleChangeItems(e, index, indx)}
        />
      ))}
    </Div>
  );
};
