import styled from "styled-components";
import backImage from "../../assets/images/background-login.png";

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: url(${backImage}) no-repeat;
  background-position: center;
  justify-content: center;
`;
