import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useItems = () => {
  const data = useQuery(["itemsList"], () =>
    axiosClient.get(`/Item/GetItemsAllInfoList`)
  );

  return { data: data?.data?.data };
};
export default useItems;
