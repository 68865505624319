import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetTemplateInfo = (id) => {
  const data = useQuery(
    ["digDocumentList", id],
    () =>
      axiosClient.get(
        `/DocumentType/GetDocumentTypeById?documentTypeId=${id}`
      ),
      { enabled: !!id }
  );

  return data;
};
export default useGetTemplateInfo;
