import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCountries = () => {
  const data = useQuery(["countriesList"], () =>
    axiosClient.get("/Country/GetCountryList")
  );

  return { data: data.data?.data };
};
export default useCountries;
