import axios from "axios";
import momentTimezone from "moment-timezone";
import { getUserInfo } from "../../utilities/helpers";

const pathName = window.location.href;

const axiosClient = axios.create({
  baseURL:
    pathName.includes("agreeable-moss") || pathName.includes("mps-jobbook")
      ? "https://client-mps-backend-api.azurewebsites.net/api"
      : "https://fraktal-jobbook-api.azurewebsites.net/api",
});

const refreshToken = () => {
  const user = getLocalUser();

  return axiosClient.post("/Auth/RefreshToken", {
    ...user,
    UserId: getUserInfo().id,
  });
};

const getLocalUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return user;
};

const deleteLocalUser = () => {
  localStorage.removeItem("user");
};

const setLocalUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const isRefresTokenURL = (url) => {
  return url.includes("/RefreshToken");
};

axiosClient.interceptors.request.use(
  (config) => {
    const user = getLocalUser();
    const isRefreshTokenUrl = isRefresTokenURL(config?.url);

    if (user) {
      if (isRefreshTokenUrl) {
        config.headers.Authorization = `Bearer ${user.RefreshTokenValue}`;
      } else {
        config.headers.Authorization = `Bearer ${user}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    console.log("response", error);
    if (error?.response) {
      // Access Token was expired
      const isErrorFromRefresh = isRefresTokenURL(
        error?.response?.request?.responseURL
      );

      if (
        error?.response?.status === 401 &&
        !originalConfig._retry &&
        !isErrorFromRefresh
      ) {
        originalConfig._retry = true;

        try {
          const response = await refreshToken();
          const user = await response.data;
          setLocalUser(user);

          axiosClient.defaults.headers.common.Authorization = `Bearer ${user.TokenValue}`;

          return axiosClient(originalConfig);
        } catch (_error) {
          deleteLocalUser();
          console.log("refreshTTokenresponse", _error);
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export { axiosClient, deleteLocalUser, setLocalUser, getLocalUser };
