import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProjectInfo = (id) => {
  const data = useQuery(
    ["getProjectInfo", id],
    () => axiosClient.get(`/Project/GetProjectById?projectId=${id}`),
    {
      enabled: id !== null,
    }
  );
  return data;
};
export default useGetProjectInfo;
