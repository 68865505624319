import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useResetPassword = () => {
  const mutation = useMutation((userForm) => {
    return axiosClient.post(`/Authentication/ResetPassword`, userForm);
  });
  return mutation;
};
export default useResetPassword;
