import React, { useState } from "react";
import { Navbar, WrapperLayout } from "./styles";
import { Div } from "../../styles/Common";
import logo from "../../assets/images/logo.png";
import logout from "../../assets/icons/logout.svg";
import { Link } from "../Link";
import { theme } from "../../styles/theme";
import { deleteLocalUser } from "../../core/services/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../utilities/helpers";

export const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { role } = getUserInfo();

  const handleLogout = () => {
    deleteLocalUser();
    navigate("/");
  };

  const handleGo = (url) => {
    navigate(`${url}`);
  };

  const items = [
    {
      label: "Home", 
      url: "/projects", 
      permission: true
    },
    {
      label: "Companies", 
      url: "/companies", 
      permission: role === "superAdmin"
    },
    {
      label: "Digitalization templates", 
      url: "/digitalization-documents", 
      permission: true
    },
  ];

  const color = (path) => {
    return pathname.includes(path) ? theme.colors.dark : theme.colors.grey1;
  };

  return (
    <WrapperLayout>
      <Navbar>
        <Div>
          <img alt="logo" src={logo} width="159px" height="63px" />
        </Div>
        <Div gap="48px">
          {items.map((ele, indx) => 
            ele.permission && (
              <Link 
                key={indx}
                onClick={() => handleGo(ele.url)} 
                color={color(ele.url)}
              >
                {ele.label}
              </Link>
            )
          )}
          <Div gap="8px">
            <Link onClick={handleLogout}>Logout</Link>
            <Link
              onClick={handleLogout}
              icon={<img alt="logout" src={logout} />}
            />
          </Div>
        </Div>
      </Navbar>
      {children}
    </WrapperLayout>
  );
};
