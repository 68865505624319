import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDigDocumentList = (currentPage, pageSize, searchValue, fieldName, ascendingOrder) => {
  const data = useQuery(
    ["digDocumentList", currentPage, pageSize, searchValue, fieldName, ascendingOrder],
    () =>
      axiosClient.get(
        `/DocumentType/GetDocumentTypePaginatedList?currentPage=${currentPage}&pageSize=${pageSize}&searchValue=${searchValue}&fieldName=${fieldName}&ascendingOrder=${ascendingOrder}`
      ),
  );

  return data;
};
export default useGetDigDocumentList;
