import Joi from "joi";
import tlds from "../../node_modules/@sideway/address/lib/tlds";

const validatePassword = {
  password: Joi.string()
    .required()
    .pattern(
      new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ),
};

export const validateEmailRequired = {
  email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .required(),
};

export const validateLoginForm = Joi.object({
  ...validateEmailRequired,
  ...validatePassword,
});

export const validateResetPassword = Joi.object({
  password: validatePassword.password,
  passwordConfirm: validatePassword.password,
});

export const validateNewProject = Joi.object({
  companyId: Joi.number().min(1).max(100).required(),
  ownerCompany: Joi.string().min(1).max(150).allow(null, ""),
  projectAFE: Joi.string().min(1).max(150).required(),
  projectName: Joi.string().min(1).max(150).required(),
  projectManager: Joi.string().min(0).max(150).allow(null, ""),
  stateId: Joi.number().min(0).max(150).allow(null, ""),
  address: Joi.string().min(0).max(150).allow(null, ""),
  addressZipCode: Joi.string().min(0).max(50).allow(null, ""),
  coordinates: Joi.string().min(0).max(150).allow(null, ""),
  coordinatesZipCode: Joi.string().min(0).max(50).allow(null, ""),
});

export const validateNewCompany = Joi.object({
  companyName: Joi.string().min(1).max(100).required(),
  adminName: Joi.string().min(1).max(100).required(),
  adminLastName: Joi.string().min(1).max(100).required(),
  adminEmail: validateEmailRequired.email,
  companyLogoUrl: Joi.string().allow(null, ""),
});

export const validateNewLine = Joi.object({
  lineNumber: Joi.string().min(1).max(100).required(),
  lineDiameter: Joi.number().required(),
  pressureId: Joi.number().min(0).max(100).allow(null, ""),
  description: Joi.string().min(0).max(100).allow(null, ""),
});

export const validateNewDocument = Joi.object({
  blobDocuments: Joi.array().min(1).required(),
  documentTypeId: Joi.number().required(),
  projectId: Joi.number().required(),
  notes: Joi.string().allow(null, ""),
});
