import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteTemplate = () => {
  const mutation = useMutation((id) => {
    return axiosClient.delete(`/DocumentType/DeleteDocumentTypeById?documentTypeId=${id}`);
  });
  return mutation;
};
export default useDeleteTemplate;
