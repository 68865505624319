import React from "react";
import { Div, Text } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { ItemsCollapse } from "../../../components/Documents/CollapseItems";
import addIcon from "../../../assets/icons/add.svg";
import { theme } from "../../../styles/theme";
import { Link } from "../../../components/Link";

export const ItemsPanel = ({
  itemsInfo,
  handleChangeItems,
  handleAddItem,
  itemPanels,
}) => {
  return (
    <Div direction="column" gap="16px">
      {itemsInfo?.length === 1 &&
        itemsInfo.map((arr, indx) => (
          <Div direction="column" gap="16px" key={indx}>
            <Text color={theme.colors.grey1} size={theme.fonts.size.default}>
              {`Item ${indx + 1}`}
            </Text>
            {arr.map((item, index) => (
              <Input
                key={`itemsPanel ${index}`}
                id={`${item.name}`}
                label={`${item.name}`}
                placeholder={`${item.name}`}
                value={item.value || ""}
                onChange={(e) => handleChangeItems(e, index, indx)}
              />
            ))}
          </Div>
        ))}

      {itemsInfo?.length > 1 && <ItemsCollapse panels={itemPanels} />}

      <Link
        icon={<img alt="add" src={addIcon} />}
        onClick={() => handleAddItem(itemsInfo)}
      >
        Add New Item Line
      </Link>
    </Div>
  );
};
