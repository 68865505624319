import React from "react";
import { Div } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Table } from "../Table";

export const ItemsTable = (tableColumns, data) => {
  return (
    <Div m="32px 0 0 0">
      <Table
        columns={tableColumns}
        data={data}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        locale={{
          emptyText: "No data",
        }}
        fontSize={theme.fonts.size.xs}
        small={true}
      />
    </Div>
  );
};
