import React, { useState } from "react";
import { Col, Div, Row, Text } from "../../styles/Common";
import { LoginWrapper } from "../Login/styles";
import logo from "../../assets/images/logo.png";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import { Password } from "../../components/Password";
import useResetPassword from "./hooks/useResetPassword";
import { validateResetPassword } from "../../utilities/validations";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { Alert } from "antd";
import { Link } from "../../components/Link";
import success from "../../assets/icons/success.svg";

export const ResetPassword = () => {
  const resetPassword = useResetPassword();
  const { isLoading } = resetPassword;
  const navigate = useNavigate();
  const { search } = useLocation();
  const hash = new URLSearchParams(search).get("hash");
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [errorForm, setErrorForm] = useState({
    password: {
      error: false,
      message: "",
    },
    passwordConfirm: {
      error: false,
      message: "",
    },
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newPasswordForm = passwordForm;
    newPasswordForm[id] = value;
    setPasswordForm(newPasswordForm);
    setForceUpdate(!forceUpdate);
  };

  const handleReset = () => {
    const validation = validateResetPassword.validate(passwordForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      resetPassword.reset();
      resetPassword.mutate(
        { ...passwordForm, hash },
        {
          onSuccess: () => {
            setShowSuccess(true);
            setErrorMessage("");
          },
          onError: (err) => {
            setErrorMessage(err.response.data.Errors[0]);
          },
        }
      );
    }
  };
  const handleClose = () => {
    setShowSuccess(false);
    navigate("/");
  };

  const handleLogin = () => {
    navigate("/");
  };

  return (
    <LoginWrapper>
      <Modal
        open={showSuccess}
        onCancel={handleClose}
        align={"center"}
        padding={36}
      >
        <Modal.Header
          icon={success}
          iconBackground={theme.colors.dark}
          iconWidth={"24px"}
          iconHeight={"24px"}
          align={"-webkit-center"}
          padding="16px 0 16px 0"
          title={"New Password Successful"}
        />
        <Modal.Body margin="16px 0 24px 0" maxHeight={200}>
          <Text
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.medium}
            color={theme.colors.grey1}
          >
            You can now log in with your email and new password
          </Text>
        </Modal.Body>
      </Modal>
      <Row width="1200px" height="700px">
        <Col width="50%" height="100%">
          <img alt="logo" src={logo} width="336px" height="143px" />
        </Col>
        <Col width="50%" height="100%" align="center" justify="center">
          <Card width="380px">
            <Div direction="column" align="center" width="100%">
              <Text
                color={theme.colors.dark2}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h3}
                mb="10px"
              >
                Reset Password
              </Text>
              <Text
                color={theme.colors.grey}
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.xs}
                mb="28px"
                style={{ textAlign: "justify" }}
              >
                Password must be at least 8 characters long and include at least
                one lowercase letter, one number, one uppercase letter, and one
                special character such as #?!@$%^&*-
              </Text>
              <Div direction="column" gap="16px" width="287px">
                <Password
                  placeholder="New Password"
                  id="password"
                  onChange={handleChange}
                  error={errorForm.password.error}
                  helper={
                    errorForm.password.error ? errorForm.password.message : ""
                  }
                />
                <Password
                  placeholder="Password Again"
                  id="passwordConfirm"
                  onChange={handleChange}
                  error={errorForm.passwordConfirm.error}
                  helper={
                    errorForm.passwordConfirm.error
                      ? errorForm.passwordConfirm.message
                      : ""
                  }
                />
              </Div>
              {errorMessage !== "" && (
                <Div
                  m="20px 0 0 0"
                  width="100%"
                  justify="flex-end"
                  style={{ alignSelf: "end" }}
                >
                  <Alert closable message={`${errorMessage}`} type="error" />
                </Div>
              )}
              <Button
                onClick={handleReset}
                width="100%"
                loading={isLoading}
                background={theme.colors.salmon}
                style={{ marginTop: "32px" }}
              >
                Reset Password
              </Button>
              <Div>
                <Link size={theme.fonts.size.sm} onClick={handleLogin}>
                  Back to Login
                </Link>
              </Div>
            </Div>
          </Card>
        </Col>
      </Row>
    </LoginWrapper>
  );
};
