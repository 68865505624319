import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useSettingList = () => {
  const data = useQuery(["settingList"], () =>
    axiosClient.get("/GroupSetting/GetGroupSettingList")
  );

  return { data: data?.data?.data };
};
export default useSettingList;
