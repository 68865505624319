import styled from "styled-components";
import { Switch } from "antd";
import { theme } from "../../styles/theme";

export const CustomSwitch = styled(Switch)`
  background-color: ${theme.colors.grey1};

  :where(.css-dev-only-do-not-override-sk7ap8).ant-switch:hover {
    background: ${theme.colors.grey1};
  }

  :where(
      .css-dev-only-do-not-override-sk7ap8
    ).ant-switch.ant-switch-checked:hover {
    background: ${theme.colors.salmon};
  }

  &.ant-switch-checked {
    background-color: ${theme.colors.salmon};
    .ant-switch-handle:before {
      background-color: ${theme.colors.white};
    }
  }
`;
