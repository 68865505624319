import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Container, Div, Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import searchIcon from "../../assets/icons/search.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ModalLine } from "../../components/Projects/ModalLine";
import { Link } from "../../components/Link";
import download from "../../assets/icons/download.svg";
import back from "../../assets/icons/arrow-left.svg";
import { Table } from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import useDebounce from "../../core/hooks/useDebounce";
import edit from "../../assets/icons/edit.svg";
import file from "../../assets/icons/file.svg";
import drawing from "../../assets/icons/drawing.svg";
import pdfIcon from "../../assets/icons/pdf-icon.svg";
import useLinesInfo from "./hooks/useLinesInfo";
import { Status } from "../../styles/Line";
import { toast } from "react-hot-toast";
import { downloadExcel, downloadFile } from "../../utilities/helpers";
import { axiosClient, getLocalUser } from "../../core/services/axiosInstance";
import { Tooltip } from "antd";

export const ProjectDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectAFE = new URLSearchParams(search).get("projectAFE");

  const [openModal, setOpenModal] = useState(false);
  const [dataLines, setDataLines] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const getLines = useLinesInfo(pages.current, perPage, searchValue, id);
  const { data } = getLines;
  const { data: dataLinesList, isLoading: loading } = data;
  const [lineId, setLineId] = useState(null);

  const handleEdit = (sheetId) => {
    navigate(
      `/projects/${id}/sheet/${sheetId}?projectName=${projectName}&projectAFE=${projectAFE}`
    );
  };

  const handleExportDrawing = async (
    sheetVersionId,
    lineNumber,
    sheetNumber
  ) => {
    await toast.promise(
      downloadFile(
        `/Item/ExportDrawing?sheetVersionId=${sheetVersionId}&projectId=${id}`,
        `${projectName}-${lineNumber}-${sheetNumber}-${sheetVersionId}.pdf`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  const handleExportTable = async () => {
    await toast.promise(
      downloadExcel(
        `/Line/ExportTable?projectId=${id}`,
        `${projectName}-${projectAFE}.xlsx`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  const handleExportProject = async () => {
    await toast.promise(
      downloadExcel(
        `/Project/ExportProject?projectId=${id}`,
        `${projectName}.zip`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  const handleExportProjectPdf = async () => {
    await toast.promise(
      downloadExcel(
        `/Project/ExportProjectPdf?projectId=${id}`,
        `${projectName}.pdf`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  const handleEditLine = (id) => {
    setLineId(id);
    setOpenModal(true);
  };

  useEffect(() => {
    if (dataLinesList?.data?.lines?.items.length > 0) {
      const dataTable = dataLinesList.data.lines.items.map((ele, i) => ({
        ...ele,
        key: `${ele.sheetId}`,
        actions: [
          {
            key: `1${i}`,
            id: ele.sheetId,
            name: "Export Drawing",
            icon: drawing,
            color: theme.colors.dark,
            onClick: () =>
              handleExportDrawing(
                ele.sheetVersionId,
                ele.lineNumber,
                ele.sheetNumber
              ),
          },
          {
            key: `1${i}`,
            id: ele.sheetId,
            name: "Edit",
            icon: edit,
            color: theme.colors.salmon,
            onClick: () => handleEdit(ele.sheetId),
          },
          {
            key: `1${i}`,
            id: ele.lineId,
            name: "Edit line",
            icon: edit,
            color: theme.colors.salmon,
            onClick: () => handleEditLine(ele.lineId),
          },
        ],
      }));

      setDataLines(dataTable);
      setTotalItems(dataLinesList?.data?.lines.totalItems);
    } else {
      setDataLines([]);
    }
  }, [dataLinesList?.data]);

  const handleAllVersions = (sheetId, lineNumber) => {
    navigate(
      `/projects/${id}/sheet-versions/${sheetId}?projectName=${projectName}&lineNumber=${lineNumber}&projectAFE=${projectAFE}`
    );
  };

  const tableColumns = [
    {
      title: "Line Number",
      dataIndex: "lineNumber",
      key: "lineNumber",
      width: 180,
    },
    {
      title: "State",
      dataIndex: "isPublished",
      key: "isPublished",
      width: 220,
      render: (published) => (
        <Div direction="row" align="center" gap="8px">
          <Status
            style={{
              backgroundColor: published
                ? theme.colors.green
                : theme.colors.salmon,
            }}
          />
          {published ? "Published" : "Not published"}
        </Div>
      ),
    },
    {
      title: "Sheet #",
      dataIndex: "sheetNumber",
      key: "sheetNumber",
      width: 150,
    },
    {
      title: "Version #",
      dataIndex: "sheetVersion",
      key: "sheetVersion",
      width: 150,
      render: (number, item) => (
        <div>
          {number ? number : "-"}
          {number > 1 ? (
            <Link
              onClick={() => handleAllVersions(item.sheetId, item.lineNumber)}
              color={theme.colors.salmon}
              size={theme.fonts.size.sm}
              style={{ marginLeft: "20px" }}
            >
              All Versions
            </Link>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "left",
      width: 400,
      render: (actions) => (
        <Div gap="80px">
          {actions.map((action, i) => (
            <Link
              key={i}
              onClick={action.onClick}
              color={action.color}
              icon={
                <img
                  alt={`${action.icon}`}
                  src={action.icon}
                  style={{ marginRight: "8px" }}
                />
              }
              styles={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {action.name}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleProjects = () => {
    navigate("/projects");
  };

  const handleChangeTable = (pagination) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
  };

  const handleDocuments = () => {
    navigate(
      `/documents/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  return (
    <Container style={{ padding: "0px 97px 29px 97px" }}>
      <ModalLine
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={data}
        id={id}
        projectAFE={projectAFE}
        projectName={projectName}
        lineId={lineId}
        setLineId={setLineId}
      />
      <Div m="0 0 5px 0">
        <Link
          onClick={handleProjects}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" justify="space-between" align="center">
        <Div
          direction="column"
          gap="6px"
          style={{
            width: "350px",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            {projectName}
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
            {`Project AFE: ${projectAFE}`}
          </Text>
        </Div>
        <Div gap="20px" align="center">
          <Tooltip title="Export Table">
            <Link
              color={theme.colors.dark}
              icon={
                <img alt="file" src={file} style={{ marginRight: "2px" }} />
              }
              onClick={handleExportTable}
            />
          </Tooltip>
          <Tooltip title="Export Drawings">
            <Link
              color={theme.colors.salmon}
              icon={
                <img
                  alt="download"
                  src={pdfIcon}
                  style={{ marginRight: "2px" }}
                />
              }
              onClick={handleExportProjectPdf}
            />
          </Tooltip>
          <Tooltip title="Export Project">
            <Link
              color={theme.colors.salmon}
              icon={
                <img
                  alt="download"
                  src={download}
                  style={{ marginRight: "2px" }}
                />
              }
              onClick={handleExportProject}
            />
          </Tooltip>

          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="280px"
            onChange={handleChangeSearch}
          />
          <Button
            background={theme.colors.white}
            border={theme.colors.dark}
            color={theme.colors.dark}
            onClick={handleDocuments}
            width={"120px"}
          >
            Documents
          </Button>
          <Button
            background={theme.colors.salmon}
            onClick={handleOpenModal}
            width={"120px"}
          >
            New Line
          </Button>
        </Div>
      </Div>
      <Div m="32px 0 0 0">
        <Table
          columns={tableColumns}
          data={dataLines}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: loading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((v, idx) => (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                    key={idx}
                  />
                ))}
              </Div>
            ) : (
              "No data"
            ),
          }}
        />
      </Div>
    </Container>
  );
};
