import styled from "styled-components";
import grid from "../../assets/images/grid.svg";
import { theme } from "../theme";
import more from "../../assets/icons/more.svg";
import close from "../../assets/icons/close-white.svg";
import darkMore from "../../assets/icons/dark-more.svg";
import darkClose from "../../assets/icons/dark-close.svg";

export const Status = styled.div`
  display: flex;
  width: 17px;
  height: 7px;
  border-radius: 4px;
  opacity: 1;
`;

export const Grid = styled.div`
  display: flex;
  width: 531.627px;
  height: 496.954px;
  background: url(${grid}) no-repeat;
  background-position: center;
`;

export const FloatButton = styled.div`
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background || theme.colors.white} 0% 0%
    no-repeat padding-box;
  color: ${(props) => props.color || theme.colors.white};
  border: 1px solid ${(props) => props.border || "transparent"};
  border-radius: 21px;
  opacity: 1;
  cursor: pointer;
  padding-top: 3.73px;

  &::before {
    content: url(${(props) => (props.outline ? darkMore : more)});
  }

  span {
    display: none;
  }

  &:hover {
    width: max-content;
    height: 42px;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 5;

    &::before {
      content: url(${(props) => (props.outline ? darkClose : close)});
      margin-right: 8.75px;
    }

    span {
      display: flex;
    }
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  opacity: 1;
`;

export const SheetsNumber = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: max-content;
  height: 30px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
`;
