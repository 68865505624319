import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import _ from "lodash";
import { Button } from "../../components/Button";
import { Card, Container, Div, Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "../../components/Link";
import { Collapse } from "../../components/Collapse";
import { FloatButton, SheetsNumber, WrapperItem } from "../../styles/Line";
import { Drawer } from "../../components/Drawer";
import { Select } from "../../components/Select";
import { Modal } from "../../components/Modal";
import { Alert, Col, Row } from "antd";
import DnD from "../../components/DnD";
import alert from "../../assets/icons/alert.svg";
import useSaveSheet from "./hooks/useSaveSheet";
import { ModalLine } from "../../components/Projects/ModalLine";
import useItems from "./hooks/useItems";
import useDetail from "./hooks/useDetail";
import { ItemsTable } from "../../components/Sheet/ItemsTable";
import trash from "../../assets/icons/trash.svg";
import { useQueryClient } from "react-query";
import {
  setItemProperties,
  setItems,
  setItemsNamesList,
} from "../../core/store/common";
import usePropertiesList from "./hooks/usePropertiesList";
import useItemsName from "./hooks/useItemsName";
import useSheetNumber from "./hooks/useSheetNumber";
import back from "../../assets/icons/arrow-left.svg";
import next from "../../assets/icons/arrow-right.svg";
import {
  calculateTotalLength,
  compareArrays,
  parseLength,
} from "../../utilities/helpers";
import { DrawerContent } from "../../components/Sheet/DrawerContent";
import { abr } from "../../utilities/abreviations";
import useItemPropertiesList from "./hooks/useItemPropertiesList";
import { toast } from "react-hot-toast";

export const Sheet = () => {
  const save = useSaveSheet();
  const { isLoading } = save;
  const getItems = useItems();
  const { data: items } = getItems;
  const getItemsNames = useItemsName();
  const { isSuccess: itemsSuccess, data: itemsNamesArray } = getItemsNames;
  const { id, sheetId } = useParams();
  const dispatch = useDispatch();
  const { common } = useSelector((state) => state);
  const { itemProperties: itemPropertiesStore, itemsNamesList } = common;
  const navigate = useNavigate();
  const { search } = useLocation();
  const projectName = new URLSearchParams(search).get("projectName");
  const lineNumber = new URLSearchParams(search).get("lineNumber");
  const lineId = new URLSearchParams(search).get("lineId");
  const projectAFE = new URLSearchParams(search).get("projectAFE");
  const sheetVersionId = new URLSearchParams(search).get("sheetVersionId");
  const getDetail = useDetail(sheetId);
  const { data, isSuccess, isLoading: isLoadingDetail } = getDetail;
  const queryClient = useQueryClient();

  const [itemsNames, setItemsNames] = useState([]);
  const getPropertiesList = usePropertiesList(itemsNames);

  const [sheetDetail, setSheetDetail] = useState({});
  const sheetNumbers = useSheetNumber(lineId || sheetDetail?.lineId);
  const { data: dataSheetNumbers, isSuccess: successSheetNumbers } =
    sheetNumbers;

  const itemPropertiesList = useItemPropertiesList();
  const { isSuccess: isSuccessProperties, data: itemPropertiesListInfo } =
    itemPropertiesList;
  const [itemPropertiesData, setItemPropertiesData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalLine, setOpenModalLine] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openModalNavigation, setOpenModalNavigation] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [imageSelected, setImageSelected] = useState(null);
  const [urlImageSelected, setUrlImageSelected] = useState("");
  const [itemIdSelected, setItemIdSelected] = useState(null);
  const [itemName, setItemName] = useState("");
  const [images, setImages] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [publish, setPublish] = useState(false);
  const [draft, setDraft] = useState(false);
  const [newSheet, setNewSheet] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [textOption, setTextOption] = useState({});
  const [itemsList, setItemsList] = useState([]);
  const [drawerType, setDrawerType] = useState("");
  const [listItems, setListItems] = useState([]);
  const [listWelds, setListWelds] = useState([]);
  const [sheetNumberOfSheets, setSheetNumberOfSheets] = useState([]);
  const [itemProperties, setItemPropertiesPage] = useState([]);
  const excludedItemsIds = [5, 6, 12];
  const excludedItemsNames = ["Line", "Intersection", "Text"];

  const allRequestsSuccessful = getPropertiesList?.every(
    (ele) => ele.isSuccess && !!ele.data
  );

  const allRequestsLoading = getPropertiesList?.some((ele) => ele.isLoading);

  useEffect(() => {
    if (itemPropertiesStore?.length > 0) {
      setItemPropertiesPage(itemPropertiesStore);
    }
  }, [itemPropertiesStore]);

  useEffect(() => {
    if (successSheetNumbers) {
      setSheetNumberOfSheets(
        dataSheetNumbers?.data?.lines.map((ele) => ({
          sheetId: ele.sheetId,
          sheetNumber: ele.sheetNumber,
        }))
      );
    }
  }, [successSheetNumbers]);

  useEffect(() => {
    if (allRequestsSuccessful) {
      dispatch(
        setItemProperties(getPropertiesList?.map((ele) => ele.data.data.items))
      );
      setForceUpdate(!forceUpdate);
    } else {
      setItemProperties([]);
    }
  }, [allRequestsSuccessful, dispatch]);

  useEffect(() => {
    if (itemsSuccess) {
      dispatch(setItemsNamesList(itemsNamesArray?.data?.items));
      setForceUpdate(!forceUpdate);
    }
  }, [itemsSuccess]);

  useEffect(() => {
    if (isSuccessProperties) {
      setItemPropertiesData(itemPropertiesListInfo?.data?.items);
    }
  }, [isSuccessProperties]);

  const itemNames = (itemId) => {
    const currentItem = itemsNamesList.find((ele) => ele.id === itemId)?.name;
    return currentItem;
  };

  const dataTable = (imagesArray) => {
    try {
      const newImages = imagesArray.filter(
        (ele) => !excludedItemsIds.includes(ele.itemId)
      );

      const imagesGroup = _.groupBy(newImages, "name");

      const array = [];

      for (const key in imagesGroup) {
        const item = imagesGroup[key];

        const hNumberName = item[0].heatNumber;

        const lengths =
          itemNames(item[0].itemId) === "Pipe" && item.map((ele) => ele.length);
        const totalLength =
          itemNames(item[0].itemId) === "Pipe" && lengths.length > 1
            ? calculateTotalLength(lengths)
            : lengths;

        const idType =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Type"
          )?.id || "";
        const idMaterial =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Material"
          )?.id || "";
        const idClass =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Class"
          )?.id || "";
        const idDiameter =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Diameter"
          )?.id || "";
        const idGrade =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Grade"
          )?.id || "";
        const idThickness =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Thickness"
          )?.id || "";
        const idDiameter1 =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Diameter 1"
          )?.id || "";
        const idDiameter2 =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Diameter 2"
          )?.id || "";
        const idSchedule =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Schedule"
          )?.id || "";
        const idFinish =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Finish"
          )?.id || "";
        const idSeam =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Seam"
          )?.id || "";
        const idConnection =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "Connection"
          )?.id || "";
        const idSCH =
          item[0].itemPropertiesValue.find(
            (elem) => elem.propertyName === "SCH"
          )?.id || "";

        const values = (propertyId) => {
          return (
            itemPropertiesData.find((ele) => ele.id === propertyId)?.name || ""
          );
        };

        const flangeDescriptions = (type, itemName) => {
          switch (type) {
            case "BLD RF":
            case "BLD RTJ A":
            case "BLD RTJ B":
            case "BLD FF":
            case "THRD RF":
            case "THRD FF":
            case "RED THRD RF":
            case "RED THRD FF":
            case "ANCHOR":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "WN RF":
            case "WN RTJ A":
            case "WN RTJ B":
            case "WN FF":
            case "SW RF":
            case "SW RTJ":
            case "SW FF":
            case "RED WN RF":
            case "RED WN RTJ":
            case "RED WN FF":
            case "ORIFFICE RTJ":
            case "ORIFFICE RF":
            case "ORIFFICE SW":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}_${values(
                idThickness
              )}_${_.get(abr, values(idMaterial), values(idMaterial))}_${values(
                idGrade
              )}`;
            default:
              return "";
          }
        };

        const elbowDescriptions = (type, itemName) => {
          switch (type) {
            case "3R 90":
            case "3R 45":
            case "LR 90":
            case "LR 45":
            case "RED 45":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${values(idThickness)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "THRD 90":
            case "THRD 45":
            case "SOC 90":
            case "SOC 45":
            case "STREET 90":
            case "STREET 45":
            case "THRD RED 90":
            case "THRD RED 45":
            case "RED 90":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            default:
              return "";
          }
        };

        const teeCapDescriptions = (type, itemName) => {
          switch (type) {
            case "BW":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${values(idThickness)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "THRD":
            case "SOC":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "RED - BW":
              return `${abr[itemName] || ""}_${values(idType)}_${
                `${parseLength(values(idDiameter))}X${parseLength(
                  values(idDiameter2)
                )}` || ""
              }_${values(idThickness)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "RED - THRD":
            case "RED - SOC":
              return `${abr[itemName] || ""}_${values(idType)}_${
                `${parseLength(values(idDiameter))}X${parseLength(
                  values(idDiameter2)
                )}` || ""
              }_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            default:
              return "";
          }
        };

        const reducerDescriptions = (type, itemName) => {
          switch (type) {
            case "SWAGE":
              return `${abr[itemName] || ""}_${values(idType)}_${`${parseLength(
                values(idDiameter)
              )}X${parseLength(values(idDiameter2))}`}_${_.get(
                abr,
                values(idClass),
                values(idClass)
              )}_${values(idSchedule)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}_${values(idConnection)}`;
            case "CON":
            case "ECC":
              return `${abr[itemName] || ""}_${values(idType)}_${`${parseLength(
                values(idDiameter)
              )}X${parseLength(values(idDiameter2))}`}_${_.get(
                abr,
                values(idClass),
                values(idClass)
              )}_${values(idSchedule)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}_${values(idConnection)}`;
            default:
              return "";
          }
        };

        const valveDescriptions = (type, itemName) => {
          switch (type) {
            case "BALL":
            case "SWG CHECK":
            case "PISTON CHECK":
            case "BUTTERFLY":
            case "GATE":
            case "GLOBE":
            case "PLUG":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${values(idConnection)}_${_.get(
                abr,
                values(idClass),
                values(idClass)
              )}_${_.get(abr, values(idMaterial), values(idMaterial))}`;
            case "RELIEF":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}`;
            case "RED BALL":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}`;
            default:
              return "";
          }
        };

        const oletDescriptions = (type, itemName) => {
          switch (type) {
            case "WOL":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${values(idSCH)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "SOL":
            case "TOL":
              return `${abr[itemName] || ""}_${values(idType)}_${parseLength(
                values(idDiameter)
              )}_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            default:
              return "";
          }
        };

        const crossDescriptions = (type, itemName) => {
          switch (type) {
            case "EQUAL BW":
              return `${abr[itemName] || ""}_${values(idType)}_${values(
                idDiameter1
              )}_${values(idSchedule)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            case "EQUAL BW":
              return `${abr[itemName] || ""}_${values(idType)}_${`${values(
                idDiameter1
              )}X${parseLength(values(idDiameter2))}`}_${values(
                idSchedule
              )}_${_.get(abr, values(idMaterial), values(idMaterial))}_${values(
                idGrade
              )}`;
            case "EQUAL SOC":
            case "EQUAL THRD":
              return `${abr[itemName] || ""}_${values(idType)}_${values(
                idDiameter1
              )}_${_.get(abr, values(idClass), values(idClass))}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}`;
            default:
              return "";
          }
        };

        const descriptions = (itemName) => {
          switch (itemName) {
            case "Pipe":
              return `${abr[itemName] || ""}_${parseLength(
                values(idDiameter)
              )}_${values(idThickness)}_${values(idSeam)}_${_.get(
                abr,
                values(idMaterial),
                values(idMaterial)
              )}_${values(idGrade)}_${values(idConnection)}_${_.get(
                abr,
                values(idFinish),
                values(idFinish)
              )}`;
            case "Flange":
              return flangeDescriptions(values(idType), itemName);
            case "Elbow":
              return elbowDescriptions(values(idType), itemName);
            case "Tee":
              return teeCapDescriptions(values(idType), itemName);
            case "Cap":
              return teeCapDescriptions(values(idType), itemName);
            case "Reducer":
              return reducerDescriptions(values(idType), itemName);
            case "Valve":
              return valveDescriptions(values(idType), itemName);
            case "OLet":
              return oletDescriptions(values(idType), itemName);
            case "Cross":
              return crossDescriptions(values(idType), itemName);
            default:
              break;
          }
        };

        const newObj = {
          key: key,
          name: key,
          itemType: key === "" ? "" : itemNames(item[0].itemId),
          itemDescription: descriptions(itemNames(item[0].itemId)),
          hNumber: hNumberName,
          quantity:
            itemNames(item[0].itemId) === "Pipe" ? totalLength : item.length,
        };

        array.push(newObj);
      }

      return array;
    } catch (error) {
      return [];
    }
  };

  const dataWeldTable = (imagesArray) => {
    try {
      const newImages = imagesArray.filter((ele) => ele.itemId === 6);
      const imagesGroup = _.groupBy(newImages, "name");
      const array = [];

      for (const key in imagesGroup) {
        const item = imagesGroup[key];

        const itemProperty = itemProperties?.find(
          (ele) => ele.item === "WeldMarks"
        );

        const idDiameter = item[0].diameterId;

        const diameterProperty = itemProperty?.properties.find(
          (elem) => elem.propertyName === "Diameter"
        );
        const diameterName = diameterProperty?.values.find(
          (elemen) => elemen.id === idDiameter
        )?.value;

        const ndeNumber = item[0].ndeNumber;
        const nonEmptyArray = item[0].welder.filter((item) => item !== "");
        const weldersList = nonEmptyArray.join(", ");
        const welders = weldersList;

        const newObj = {
          key: key,
          name: key,
          ndeNumber: ndeNumber,
          welder: welders,
          diameter: diameterName,
        };

        array.push(newObj);
      }
      return array;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (itemProperties?.length > 0) {
      setListItems(dataTable(images));
      setListWelds(dataWeldTable(images));
      setForceUpdate(!forceUpdate);
    }
  }, [itemProperties, images]);

  const handleDeleteTable = (name) => {
    const newItems = listItems.filter((ele) => ele.name !== name);
    const newImages = images.filter((ele) => ele.name !== name);
    setListItems(newItems);
    setImages(newImages);
    setForceUpdate(!forceUpdate);
  };

  const handleDeleteWeld = (name) => {
    const newWelds = listWelds.filter((ele) => ele.name !== name);
    const newImages = images.filter((ele) => ele.name !== name);
    setListItems(newWelds);
    setImages(newImages);
    setForceUpdate(!forceUpdate);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "itemType",
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "H number",
      dataIndex: "hNumber",
      key: "hNumber",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "left",
      render: (value, item) => (
        <Div width="100%" gap="8px" align="center">
          <Div width="70%" justify="left">
            <Text color={theme.colors.grey1}>{value}</Text>
          </Div>
          <Div width="30%">
            <img
              alt="trash"
              src={trash}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteTable(item.name)}
            />
          </Div>
        </Div>
      ),
    },
  ];

  const weldTableColumns = [
    {
      title: "Mark",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
    },
    {
      title: "NDE #",
      dataIndex: "ndeNumber",
      key: "ndeNumber",
      width: 200,
    },
    {
      title: "Welder",
      dataIndex: "welder",
      key: "welder",
      width: 300,
    },
    {
      title: "DIAM",
      dataIndex: "diameter",
      key: "diameter",
      width: 150,
      render: (value, item) => (
        <Div width="100%" gap="20px" align="center">
          <Div width="50%" justify="center">
            <Text color={theme.colors.grey1}>{value}</Text>
          </Div>
          <Div width="50%">
            <img
              alt="trash"
              src={trash}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteWeld(item.name)}
            />
          </Div>
        </Div>
      ),
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      setSheetDetail(data?.data?.sheet);
      setImages(
        data?.data?.sheet?.items.map((ele, indx) => ({
          key: indx + 1,
          x: ele.x,
          y: ele.y,
          heatNumber: ele.heatNumber,
          name: ele.name,
          description: ele.description,
          itemDetailId: ele.itemDetailId,
          itemId: ele.itemId,
          url: ele.url,
          ndeNumber: ele.ndeNumber,
          diameterId: ele.diameterId,
          welder: ele.welder.concat(Array(6 - ele.welder.length).fill("")),
          length: ele.length,
          itemPropertiesValue: ele.itemPropertiesValue,
          text: ele.text,
        }))
      );
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (items?.items.length > 0) {
      setItemsList(
        items?.items.map((ele, indx) => ({
          key: indx,
          id: ele.id,
          name: ele.name,
          itemDetailList: ele.itemDetailList,
        }))
      );
      dispatch(
        setItems(
          items?.items.map((ele, indx) => ({
            key: indx,
            id: ele.id,
            name: ele.name,
            itemDetailList: ele.itemDetailList,
          }))
        )
      );
      setTypeOptions(
        items?.items
          .filter((elem) => !excludedItemsNames.includes(elem.name))
          .map((ele) => ({
            value: ele.id,
            label: ele.name,
          }))
      );
      const itemsWithProperties = items?.items
        .filter((elem) => !excludedItemsNames.includes(elem.name))
        .map((ele) => ele.name);
      setItemsNames([...itemsWithProperties, "WeldMarks"]);
      setTextOption(items?.items.find((ele) => ele.name === "Text"));
      setForceUpdate(!forceUpdate);
    }
  }, [items]);

  const panels = [
    {
      key: "1",
      header: "Item Marks",
      panel: ItemsTable(tableColumns, listItems),
    },
    {
      key: "2",
      header: "Weld Marks",
      panel: ItemsTable(weldTableColumns, listWelds),
    },
  ];

  const [modalInfo, setModalInfo] = useState({
    title: "",
    message: "",
    handleOk: () => {},
    handleCancel: () => {},
    okText: "",
    cancelText: "",
  });

  const handleAdd = () => {
    setOpenDrawer(true);
    setDrawerType("items");
  };

  const handleWelds = () => {
    setOpenDrawer(true);
    setDrawerType("welds");
  };

  const handleSelect = (value) => {
    setItemType(value);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setItemType(null);
  };

  const handleSelectImage = (itemId, subItemId, urlImage, itemName) => {
    setItemIdSelected(itemId);
    setImageSelected(subItemId);
    setUrlImageSelected(urlImage);
    setItemName(itemName);
    setForceUpdate(!forceUpdate);
  };

  const findEmptyPosition = (images) => {
    for (let y = 14; y >= 1; y--) {
      for (let x = 14; x >= 1; x--) {
        const positionOccupied = images.some(
          (image) => image.x === x && image.y === y
        );

        if (!positionOccupied) {
          return { x, y };
        }
      }
    }

    return null;
  };

  const handleAddItem = (
    itemId,
    subItemId,
    urlImage,
    itemName,
    itemPropertiesArray
  ) => {
    const newImages = [...images];
    const lastKey = newImages.length > 0 ? _.maxBy(newImages, "key").key : 0;

    let properties = {};

    if (!excludedItemsIds.includes(itemId)) {
      const newItemProperties = itemPropertiesArray?.filter(
        (ele) => ele.item !== "Line"
      );
      const propertiesAllInfo = newItemProperties?.find(
        (ele) => ele.item === itemName
      )?.properties;

      properties = propertiesAllInfo.map((ele) => ({
        id: 0,
        propertyName: ele.propertyName,
      }));
    }

    const newPosition = findEmptyPosition(newImages);

    if (newPosition) {
      setImages([
        ...newImages,
        {
          x: newPosition.x,
          y: newPosition.y,
          name: "",
          description: "",
          heatNumber: "",
          itemDetailId: subItemId,
          itemId: itemId,
          url: urlImage,
          key: lastKey + 1,
          ndeNumber: null,
          diameterId: null,
          welder: ["", "", "", "", "", ""],
          length: null,
          itemPropertiesValue: excludedItemsIds.includes(itemId)
            ? null
            : properties,
          text: "",
        },
      ]);

      setItemType(null);
      setImageSelected(null);
      setForceUpdate(!forceUpdate);
      setOpenDrawer(false);
    } else {
      toast.error(
        "It is not possible to add the item because all the positions on the board are occupied"
      );
    }
  };

  const handleChangeImages = (newImagesProperties) => {
    const newImages = images.filter(
      (ele) => ele.key !== newImagesProperties.key
    );
    setImages([...newImages, newImagesProperties]);
    setForceUpdate(!forceUpdate);
  };

  const handleAction = (action, sheet, line, sheetVersion) => {
    switch (action) {
      case "close":
        navigate(
          `/projects/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
        );
        break;
      case "line":
        setOpenModalLine(true);
        break;
      case "sheet":
        navigate(
          `/projects/${id}/sheet/${sheet}?projectName=${projectName}&lineNumber=${sheetDetail?.lineNumber}&lineId=${line}&projectAFE=${projectAFE}&sheetVersionId=${sheetVersion}`
        );
        setImages([]);
        break;
      case "next":
        const index = sheetNumberOfSheets.findIndex(
          (ele) => ele.sheetNumber === sheetDetail?.sheetVersion
        );
        const nextId = sheetNumberOfSheets[Number(index) + Number(1)].sheetId;
        queryClient.removeQueries("sheetDetail");
        navigate(
          `/projects/${id}/sheet/${nextId}?projectName=${projectName}&projectAFE=${projectAFE}`
        );
        setOpenModalNavigation(false);
        setForceUpdate(!forceUpdate);
        break;
      case "back":
        const previousIndex = sheetNumberOfSheets.findIndex(
          (ele) => ele.sheetNumber === sheetDetail?.sheetVersion
        );
        const previusId =
          sheetNumberOfSheets[Number(previousIndex) - Number(1)].sheetId;
        queryClient.removeQueries("sheetDetail");
        navigate(
          `/projects/${id}/sheet/${previusId}?projectName=${projectName}&projectAFE=${projectAFE}`
        );
        setOpenModalNavigation(false);
        setForceUpdate(!forceUpdate);
        break;
      default:
        break;
    }
  };

  const filteredImages = images.map((image) => {
    if (image.welder.every((ele) => ele === "")) {
      return { ...image, welder: null };
    } else {
      const filteredWelders = image.welder.filter((welder) => welder !== "");
      return { ...image, welder: filteredWelders };
    }
  });

  const handleSave = (action, type) => {
    save.reset();
    save.mutate(
      {
        sheetId: Number(sheetId),
        projectName: projectName,
        lineNumber: lineNumber || sheetDetail?.lineNumber,
        sheetVersionId: Number(sheetVersionId) || sheetDetail?.sheetVersionId,
        items: filteredImages,
        lineId: Number(lineId) || sheetDetail?.lineId,
        saveType: type,
      },
      {
        onSuccess: (res) => {
          setDraft(false);
          setPublish(false);
          setNewSheet(false);
          setOpenModal(false);
          setOpenConfirmation(false);
          queryClient.removeQueries("sheetDetail");
          queryClient.removeQueries("propertiesList");
          queryClient.removeQueries("sheetNumber");
          handleAction(
            action,
            res?.data?.Result?.sheetId,
            res?.data?.Result?.lineId,
            res?.data?.Result?.sheetVersionId
          );
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      }
    );
  };

  const somePropertyEmpty = _.some(images, (objeto) => {
    return _.some(objeto.itemPropertiesValue, { id: 0 });
  });

  const handleSaveModal = (type) => {
    setOpenModal(true);
    setModalInfo({
      title: "You are saving",
      message: "Do you want to publish the line?",
      handleOk: () => {
        if (somePropertyEmpty) {
          setOpenModal(false);
          setOpenConfirmation(true);
          setModalInfo({
            title: "Are you sure you want to publish?",
            message: "Some of your items do not contain all the properties.",
            handleOk: () => {
              handleSave(type, 2), setPublish(true);
            },
            handleCancel: () => {
              handleSave(type, 0), setDraft(true);
            },
            okText: "Yes, publish the line",
            cancelText: "No, save as a draft",
          });
        } else {
          handleSave(type, 2), setPublish(true);
        }
      },
      handleCancel: () => {
        handleSave(type, 0), setDraft(true);
      },
      okText: "Yes, publish the line",
      cancelText: "No, save as a draft",
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenConfirmation(false);
    setOpenModalNavigation(false);
    setErrorMessage("");
    setForceUpdate(!forceUpdate);
  };

  const handleBack = (number) => {
    const index = sheetNumberOfSheets.findIndex(
      (ele) => ele.sheetNumber === number
    );
    const previusId = sheetNumberOfSheets[Number(index) - Number(1)].sheetId;
    queryClient.removeQueries("sheetDetail");
    navigate(
      `/projects/${id}/sheet/${previusId}?projectName=${projectName}&projectAFE=${projectAFE}`
    );
    setOpenModalNavigation(false);
    setForceUpdate(!forceUpdate);
  };

  const handleNext = (number) => {
    const index = sheetNumberOfSheets.findIndex(
      (ele) => ele.sheetNumber === number
    );
    const nextId = sheetNumberOfSheets[Number(index) + Number(1)].sheetId;
    queryClient.removeQueries("sheetDetail");
    navigate(
      `/projects/${id}/sheet/${nextId}?projectName=${projectName}&projectAFE=${projectAFE}`
    );
    setOpenModalNavigation(false);
    setForceUpdate(!forceUpdate);
  };

  const handleNextPrev = () => {
    setOpenModalNavigation(true);
    setModalInfo({
      title: "You are changing sheet",
      message:
        "Do you want to save the changes before going to the next sheet?",
      handleOk: () => handleSave("next", 0),
      handleCancel: () => handleNext(sheetDetail?.sheetVersion),
      okText: "Save and continue",
      cancelText: "Continue",
    });
  };

  const handleBackPrev = () => {
    setOpenModalNavigation(true);
    setModalInfo({
      title: "You are changing sheet",
      message:
        "Do you want to save the changes before going to the previous sheet?",
      handleOk: () => handleSave("back", 0),
      handleCancel: () => handleBack(sheetDetail?.sheetVersion),
      okText: "Save and continue",
      cancelText: "Continue",
    });
  };

  let sheetOfSheets = sheetDetail?.sheetVersion?.split(" ");
  let currentPage = sheetDetail?.sheetVersion && parseInt(sheetOfSheets[0]);
  let totalPage = sheetDetail?.sheetVersion && parseInt(sheetOfSheets[2]);

  const compareNext = () => {
    if (images && data?.data?.sheet?.items) {
      if (compareArrays(images, data?.data?.sheet?.items)) {
        handleNext(sheetDetail?.sheetVersion);
      } else {
        handleNextPrev();
      }
    }
  };

  const compareBack = () => {
    if (images && data?.data?.sheet?.items) {
      if (compareArrays(images, data?.data?.sheet?.items)) {
        handleBack(sheetDetail?.sheetVersion);
      } else {
        handleBackPrev();
      }
    }
  };

  return (
    <Container>
      <ModalLine
        openModal={openModalLine}
        setOpenModal={setOpenModalLine}
        id={id}
        projectAFE={projectAFE}
        projectName={projectName}
        setImages={setImages}
      />
      <Modal
        open={openModal || openConfirmation || openModalNavigation}
        onCancel={handleCloseModal}
        align={"center"}
        padding={36}
      >
        <Modal.Header
          icon={alert}
          iconBackground={theme.colors.salmon}
          iconWidth={"24px"}
          iconHeight={"24px"}
          align={"-webkit-center"}
          padding="16px 0 16px 0"
          title={modalInfo.title}
        />
        <Modal.Body margin="16px 0 24px 0" maxHeight={200} align="center">
          <Text
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.medium}
            color={theme.colors.grey1}
            style={{ textAlign: "center" }}
          >
            {modalInfo.message}
          </Text>
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          {errorMessage !== "" && (
            <Div m="0 0 20px 0" justify="flex-end">
              <Alert closable message={`${errorMessage}`} type="error" />
            </Div>
          )}
          <Div gap="32px" justify="center">
            <Button
              onClick={modalInfo.handleCancel}
              border={theme.colors.dark}
              color={theme.colors.dark}
              loading={draft && isLoading}
            >
              {modalInfo.cancelText}
            </Button>
            <Button
              onClick={modalInfo.handleOk}
              background={theme.colors.salmon}
              loading={publish && isLoading}
            >
              {modalInfo.okText}
            </Button>
          </Div>
        </Modal.Footer>
      </Modal>
      <Drawer
        closelabel={"Close"}
        onClose={handleClose}
        open={openDrawer}
        width={378}
      >
        <Drawer.Header
          title={drawerType === "items" ? "Add Item" : "Add Weld"}
        />
        <Div m="24px 0 0 0" direction="column">
          {drawerType === "items" ? (
            <>
              <Select
                id="itemType"
                placeholder="Item Types"
                value={itemType}
                options={typeOptions}
                onChange={(e) => handleSelect(e)}
                style={{ width: "330px", marginBottom: "24px" }}
              />
              <Div maxheight="400px" style={{ overflowY: "auto" }}>
                <DrawerContent
                  itemsList={itemsList}
                  itemType={itemType}
                  imageSelected={imageSelected}
                  handleSelectImage={handleSelectImage}
                />
              </Div>
            </>
          ) : (
            <Div direction="column">
              <Row
                gutter={[24, 24]}
                style={{ width: "330px", marginTop: "24px" }}
              >
                {itemsList
                  .filter((ele) => ele.name === "Intersection")
                  .map((ele) =>
                    ele.itemDetailList.map((elem, index) => (
                      <Col key={index} md={6}>
                        <WrapperItem
                          onClick={() =>
                            handleSelectImage(
                              ele.id,
                              elem.id,
                              elem.url,
                              ele.name
                            )
                          }
                          style={{
                            backgroundColor:
                              elem.id === imageSelected
                                ? theme.colors.salmon1
                                : theme.colors.light,
                            border: `1px solid ${
                              elem.id === imageSelected
                                ? theme.colors.salmon
                                : theme.colors.grey3
                            }`,
                            cursor: "pointer",
                          }}
                        >
                          <img alt="item" src={elem.url} />
                        </WrapperItem>
                      </Col>
                    ))
                  )}
              </Row>
              <Text
                color={theme.colors.dark}
                size={theme.fonts.size.h4}
                weight={theme.fonts.weight.bold}
                mt="46px"
              >
                Add Line
              </Text>
              <Row
                gutter={[24, 24]}
                style={{ width: "330px", marginTop: "24px" }}
              >
                {itemsList
                  .filter((ele) => ele.name === "Line")
                  .map((ele) =>
                    ele.itemDetailList.map((elem, index) => (
                      <Col key={index} md={6}>
                        <WrapperItem
                          onClick={() =>
                            handleSelectImage(
                              ele.id,
                              elem.id,
                              elem.url,
                              ele.name
                            )
                          }
                          style={{
                            backgroundColor:
                              elem.id === imageSelected
                                ? theme.colors.salmon1
                                : theme.colors.light,
                            border: `1px solid ${
                              elem.id === imageSelected
                                ? theme.colors.salmon
                                : theme.colors.grey3
                            }`,
                            cursor: "pointer",
                          }}
                        >
                          <img alt="item" src={elem.url} />
                        </WrapperItem>
                      </Col>
                    ))
                  )}
              </Row>
            </Div>
          )}
          <Div
            width="330px"
            direction="column"
            style={{ position: "absolute", bottom: "4vh" }}
          >
            <Button
              background={theme.colors.salmon}
              width="100%"
              onClick={() =>
                handleAddItem(
                  itemIdSelected,
                  imageSelected,
                  urlImageSelected,
                  itemName,
                  itemProperties
                )
              }
              disabled={imageSelected === null}
            >
              {drawerType === "items" ? "Add Item" : "Add Weld or Line"}
            </Button>
            <Link color={theme.colors.salmon} onClick={handleClose}>
              Cancel
            </Link>
          </Div>
        </Div>
      </Drawer>
      {allRequestsLoading || isLoadingDetail ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.dark} />
        </Div>
      ) : (
        <>
          <Div width="100%" justify="space-between" align="center">
            <Div
              direction="column"
              gap="6px"
              style={{
                width: "350px",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              <Text
                size={theme.fonts.size.h4}
                color={theme.colors.dark}
                weight={theme.fonts.weight.bold}
              >
                {projectName}
              </Text>
              <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
                {`${lineNumber || sheetDetail?.lineNumber}`}
              </Text>
            </Div>
            <Div gap="24px" align="center">
              <Link
                href={`/projects/${id}?projectAFE=${projectAFE}&projectName=${projectName}`}
                color={theme.colors.salmon}
                style={{ height: "min-content" }}
              >
                Cancel
              </Link>
              <Button
                onClick={() => handleSaveModal("close")}
                border={theme.colors.dark}
                color={theme.colors.dark}
                weight={theme.fonts.weight.bold}
                height="35px"
              >
                Save and Close
              </Button>
              <Button
                onClick={() => handleSaveModal("line")}
                border={theme.colors.dark}
                color={theme.colors.dark}
                weight={theme.fonts.weight.bold}
                height="35px"
              >
                Save and New Line
              </Button>
              <Button
                onClick={() => {
                  handleSave("sheet", 1), setNewSheet(true);
                }}
                background={theme.colors.salmon}
                weight={theme.fonts.weight.bold}
                loading={newSheet && isLoading}
                height="35px"
              >
                Save and New Sheet
              </Button>
            </Div>
          </Div>
          <Div justify="space-between" m="21px 0 21px 0">
            <Div width="58%">
              <Card height="582px">
                <Div direction="column" gap="10px">
                  <DnD
                    images={images}
                    setImages={setImages}
                    handleChangeImages={handleChangeImages}
                    style={{ width: "90%" }}
                  />
                  {sheetDetail?.sheetVersion &&
                    sheetDetail?.sheetVersion !== "1 of 1" && (
                      <SheetsNumber>
                        {currentPage !== 1 && (
                          <img
                            alt="back"
                            src={back}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            onClick={() => compareBack()}
                          />
                        )}
                        <Text
                          size={theme.fonts.size.sm}
                          color={theme.colors.grey2}
                        >{`Sheet ${sheetDetail?.sheetVersion}`}</Text>
                        {currentPage !== totalPage && (
                          <img
                            alt="next"
                            src={next}
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => compareNext()}
                          />
                        )}
                      </SheetsNumber>
                    )}
                </Div>
                <Div
                  width="5%"
                  height="100%"
                  align="self-start"
                  m="80px 0px 0 30px"
                  direction="column"
                  gap="16px"
                >
                  <FloatButton
                    onClick={handleAdd}
                    background={theme.colors.salmon}
                  >
                    <span>Add Item</span>
                  </FloatButton>
                  <FloatButton
                    onClick={handleWelds}
                    background={theme.colors.dark}
                  >
                    <span>Add Weld and Line</span>
                  </FloatButton>
                  <FloatButton
                    onClick={() =>
                      handleAddItem(
                        textOption.id,
                        textOption.itemDetailList[0].id,
                        textOption.itemDetailList[0].url,
                        textOption.name,
                        itemProperties
                      )
                    }
                    outline
                    color={theme.colors.dark}
                    border={theme.colors.dark}
                  >
                    <span>Add Text</span>
                  </FloatButton>
                </Div>
              </Card>
            </Div>
            <Div width="38%" direction="column" gap="21px">
              <Collapse panels={panels} />
            </Div>
          </Div>
        </>
      )}
    </Container>
  );
};
