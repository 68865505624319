import React from "react";
import { Modal } from "../../../components/Modal";
import successIcon from "../../../assets/icons/success.svg";
import { theme } from "../../../styles/theme";
import { Text } from "../../../styles/Common";

export const ModalSuccess = ({
  openModal,
  handleClose,
}) => {
  return (
    <Modal
      open={openModal}
      onCancel={handleClose}
      align={"center"}
      padding={36}
    >
      <Modal.Header
        icon={successIcon}
        iconBackground={theme.colors.dark}
        iconWidth={"24px"}
        iconHeight={"24px"}
        align={"-webkit-center"}
        padding="16px 0 16px 0"
        title={"Document Saved"}
      />
      <Modal.Body margin="0 0 24px 0" align={"center"}>
        <Text>
          Your changes have been saved successfully
        </Text>
      </Modal.Body>
    </Modal>
  )
};