import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { theme } from "../../styles/theme";
import { Div, Text } from "../../styles/Common";
import { WrapperLength, WrapperName } from "./styles";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { Content } from "../PopoverContent";
import { Link } from "../Link";
import { Input } from "../Input";
import copy from "../../assets/icons/copy.svg";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { DrawerContent } from "../Sheet/DrawerContent";

const Item = ({ currentImage, images, setImages }) => {
  const [collected, drag] = useDrag(
    () => ({
      type: `ITEM`,
      item: { currentImage },

      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [currentImage]
  );

  const { common } = useSelector((state) => state);
  const { itemProperties, itemsNamesList, items } = common;
  const [propertiesVisible, setPropertiesVisible] = useState(false);
  const [openFirstTime, setOpenFirstTime] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [openDeleteLine, setOpenDeleteLine] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [openOrientation, setOpenOrientation] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [urlImageSelected, setUrlImageSelected] = useState("");
  const line = currentImage.itemId === 5;
  const text = currentImage.itemId === 12;

  const item = (itemId) => {
    const currentItem = itemsNamesList?.find((ele) => ele.id === itemId)?.name;
    return currentItem;
  };

  const optionsSelect = (image) => {
    if (image.itemId !== 5) {
      const newItemProperties = itemProperties?.filter(
        (ele) => ele.item !== "Line"
      );
      const propertiesAllInfo = newItemProperties?.find(
        (ele) => ele.item === item(image.itemId)
      )?.properties;

      let optionsSelect = propertiesAllInfo?.map((ele, indx) => ({
        key: indx,
        name: ele.propertyName,
        options: ele.values.map((elem) => ({
          label: elem.value,
          value: elem.id,
        })),
        dependencies: ele.dependencies.map((element, index) => ({
          key: index,
          name: element.name,
          index: element.index,
        })),
      }));

      setSelectOptions(optionsSelect);
    }
  };

  useEffect(() => {
    const lastKey = images.length > 0 ? _.maxBy(images, "key").key : 0;
    if (lastKey) {
      setOpenFirstTime(true);
      optionsSelect(currentImage);
      setForceUpdate(!forceUpdate);
    }

    if (currentImage.x !== 14 || currentImage.y !== 14) {
      setOpenFirstTime(false);
    }
  }, [currentImage, images]);

  const handleOpenChange = (value) => {
    setPropertiesVisible(value);
    setOpenFirstTime(false);
  };

  const handleClick = (image) => {
    setPropertiesVisible(true);
    setOpenFirstTime(false);
    optionsSelect(image);
    setForceUpdate(!forceUpdate);
  };

  const handleOpenChangeLine = (value) => {
    setOpenDeleteLine(value);
  };

  const handleDelete = (key) => {
    const newImages = images.filter((ele) => ele.key !== key);
    setImages(newImages);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event, image) => {
    const { id, value } = event.target;
    const newImages = images.filter((ele) => ele.key !== image.key);
    image[id] = value;
    setImages([...newImages, image]);
    setForceUpdate(!forceUpdate);
  };

  const textContent = (key) => {
    return (
      <>
        <Div gap="30px" justify="space-between" align="center">
          <Text>Text</Text>
          <Input
            id="text"
            value={currentImage.text}
            maxLength={15}
            placeholder="Text"
            onChange={(e) => handleChange(e, currentImage)}
            height="30px"
            radius="8px"
            style={{ width: "135px" }}
          />
        </Div>
        <Div m={"10px 0 5px 0"} justify="center">
          <Link style={{ height: "16px" }} onClick={() => handleDelete(key)}>
            Delete text
          </Link>
        </Div>
      </>
    );
  };

  const lineContent = (key) => {
    return (
      <Link style={{ height: "16px" }} onClick={() => handleDelete(key)}>
        Delete line
      </Link>
    );
  };

  const lengthLocation = (id) => {
    switch (id) {
      case 17:
        return { top: "10%", left: "15%", rotate: "45deg" };
      case 18:
        return { top: "25%", left: "-60%", rotate: "-90deg" };
      case 19:
        return { top: "-5%", left: "-40%", rotate: "-45deg" };
      case 20:
        return { top: "-5%", left: "0%", rotate: "0deg" };
      default:
        break;
    }
  };

  const handleCopy = (key, allImages) => {
    const lastKey = allImages.length > 0 ? _.maxBy(allImages, "key").key : 0;
    const newImage = allImages.find((ele) => ele.key === key);
    const updateImage = {
      ...newImage,
      x: 14,
      y: 14,
      key: lastKey + 1,
      itemDetailId: imageSelected,
      url: urlImageSelected,
    };
    setImages([...allImages, updateImage]);
    setPropertiesVisible(false);
    setOpenFirstTime(false);
    setOpenOrientation(false);
    setImageSelected(null);
  };

  const handleSelectOrientation = () => {
    setOpenOrientation(true);
    setPropertiesVisible(false);
    setOpenFirstTime(false);
  };

  const title = (itemId) => {
    return (
      <Div justify="space-between" m="0 0 8px 0">
        <Text
          size={theme.fonts.size.sm}
          color="rgba(0, 0, 0, 0.88)"
          weight={theme.fonts.weight.semibold}
        >{`${item(itemId)} information`}</Text>
        {currentImage.itemId !== 6 && (
          <img
            alt="copy"
            src={copy}
            style={{ cursor: "pointer" }}
            onClick={handleSelectOrientation}
          />
        )}
      </Div>
    );
  };

  const oletLocation = (id) => {
    switch (id) {
      case 77:
        return { top: "32%", left: "0%" };
      case 78:
        return { top: "0%", left: "-32%" };
      case 80:
        return { top: "-32%", left: "0%" };
      case 81:
        return { top: "0%", left: "32%" };
      default:
        break;
    }
  };

  const handleSelectImage = (itemId, subItemId, urlImage, itemName) => {
    setImageSelected(subItemId);
    setUrlImageSelected(urlImage);
    setForceUpdate(!forceUpdate);
  };

  return (
    <>
      <Modal
        open={openOrientation}
        onCancel={() => setOpenOrientation(false)}
        align={"left"}
        padding={"41px 39px"}
      >
        <Modal.Header
          padding="16px 0 16px 0"
          title={"Select item orientation"}
        />
        <Modal.Body
          margin="16px 0 24px 0"
          maxHeight={400}
          style={{ textAlign: "-webkit-center" }}
        >
          <DrawerContent
            itemsList={items}
            itemType={currentImage.itemId}
            imageSelected={imageSelected}
            handleSelectImage={handleSelectImage}
          />
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          <Div gap="32px" justify="center">
            <Button
              onClick={() => setOpenOrientation(false)}
              border={theme.colors.dark}
              color={theme.colors.dark}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleCopy(currentImage.key, images)}
              background={theme.colors.salmon}
              disabled={imageSelected === null}
            >
              Add item
            </Button>
          </Div>
        </Modal.Footer>
      </Modal>
      {line || text ? (
        <Popover
          placement="right"
          trigger="click"
          content={
            line ? lineContent(currentImage.key) : textContent(currentImage.key)
          }
          open={openDeleteLine}
          onOpenChange={handleOpenChangeLine}
        >
          <div
            ref={drag}
            style={{
              opacity: collected.isDragging ? 0.5 : 1,
              cursor: "pointer",
              position: "absolute",
            }}
          >
            <img alt="image" src={currentImage?.url || ""} />
            {text && (
              <WrapperName
                color={theme.colors.white}
                size="10px"
                location="36%"
              >
                <Text size={theme.fonts.size.xxs} color={theme.colors.dark}>
                  {currentImage?.text}
                </Text>
              </WrapperName>
            )}
          </div>
        </Popover>
      ) : (
        <Popover
          placement="right"
          title={() => title(currentImage.itemId)}
          content={
            <Content
              currentImage={currentImage}
              setPropertiesVisible={setPropertiesVisible}
              setOpenFirstTime={setOpenFirstTime}
              selectOptions={selectOptions}
              images={images}
              setImages={setImages}
              itemNames={item}
              itemProperties={itemProperties}
            />
          }
          trigger="click"
          open={propertiesVisible || openFirstTime}
          onOpenChange={handleOpenChange}
        >
          <div
            ref={drag}
            style={{
              opacity: collected.isDragging ? 0.5 : 1,
              cursor: "pointer",
              position: "absolute",
              margin:
                currentImage.itemId === 7 &&
                `${oletLocation(currentImage.itemDetailId).top} 0 0 ${
                  oletLocation(currentImage.itemDetailId).left
                }`,
            }}
            onClick={() => handleClick(currentImage)}
          >
            <img alt="image" src={currentImage?.url || ""} />
            {currentImage.itemId === 2 && (
              <WrapperLength
                top={() => lengthLocation(currentImage.itemDetailId).top}
                left={() => lengthLocation(currentImage.itemDetailId).left}
                right={() => lengthLocation(currentImage.itemDetailId).right}
                rotate={() => lengthLocation(currentImage.itemDetailId).rotate}
              >
                <Text size={theme.fonts.size.xxs} color={theme.colors.dark}>
                  {currentImage?.length}
                </Text>
              </WrapperLength>
            )}
            {currentImage.itemId !== 6 ? (
              <WrapperName
                color={theme.colors.white}
                size="10px"
                location="36%"
              >
                <Text size={theme.fonts.size.xxs} color={theme.colors.dark}>
                  {currentImage?.name}
                </Text>
              </WrapperName>
            ) : (
              <WrapperName color={theme.colors.dark} size="14px" location="30%">
                <Text
                  size={theme.fonts.size.xxxs}
                  color={theme.colors.white}
                  style={{ marginTop: "1px" }}
                >
                  {currentImage?.name}
                </Text>
              </WrapperName>
            )}
          </div>
        </Popover>
      )}
    </>
  );
};

export default Item;
