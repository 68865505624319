import styled from "styled-components";
import { Collapse } from "antd";
import { theme } from "../../styles/theme";

export const CustomCollapse = styled(Collapse)`
  .ant-collapse-item {
    .ant-collapse-content {
      border-radius: ${({ detail }) => detail ? "0 0 15px 15px" : "0 0 10px 10px"} !important;
      box-shadow: ${({ detail }) => detail ? null : "0px 9px 20px #00000008"};
      opacity: 1;
      border: 1px solid ${theme.colors.white};
      height: ${({ detail }) => detail ? "max-content" : "451px"};
      max-height: ${({ detail }) => detail ? "max-content" : "451px"};
      overflow-y: auto;
      padding-bottom: 20px;

      .ant-collapse-content-box {
        padding-top: 0px;
      }
    }

    .ant-collapse-header {
      border-radius: ${({ detail }) => detail ? "0 0 15px 15px" : "0 0 10px 10px"} !important;
      color: ${theme.colors.dark};
      font-size: ${theme.fonts.size.default};
      font-weight: ${theme.fonts.weight.medium};
      align-items: center;

      .ant-collapse-arrow {
        font-size: 14px;
      }
    }
  }
`;
