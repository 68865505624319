import React, { useEffect, useState } from "react";
import { Container, Div, Text } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Table } from "../../components/Table";
import { Link } from "../../components/Link";
import { theme } from "../../styles/theme";
import searchIcon from "../../assets/icons/search.svg";
import edit from "../../assets/icons/edit.svg";
import { validateNewCompany } from "../../utilities/validations";
import useCreateCompany from "./hooks/useCreateCompany";
import { ModalCompanies } from "../../components/Companies/ModalCompanies";
import { getUserInfo, uploadFiles } from "../../utilities/helpers";
import useCompaniesInfo from "./hooks/useCompaniesInfo";
import { Skeleton } from "../../components/Skeleton";
import useDebounce from "../../core/hooks/useDebounce";
import useGetCompany from "./hooks/useGetCompany";
import useUpdateCompany from "./hooks/useUpdateCompany";
import { useNavigate } from "react-router-dom";
import Switch from "../../components/Switch";
import useUpdateStatus from "./hooks/useUpdateStatus";
import { toast } from "react-hot-toast";

export const Companies = () => {
  const navigate = useNavigate();
  const create = useCreateCompany();
  const { isLoading: loading } = create;
  const update = useUpdateCompany();
  const updateStatus = useUpdateStatus();
  const { isLoading: loadingUpdate } = update;
  const { isLoading: loadingStatus } = updateStatus;
  const [companyId, setCompanyId] = useState(null);
  const getCompanyInfo = useGetCompany(companyId);
  const { data: companyInfo, isSuccess } = getCompanyInfo.data;
  const [dataCompanies, setDataCompanies] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const getCompanies = useCompaniesInfo(pages.current, perPage, searchValue);
  const { data } = getCompanies;
  const { data: dataCompaniesList, isLoading } = data;

  const [forceUpdate, setForceUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [updateCompany, setUpdateCompany] = useState(false);

  const initialValues = {
    companyName: "",
    adminName: "",
    adminLastName: "",
    adminEmail: "",
    companyLogoUrl: "",
  };

  const initialValuesError = {
    companyName: {
      error: false,
      message: "",
    },
    adminName: {
      error: false,
      message: "",
    },
    adminLastName: {
      error: false,
      message: "",
    },
    adminEmail: {
      error: false,
      message: "",
    },
    companyLogoUrl: {
      error: false,
      message: "",
    },
  };

  const [companyForm, setCompanyForm] = useState(initialValues);
  const [companyErrorForm, setCompanyErrorForm] = useState(initialValuesError);

  useEffect(() => {
    if (getUserInfo().role !== "superAdmin") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (dataCompaniesList?.data?.companies?.items.length > 0) {
      const dataTable = dataCompaniesList.data.companies.items.map(
        (ele, i) => ({
          ...ele,
          key: `${ele.id}`,
          status: {
            id: ele.id,
            isEnabled: ele.isEnabled,
          },
          actions: {
            key: `1${i}`,
            id: ele.id,
            name: "Edit",
          },
        })
      );

      setDataCompanies(dataTable);
      setTotalItems(dataCompaniesList?.data?.companies.totalItems);
    } else {
      setDataCompanies([]);
    }
  }, [dataCompaniesList?.data]);

  useEffect(() => {
    if (isSuccess) {
      setOpenModal(true);
      setUpdateCompany(true);
      setCompanyForm({
        companyName: companyInfo?.data.companyName,
        adminName: companyInfo?.data.adminName,
        adminLastName: companyInfo?.data.adminLastName,
        adminEmail: companyInfo?.data.adminEmail,
        companyLogoUrl: companyInfo?.data.companyLogoUrl,
      });
    }
  }, [isSuccess]);

  const handleEdit = (id) => {
    setCompanyId(id);
    setForceUpdate(!forceUpdate);
  };

  const handleStatus = (event, companyId) => {
    updateStatus.reset();
    updateStatus.mutate(
      {
        isEnabled: event,
        companyId: companyId,
      },
      {
        onSuccess: () => {
          reset();
          toast.success(
            `The company has been ${
              event ? "activated" : "inactivated"
            } successfully.`
          );
          data.refetch();
        },
        onError: (err) => {
          toast.error(`${err.response.data.Errors[0]}`);
          setErrorMessage(err.response.data.Errors[0]);
        },
      }
    );
  };

  const tableColumns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 170,
    },
    {
      title: "User Admin Name",
      dataIndex: "adminName",
      key: "adminName",
      width: 160,
    },
    {
      title: "User Admin Lastname",
      dataIndex: "adminLastName",
      key: "adminLastName",
      width: 160,
    },
    {
      title: "User Admin Email",
      dataIndex: "adminEmail",
      key: "adminEmail",
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 180,
      render: (item) => (
        <Switch
          checked={item.isEnabled}
          onChange={(e) => handleStatus(e, item.id)}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 140,
      render: (actions) => (
        <Link
          onClick={() => handleEdit(actions.id)}
          color={theme.colors.salmon}
          icon={<img alt="edit" src={edit} style={{ marginRight: "8px" }} />}
          styles={{
            "&:hover": {
              background: "transparent",
            },
          }}
        >
          {actions.name}
        </Link>
      ),
    },
  ];

  const reset = () => {
    setOpenModal(false);
    setCompanyErrorForm(initialValuesError);
    setCompanyForm(initialValues);
    setErrorMessage("");
    setLoadingImage(false);
    setUpdateCompany(false);
    setCompanyId(null);
    getCompanyInfo.data.refetch();
    setForceUpdate(!forceUpdate);
  };

  const handleNewCompany = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    reset();
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = companyErrorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setCompanyErrorForm(newErrorForm);

    const form = companyForm;
    form[id] = value;
    setCompanyForm(form);
    setForceUpdate(!forceUpdate);
  };

  const handleUploadLogo = async (event) => {
    if (event.file.type !== "image/png" && event.file.type !== "image/jpg") {
      setErrorMessage("Invalid format or size image");
    } else {
      const urlFile = await uploadFiles(event.file);
      setCompanyForm({
        ...companyForm,
        companyLogoUrl: urlFile.data.Result,
      });
      setForceUpdate(!forceUpdate);
    }
  };

  const handleCreateCompany = () => {
    const validation = validateNewCompany.validate(companyForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = companyErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setCompanyErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      create.reset();
      create.mutate(companyForm, {
        onSuccess: () => {
          reset();
          data.refetch();
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      });
    }
  };

  const handleUpdateCompany = () => {
    const validation = validateNewCompany.validate(companyForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = companyErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setCompanyErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      update.reset();
      update.mutate(
        {
          ...companyForm,
          id: companyId,
        },
        {
          onSuccess: () => {
            reset();
            data.refetch();
          },
          onError: (err) => {
            setErrorMessage(err.response.data.Errors[0]);
          },
        }
      );
    }
  };

  const handleChangeTable = (pagination) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container>
      <ModalCompanies
        companyErrorForm={companyErrorForm}
        companyForm={companyForm}
        handleCreateCompany={handleCreateCompany}
        handleClose={handleClose}
        handleChange={handleChange}
        openModal={openModal}
        handleUploadLogo={handleUploadLogo}
        errorMessage={errorMessage}
        loadingImage={loadingImage}
        setLoadingImage={setLoadingImage}
        isLoading={loading}
        updateCompany={updateCompany}
        handleUpdateCompany={handleUpdateCompany}
        loadingUpdate={loadingUpdate}
      />
      <Div width="100%" height="55px" justify="space-between" align="center">
        <Div direction="column">
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            Companies
          </Text>
        </Div>
        <Div gap="24px">
          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="324px"
            onChange={handleChangeSearch}
          />
          <Button background={theme.colors.salmon} onClick={handleNewCompany}>
            New Company
          </Button>
        </Div>
      </Div>
      <Div m="32px 0 0 0">
        <Table
          columns={tableColumns}
          data={dataCompanies}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText:
              isLoading || loadingStatus ? (
                <Div justify="center" gap="16px">
                  {[...Array(9)].map((v, idx) => (
                    <Skeleton
                      title={false}
                      paragraph={{
                        rows: 10,
                        width: "100%",
                      }}
                      loading
                      key={idx}
                    />
                  ))}
                </Div>
              ) : (
                "No data"
              ),
          }}
        />
      </Div>
    </Container>
  );
};
