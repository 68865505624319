import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useItemsName = () => {
  const data = useQuery(["itemsNameList"], () =>
    axiosClient.get(`/Item/GetItems`)
  );

  return data;
};
export default useItemsName;
