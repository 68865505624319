import React from "react";
import { Wrapper, WrapperIcon } from "./styles";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import success from "../../../assets/icons/success.svg";

export const ModalHeader = ({
  icon,
  title,
  align,
  margin,
  padding,
  hasBorder,
  iconWidth,
  iconHeight,
  iconBackground,
  ...rest
}) => {
  return (
    <Wrapper
      align={align}
      margin={margin}
      padding={padding}
      hasBorder={hasBorder}
      {...rest}
    >
      {icon && (
        <WrapperIcon style={{ background: iconBackground }}>
          <img alt="icon" src={icon} width={iconWidth} height={iconHeight} />
        </WrapperIcon>
      )}
      {title && (
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.dark}
          weight={theme.fonts.weight.bold}
        >
          {title}
        </Text>
      )}
    </Wrapper>
  );
};
