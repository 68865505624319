import styled from "styled-components";
import { Button } from "antd";
import { theme } from "../../styles/theme";

export const CustomButton = styled(Button)`
  width: ${({ width }) => width || "175px"};
  height: ${({ height }) => height || "50px"};
  background: ${({ background }) => background || theme.colors.white} 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: ${({ color }) => color || theme.colors.white};
  font-size: ${({ size }) => size || theme.fonts.size.sm};
  font-weight: ${({ weight }) => weight || theme.fonts.weight.regular};
  border: 1px solid;
  border-color: ${({ border }) => border || "transparent"};

  && {
    &.ant-btn-link {
      &:hover {
        color: ${({ color }) => color || theme.colors.white};
      }
    }

    &.ant-btn-default {
      &:hover {
        color: ${({ color }) => color || theme.colors.white};
        border: 1px solid;
        border-color: ${({ border }) => border || "transparent"};
      }
    }
  }
`;
