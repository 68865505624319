import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import usePropertiesValues from "../../pages/Sheet/hooks/usePropertiesValues";
import { Col, Row } from "antd";
import { Div, Text } from "../../styles/Common";
import { Button } from "../Button";
import { Select } from "../Select";
import { theme } from "../../styles/theme";
import { Link } from "../Link";
import AsyncSelect from "../AsyncSelect";
import { Input } from "../Input";

export const Content = ({
  currentImage,
  setPropertiesVisible,
  setOpenFirstTime,
  selectOptions,
  images,
  setImages,
  itemNames,
  itemProperties,
}) => {
  const getProperties = usePropertiesValues();
  const queryClient = useQueryClient();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [optionsDependentSelect, setOptionsDependentSelect] = useState({});
  const [usedLetters, setUsedLetters] = useState([]);
  const [usedNumbers, setUsedNumbers] = useState([]);

  const newImages = images.find((ele) => ele.key === currentImage.key);

  const [lengthNumber, setLengthNumber] = useState({
    feet: newImages.length
      ? newImages.length.match(/(\d+)'/)
        ? newImages.length.match(/(\d+)'/)[1]
        : 0
      : 0,
    inches: newImages.length
      ? newImages.length.match(/(?:'|\s)(\d+(?:\/\d+)?)["'-]?/) &&
        !newImages.length
          .match(/(?:'|\s)(\d+(?:\/\d+)?)["'-]?/)[1]
          .includes("/")
        ? newImages.length.match(/(?:'|\s)(\d+(?:\/\d+)?)["'-]?/)[1]
        : 0
      : 0,
    fraction: newImages.length
      ? newImages.length.match(/(?:\d+(?:'|\s))?(?:(\d+\/\d+)|(\d+(?:-\d+)?"))/)
        ? newImages.length.match(
            /(?:\d+(?:'|\s))?(?:(\d+\/\d+)|(\d+(?:-\d+)?"))/
          )[1] === undefined
          ? 0
          : newImages.length.match(
              /(?:\d+(?:'|\s))?(?:(\d+\/\d+)|(\d+(?:-\d+)?"))/
            )[1]
        : 0
      : 0,
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const selectedLetters = images.map((ele) => ele.itemId !== 6 && ele.name);
    const selectedNumbers = images.map((ele) => ele.itemId === 6 && ele.name);
    setUsedLetters(selectedLetters);
    setUsedNumbers(selectedNumbers);
  }, [images]);

  const handleDelete = (key) => {
    const newImages = images.filter((ele) => ele.key !== key);
    setImages(newImages);
    setForceUpdate(!forceUpdate);
  };

  const handleAddProp = () => {
    setPropertiesVisible(false);
    setOpenFirstTime(false);
    setOptionsDependentSelect({});
    queryClient.removeQueries("itemsNameList");
  };

  const arrayAllProp =
    newImages.itemId !== 6 &&
    newImages.itemPropertiesValue.map((ele) => ele.propertyName);

  const arrayProp =
    newImages.itemId !== 6 && arrayAllProp.filter((ele) => ele !== "H number");

  const itemProperty = itemProperties?.find((ele) => ele.item === "WeldMarks");
  const diameterOptions = itemProperty?.properties[0].values.map((ele) => ({
    value: ele.id,
    label: ele.value,
  }));

  const optionsDependency = async (elem, options) => {
    if (
      elem.dependencies.every(
        (dep) =>
          newImages.itemPropertiesValue.find(
            (ele) => ele.propertyName === dep.name
          )?.id !== 0
      ) ||
      elem.dependencies.some(
        (dep) =>
          newImages.itemPropertiesValue.find(
            (ele) => ele.propertyName === dep.name
          )?.id !== 0
      )
    ) {
      getProperties.reset();
      try {
        const resp = await getProperties.mutateAsync({
          item: itemNames(currentImage.itemId),
          propertyName: elem.name,
          dependenciesValue: elem.dependencies.map((ele) => ({
            name: ele.name,
            valueId: newImages.itemPropertiesValue.find(
              (elem) => elem.propertyName === ele.name
            )?.id,
            index: ele.index,
          })),
        });
        return resp.data;
      } catch (e) {
        setErrorMessage(e.response.data.Errors[0]);
      }
    }

    if (options && options[elem.name]) {
      return options[elem.name];
    }
    return [];
  };

  const handleChange = (value, id, currentImage) => {
    const newImages = images.map((ele) => {
      if (ele.name === currentImage.name) {
        if (ele === currentImage) {
          return { ...ele, [id]: value };
        } else if (ele.name === "" && currentImage.name === "") {
          return ele;
        }
      }
      return ele;
    });
    setImages(newImages);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeLength = (event) => {
    const { id, value } = event.target;
    const newLengths = lengthNumber;

    if (id === "fraction") {
      newLengths[id] = value;
    } else {
      newLengths[id] = value.replace(/\D/g, "");
    }
    setLengthNumber(newLengths);

    const newImages = images.filter((ele) => ele.key !== currentImage.key);
    currentImage.length = `${newLengths.feet !== "" ? newLengths.feet : 0}' ${
      newLengths.inches !== "" ? newLengths.inches : 0
    }-${newLengths.fraction !== "" ? newLengths.fraction : 0}"`;
    setImages([...newImages, currentImage]);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeInputs = (event, currentImage) => {
    const { id, value } = event.target;
    if (id === "heatNumber") {
      const newImages = images.filter((ele) => ele.name !== currentImage.name);
      const nameImages = images
        .filter((ele) => ele.name === currentImage.name)
        .map((ele) => {
          return { ...ele, [id]: value };
        });
      setImages([...newImages, ...nameImages]);
      setForceUpdate(!forceUpdate);
    } else {
      const { id, value } = event.target;
      const newImages = images.filter((ele) => ele.key !== currentImage.key);
      currentImage[id] = value;
      setImages([...newImages, currentImage]);
      setForceUpdate(!forceUpdate);
    }
  };

  const handleChangeSelect = (value, id, currentImage) => {
    const newImages = images.filter((ele) => ele.key !== currentImage.key);
    currentImage[id] = value;
    setImages([...newImages, currentImage]);
    setForceUpdate(!forceUpdate);
  };

  const handleSelectProperties = (value, id, currentImage) => {
    const newImage = images.map((image) => {
      if (image.name === currentImage.name) {
        const item = { ...image };
        const propertieIndex = item.itemPropertiesValue.findIndex(
          (ele) => ele.propertyName === id
        );
        item.itemPropertiesValue[propertieIndex].id = value;
        const dependentProperty = selectOptions.filter((ele) =>
          ele.dependencies.find((elem) => elem.name === id)
        );
        const dependentPropertyName = dependentProperty?.map((ele) => ele.name);

        if (dependentPropertyName.length > 0) {
          dependentPropertyName.forEach((ele) => {
            let propertyObj = item.itemPropertiesValue.find(
              (elem) => elem.propertyName === ele
            );
            if (propertyObj) {
              propertyObj.id = 0;
            }
          });
        }
        return item;
      }
      return image;
    });
    setImages(newImage);
    setForceUpdate(!forceUpdate);
  };

  const nameOptions = (image) => {
    if (image.itemId !== 6) {
      const letter = [...Array(26)].map((_, i) => String.fromCharCode(97 + i));
      const lettersOptions = letter.map((ele) => {
        const disabled = usedLetters.includes(ele.toUpperCase());
        return {
          label: ele.toUpperCase(),
          value: ele.toUpperCase(),
          disabled,
        };
      });
      return lettersOptions;
    } else {
      const numbersOptions = Array.from({ length: 99 }, (_, index) => {
        const disabled = usedNumbers.includes(`${index + 1}`);
        return {
          label: `${index + 1}`,
          value: `${index + 1}`,
          disabled,
        };
      });
      return numbersOptions;
    }
  };

  const handleChangeWelders = (event, indx, currentImage) => {
    const { value } = event.target;
    const newImages = images.filter((ele) => ele.key !== currentImage.key);
    currentImage.welder[indx] = value;
    setImages([...newImages, currentImage]);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Div direction="column">
      <Div direction="column" gap="10px">
        <Div gap="30px" justify="space-between" align="center">
          <Text>Name</Text>
          <Select
            value={newImages.name}
            placeholder="Name"
            options={nameOptions(newImages)}
            onChange={(e) => handleChange(e, "name", currentImage)}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent="Data not found"
            showSearch
            height="30px"
            radius="8px"
            style={{ width: "135px" }}
          />
        </Div>
        {newImages.itemId !== 6 && (
          <Div gap="30px" justify="space-between" align="center">
            <Text>H number</Text>
            <Input
              id="heatNumber"
              value={newImages.heatNumber}
              maxLength={15}
              placeholder="H number"
              onChange={(e) => handleChangeInputs(e, currentImage)}
              disabled={newImages.name === ""}
              height="30px"
              radius="8px"
              style={{ width: "135px" }}
            />
          </Div>
        )}
        {newImages.itemId === 2 && (
          <Div gap="30px" justify="space-between" align="center">
            <Text>Length</Text>
            <Div>
              <Input
                id="feet"
                value={lengthNumber.feet}
                maxLength={15}
                placeholder="Feet"
                onChange={handleChangeLength}
                disabled={newImages.name === ""}
                height="30px"
                radius="8px"
                style={{
                  width: "37px",
                  padding: "0px 5px",
                  textAlign: "-webkit-center",
                }}
              />
              '
              <Input
                id="inches"
                value={lengthNumber.inches}
                maxLength={15}
                placeholder="Inches"
                onChange={handleChangeLength}
                disabled={newImages.name === ""}
                height="30px"
                radius="8px"
                style={{
                  width: "37px",
                  marginRight: "3px",
                  marginLeft: "3px",
                  padding: "0px 5px",
                  textAlign: "-webkit-center",
                }}
              />
              -
              <Input
                id="fraction"
                value={lengthNumber.fraction}
                maxLength={15}
                placeholder="Fraction"
                onChange={handleChangeLength}
                disabled={newImages.name === ""}
                height="30px"
                radius="8px"
                style={{
                  width: "37px",
                  marginLeft: "3px",
                  padding: "0px 5px",
                  textAlign: "-webkit-center",
                }}
              />
              "
            </Div>
          </Div>
        )}
        {newImages.itemId !== 6 ? (
          <Row gutter={[40, 0]}>
            <Col>
              {arrayProp.map((ele, indx) => (
                <Text
                  key={indx}
                  style={{
                    height: "30px",
                    marginBottom: "10px",
                    marginTop: "5px",
                  }}
                >
                  {ele}
                </Text>
              ))}
            </Col>
            <Col>
              {selectOptions?.map((elem, index) => (
                <AsyncSelect
                  key={index}
                  index={index}
                  elem={elem}
                  newImages={newImages}
                  currentImage={currentImage}
                  optionsDependency={optionsDependency}
                  optionsDependentSelect={optionsDependentSelect}
                  handleSelectProperties={handleSelectProperties}
                />
              ))}
            </Col>
          </Row>
        ) : (
          <>
            <Div gap="30px" justify="space-between" align="center">
              <Text>NDE Number</Text>
              <Input
                id="ndeNumber"
                value={newImages.ndeNumber}
                maxLength={15}
                placeholder="NDE Number"
                onChange={(e) => handleChangeInputs(e, currentImage)}
                disabled={newImages.name === ""}
                height="30px"
                radius="8px"
                style={{ width: "135px" }}
              />
            </Div>
            <Div gap="30px" justify="space-between" align="center">
              <Text>Diameter</Text>
              <Select
                value={newImages.diameterId}
                placeholder="Select"
                options={diameterOptions}
                onChange={(e) =>
                  handleChangeSelect(e, "diameterId", currentImage)
                }
                autoComplete="off"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent="Data not found"
                showSearch
                disabled={newImages.name === ""}
                height="30px"
                radius="8px"
                style={{ width: "135px" }}
              />
            </Div>

            {newImages.welder.map((ele, indx) => (
              <Row gutter={[60, 0]}>
                <Col>
                  <Text
                    style={{
                      height: "30px",
                      marginTop: "5px",
                    }}
                  >
                    {`Welder ${indx + 1}`}
                  </Text>
                </Col>
                <Col>
                  <Input
                    id="welder"
                    value={ele}
                    maxLength={15}
                    placeholder={`Welder ${indx + 1}`}
                    onChange={(e) => handleChangeWelders(e, indx, currentImage)}
                    disabled={newImages.name === ""}
                    height="30px"
                    radius="8px"
                    style={{ width: "135px" }}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
      </Div>
      <Div direction="column" m="21.5px 0 0 0" justify="center" align="center">
        <Button
          onClick={handleAddProp}
          width="143px"
          height="35px"
          background={theme.colors.salmon}
        >
          Save and Add
        </Button>
        <Link
          onClick={() => handleDelete(newImages.key)}
          size={theme.fonts.size.sm}
          color={theme.colors.salmon}
          style={{ padding: 0 }}
        >
          Delete Item
        </Link>
      </Div>
    </Div>
  );
};
