import React from "react";
import { Alert, Col, Row, Spin } from "antd";
import { Input } from "../Input";
import { Modal } from "../Modal";
import { Upload } from "antd";
import { Div, Text } from "../../styles/Common";
import { WrapperIcon } from "./styles";
import { Button } from "../Button";
import uploadIcon from "../../assets/icons/upload.svg";
import { theme } from "../../styles/theme";
import { LoadingOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

export const ModalCompanies = ({
  companyErrorForm,
  companyForm,
  handleCreateCompany,
  handleClose,
  handleChange,
  openModal,
  handleUploadLogo,
  errorMessage,
  loadingImage,
  setLoadingImage,
  isLoading,
  updateCompany,
  handleUpdateCompany,
  loadingUpdate,
}) => {
  const props = {
    multiple: true,
    showUploadList: false,
    maxCount: 1,
    customRequest: handleUploadLogo,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setLoadingImage(true);
      }
    },
  };

  const createCompanyForm = (errorForm, form, loadingImg) => {
    return (
      <>
        <Row gutter={32}>
          <Col md={12}>
            <Input
              id="companyName"
              onChange={handleChange}
              value={form.companyName}
              placeholder="Company Name"
              maxLength={100}
              error={errorForm.companyName.error}
              helper={
                errorForm.companyName.error ? errorForm.companyName.message : ""
              }
            />
            <Input
              id="adminName"
              onChange={handleChange}
              value={form.adminName}
              placeholder="User Admin Name"
              maxLength={100}
              error={errorForm.adminName.error}
              helper={
                errorForm.adminName.error ? errorForm.adminName.message : ""
              }
              style={{ marginTop: "16px" }}
            />
            <Input
              id="adminLastName"
              onChange={handleChange}
              value={form.adminLastName}
              placeholder="User Admin Lastname"
              maxLength={100}
              error={errorForm.adminLastName.error}
              helper={
                errorForm.adminLastName.error
                  ? errorForm.adminLastName.message
                  : ""
              }
              style={{ marginTop: "16px" }}
            />
            <Input
              id="adminEmail"
              onChange={handleChange}
              value={form.adminEmail}
              placeholder="User Admin Email"
              maxLength={100}
              error={errorForm.adminEmail.error}
              helper={
                errorForm.adminEmail.error ? errorForm.adminEmail.message : ""
              }
              style={{ marginTop: "16px" }}
            />
          </Col>
          <Col md={12}>
            <Dragger
              {...props}
              style={{
                background: theme.colors.white,
                border: `1px dashed ${theme.colors.light2}`,
                borderRadius: "10px",
              }}
            >
              {form.companyLogoUrl !== "" && (
                <img alt="logo" src={form.companyLogoUrl} width="100%" />
              )}
              {form.companyLogoUrl === "" && loadingImg && (
                <Div width="100%" justify="center" align="center">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                          color: theme.colors.salmon,
                        }}
                        spin
                      />
                    }
                  />
                </Div>
              )}
              {form.companyLogoUrl === "" && !loadingImage && (
                <Div width="100%" justify="center" direction="column">
                  <Div width="100%" justify="center">
                    <WrapperIcon>
                      <img
                        alt="upload"
                        src={uploadIcon}
                        width="24"
                        height="24"
                      />
                    </WrapperIcon>
                  </Div>
                  <Text
                    color={theme.colors.salmon}
                    size={theme.fonts.size.xs}
                    mt="16px"
                    mb="6px"
                  >
                    Click to upload or drag and drop
                  </Text>
                  <Text color={theme.colors.light5} size={theme.fonts.size.xs}>
                    png or jpg
                  </Text>
                  <Text color={theme.colors.light5} size={theme.fonts.size.xs}>
                    (max, 50mb)
                  </Text>
                </Div>
              )}
            </Dragger>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleClose}
      align={"left"}
      padding={"41px 39px"}
      width={"776px"}
    >
      <Modal.Header padding="16px 0 16px 0" title={"New Company"} />
      <Modal.Body
        margin="16px 0 24px 0"
        maxHeight={400}
        style={{ paddingRight: "24px" }}
      >
        {createCompanyForm(companyErrorForm, companyForm, loadingImage)}
      </Modal.Body>
      <Modal.Footer style={{ flexDirection: "column" }}>
        {errorMessage !== "" && (
          <Div m="0 0 20px 0" justify="flex-end">
            <Alert closable message={`${errorMessage}`} type="error" />
          </Div>
        )}
        <Div gap="32px" justify="center">
          <Button
            onClick={handleClose}
            border={theme.colors.dark}
            color={theme.colors.dark}
          >
            Cancel
          </Button>
          <Button
            onClick={updateCompany ? handleUpdateCompany : handleCreateCompany}
            background={theme.colors.salmon}
            loading={updateCompany ? loadingUpdate : isLoading}
          >
            {updateCompany ? "Update Company" : "Create Company"}
          </Button>
        </Div>
      </Modal.Footer>
    </Modal>
  );
};
