import React, { useEffect, useState } from "react";
import { Div } from "../../styles/Common";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { theme } from "../../styles/theme";
import { Link } from "../Link";
import { Alert } from "antd";
import { TextArea } from "../TextArea";
import notesIcon from "../../assets/icons/notes-white.svg";
import useUpdateNotes from "../../pages/Documents/hooks/useUpdateDocuments";

export const ModalNotes = ({
  openModal,
  setOpenModal,
  notes,
  docId,
  source,
  refetch
}) => {
  const update = useUpdateNotes();
  const { isLoading } = update;

  const [notesValue, setNotesValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (notes !== null) {
      setNotesValue(notes);
    } else {
      setNotesValue("");
    }
  }, [notes]);

  const handleClose = () => {
    setOpenModal(false);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event) => {
    const { value } = event.target;

    setNotesValue(value);
    setForceUpdate(!forceUpdate);
  };

  const handleUpdateNotes = () => {
    update.reset();
    update.mutate(
      { id: docId, notes: notesValue },
      {
        onSuccess: () => {
          setOpenModal(false);
          if (source === "table") {
            refetch();
          }
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      }
    );
  };

  const createDocumentForm = (notes) => {
    return (
      <>
        <Div direction="column" gap="16px" height="min-content">
          <TextArea
            id="notes"
            placeholder={"Add your note..."}
            onChange={handleChange}
            value={notes}
            maxLength={500}
            style={{ height: "150px", width: "350px" }}
          />
        </Div>
      </>
    );
  };

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleClose}
        align={"left"}
        padding={"41px 39px"}
        width={"412px"}
      >
        <Modal.Header
          align={"center"} 
          padding="16px 0 16px 0" 
          title={"Notes"}
          icon={notesIcon}
          iconBackground={theme.colors.dark}
        />
        <Modal.Body margin="16px 0 24px 0" maxHeight={400}>
          {createDocumentForm(notesValue)}
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          {errorMessage !== "" && (
            <Div m="0 0 20px 0" justify="flex-end">
              <Alert closable message={`${errorMessage}`} type="error" />
            </Div>
          )}
          <Div justify="center" align="center" direction="column">
            <Button
              onClick={handleUpdateNotes}
              background={theme.colors.salmon}
              loading={isLoading}
              width="100%"
            >
              Save Note
            </Button>
            <Link color={theme.colors.salmon} onClick={handleClose}>
              Cancel
            </Link>
          </Div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
