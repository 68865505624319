import React, { useState } from "react";
import { Col, Div, Row, Text } from "../../styles/Common";
import { LoginWrapper } from "../Login/styles";
import logo from "../../assets/images/logo.png";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import useSendEmailResetPassword from "./hooks/useSendEmailResetPassword";
import { validateEmailRequired } from "../../utilities/validations";
import { Modal } from "../../components/Modal";
import { Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "../../components/Link";

export const ResetPasswordRequest = () => {
  const navigate = useNavigate();
  const resetPassword = useSendEmailResetPassword();
  const { isLoading } = resetPassword;
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorValidation, setErrorValidation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleSend = () => {
    const validation = validateEmailRequired.email.validate(email, {
      abortEarly: false,
    });

    if (validation.error) {
      setError(true);
      setErrorValidation("Invalid email");
      setForceUpdate(!forceUpdate);
    } else {
      resetPassword.reset();
      resetPassword.mutate(email, {
        onSuccess: () => {
          setShowSuccess(true);
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      });
    }
  };

  const handleClose = () => {
    setShowSuccess(false);
    navigate("/");
  };

  const handleLogin = () => {
    navigate("/");
  };

  return (
    <LoginWrapper>
      <Modal
        open={showSuccess}
        onCancel={handleClose}
        align={"center"}
        padding={36}
      >
        <Modal.Header
          iconName={"success"}
          iconWidth={"24px"}
          iconHeight={"24px"}
          align={"-webkit-center"}
          padding="16px 0 16px 0"
          title={"Email sent successfully"}
        />
        <Modal.Body margin="16px 0 24px 0" maxHeight={200}>
          <Text
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.medium}
            color={theme.colors.grey1}
          >
            In your email you will find a link so you can change your password
          </Text>
        </Modal.Body>
      </Modal>
      <Row width="1200px" height="700px">
        <Col width="50%" height="100%">
          <img alt="logo" src={logo} width="336px" height="143px" />
        </Col>
        <Col width="50%" height="100%" align="center" justify="center">
          <Card width="380px">
            <Div direction="column" align="center" width="100%">
              <Text
                color={theme.colors.dark2}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h3}
                mb="58px"
              >
                Reset Password
              </Text>
              <Input
                width="287px"
                placeholder="Email"
                id="email"
                onChange={handleChange}
                error={error}
                helper={error ? errorValidation : ""}
              />
              {errorMessage !== "" && (
                <Div m=" 20px 0 0 0" width="100%" justify="flex-end">
                  <Alert closable message={`${errorMessage}`} type="error" />
                </Div>
              )}
              <Button
                onClick={handleSend}
                width="100%"
                loading={isLoading}
                background={theme.colors.salmon}
                size={theme.fonts.size.sm}
                style={{ marginTop: "32px" }}
              >
                Reset Password
              </Button>
              <Div>
                <Link onClick={handleLogin}>Back to Login</Link>
              </Div>
            </Div>
          </Card>
        </Col>
      </Row>
    </LoginWrapper>
  );
};
