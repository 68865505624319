export const abr = {
  "CARBON STEEL": "CS",
  "STAINLESS STEEL": "SS",
  "CHROME ALLOY": "CHRM",
  HDPE: "HDPE",
  Pipe: "PIPE",
  Flange: "FLG",
  Elbow: "ELB",
  Tee: "TEE",
  Cap: "CAP",
  Reducer: "RED",
  OLet: "O'LET",
  Valve: "VLV",
  Cross: "CROSS",
  BARE: "BLK",
  FBE: "FBE",
  "FBE/ARO": "FBE/ARO",
  2000: "2K",
  3000: "3K",
  6000: "6K",
  9000: "9K",
};
