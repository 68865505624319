import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDetail = (id) => {
  const data = useQuery(["sheetDetail", id], () =>
    axiosClient.get(`/Sheet/GetSheetByIdToEdit?sheetId=${id}`)
  );

  return data;
};
export default useDetail;
