import React from "react";
import { Upload as AntdUpload, Col, Row } from "antd";

import { Button } from "../Button";
import uploadFile from "../../assets/icons/file-upload.svg";
import uploadFileDisabled from "../../assets/icons/file-upload-disabled.svg";
import trash from "../../assets/icons/trash-salmon.svg";

import { Div, Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { FileNameWrapper, FileWrapper } from "./styles";

const Upload = ({
  uploadLabel,
  onChange,
  handleDownload,
  handleRemove,
  fileList,
  disabled,
  multiple,
  ...rest
}) => {
  const handleChange = ({ file }) => {
    onChange(file);
  };

  const UploadListItemDownload = ({ id, name }) => {
    return (
      <Row gutter={[16, 8]} style={{ marginTop: "10px" }}>
        <Col sm={24}>
          <Div gap={8} align="center">
            <FileWrapper aria-label="list-doc">
              <FileNameWrapper>
                <Div m={"0 0 0 10px"}>
                  <Text>{name}</Text>
                </Div>
              </FileNameWrapper>
            </FileWrapper>
            <img
              alt="trash"
              src={trash}
              height={20}
              style={{ marginBottom: "10px", cursor: "pointer" }}
              onClick={() => handleRemove(id)}
            />
          </Div>
        </Col>
      </Row>
    );
  };

  return (
    <Div direction="column">
      <AntdUpload
        multiple={multiple}
        showUploadList={false}
        onChange={(file) => handleChange(file)}
        itemRender={() => {}}
        disabled={disabled}
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        {...rest}
      >
        <Button
          background={theme.colors.white}
          border={disabled ? theme.colors.disabled : theme.colors.salmon}
          color={disabled ? theme.colors.disabled : theme.colors.salmon}
          icon={
            <img
              alt="upload"
              src={disabled ? uploadFileDisabled : uploadFile}
              style={{ marginRight: "20px" }}
            />
          }
          width="364px"
          disabled={disabled}
        >
          {uploadLabel}
        </Button>
      </AntdUpload>
      {fileList?.length > 0 &&
        fileList.map((ele, indx) => (
          <UploadListItemDownload key={indx} id={ele.uid} name={ele.name} />
        ))}
    </Div>
  );
};

Upload.defaultProps = {
  size: "170px",
  uploadLabel: "Cargar Documentos",
};

export default Upload;
