import React, { useMemo } from "react";
import BoardSquare from "./BoardSquare";
import Item from "./Item";
import grid from "../../assets/images/grid.svg";

const Board = ({ images, setImages, handleChangeImages }) => {
  function renderSquare(i, renderImages, setRenderImages) {
    const x = i % 15;
    const y = Math.floor(i / 14);

    return (
      <div key={i} style={{ width: "35.45px", height: "35.45px" }}>
        <BoardSquare
          type="ITEM"
          x={x}
          y={y}
          images={renderImages}
          handleChangeImages={setRenderImages}
        >
          {renderImages.find((im) => im.x === x && im.y === y) && (
            <Item
              currentImage={renderImages.find((im) => im.x === x && im.y === y)}
              images={images}
              setImages={setImages}
            />
          )}
        </BoardSquare>
      </div>
    );
  }

  const squares = useMemo(() => {
    const returnSquares = [];
    for (let i = 0; i < 210; i++) {
      returnSquares.push(renderSquare(i, images, handleChangeImages));
    }
    return returnSquares;
  }, [images]);

  return (
    <div
      style={{
        width: "531.75px",
        height: "496.95px",
        display: "flex",
        flexWrap: "wrap",
        backgroundImage: `url(${grid})`,
      }}
    >
      {squares}
    </div>
  );
};

export default Board;
