import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Div } from "../../styles/Common";
import { Button } from "../Button";
import { Input } from "../Input";
import { Modal } from "../Modal";
import { Select } from "../Select";
import { theme } from "../../styles/theme";
import { Link } from "../Link";
import { Alert } from "antd";
import useCreateLine from "../../pages/ProjectDetail/hooks/useCreateLine";
import useSettingList from "../../core/hooks/useSettingList";
import useOptions from "../../core/hooks/useOptions";
import { validateNewLine } from "../../utilities/validations";
import useDiameterList from "../../pages/ProjectDetail/hooks/useDiameterList";
import useUpdateLine from "../../pages/ProjectDetail/hooks/useUpdateLine";
import useGetLineInfo from "../../pages/ProjectDetail/hooks/useGetLineInfo";
import { toast } from "react-hot-toast";

export const ModalLine = ({
  openModal,
  setOpenModal,
  data,
  id,
  projectAFE,
  projectName,
  setImages,
  lineId,
  setLineId,
}) => {
  const navigate = useNavigate();
  const create = useCreateLine();
  const update = useUpdateLine();
  const { isLoading } = create;
  const settingList = useSettingList();
  const { data: settings } = settingList;
  const [settingId, setSettingId] = useState(null);
  const options = useOptions(settingId);
  const { data: optionsList } = options;
  const diameter = useDiameterList();
  const { data: diameterList } = diameter;
  const getLineInfo = useGetLineInfo(lineId);
  const { data: dataLine, isSuccess, isLoading: isLoadingUpdate } = getLineInfo;

  const initialValues = {
    lineNumber: "",
    lineDiameter: null,
    pressureId: null,
    description: "",
  };

  const initialValuesError = {
    lineNumber: {
      error: false,
      message: "",
    },
    lineDiameter: {
      error: false,
      message: "",
    },
    pressureId: {
      error: false,
      message: "",
    },
    description: {
      error: false,
      message: "",
    },
  };

  const [lineForm, setLineForm] = useState(initialValues);
  const [lineErrorForm, setLineErrorForm] = useState(initialValuesError);
  const [pressureOptions, setPressureOptions] = useState([]);
  const [diameterOptions, setDiameterOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (settings?.groupSettings.length > 0) {
      const id = settings.groupSettings.find(
        (ele) => ele.name === "Pressure "
      ).id;
      setSettingId(id);
    }
  }, [settings?.groupSettings]);

  useEffect(() => {
    if (optionsList?.groupSettings.length > 0) {
      setPressureOptions(
        optionsList.groupSettings.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }))
      );
    }
  }, [optionsList]);

  useEffect(() => {
    if (diameterList?.items.length > 0) {
      setDiameterOptions(
        diameterList.items.map((ele) => ({
          value: ele.id,
          label: ele.value,
        }))
      );
    } else {
      setDiameterOptions([]);
    }
  }, [diameterList]);

  useEffect(() => {
    if (isSuccess) {
      setLineForm({
        lineNumber: dataLine?.data.lineNumber,
        lineDiameter: dataLine?.data.lineDiameter,
        pressureId: dataLine?.data.pressureId,
        description: dataLine?.data.description,
      });
    }
  }, [isSuccess]);

  const reset = () => {
    setOpenModal(false);
    setLineForm(initialValues);
    setLineErrorForm(initialValuesError);
    setErrorMessage("");
    setLineId(null);
    getLineInfo.refetch();
    setForceUpdate(!forceUpdate);
  };

  const handleClose = () => {
    reset();
  };

  const handleSelectDiameter = (value) => {
    setLineForm({ ...lineForm, lineDiameter: value });
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value) => {
    setLineForm({ ...lineForm, pressureId: value });
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = lineErrorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setLineErrorForm(newErrorForm);

    const newLineForm = lineForm;
    newLineForm[id] = value;
    setLineForm(newLineForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCreateLine = () => {
    const validation = validateNewLine.validate(lineForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = lineErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setLineErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      create.reset();
      create.mutate(
        { ...lineForm, projectId: Number(id) },
        {
          onSuccess: (res) => {
            reset();
            navigate(
              `/projects/${id}/sheet/${res?.data?.sheetId}?projectName=${projectName}&lineNumber=${lineForm.lineNumber}&lineId=${res?.data?.lineId}&projectAFE=${projectAFE}&sheetVersionId=${res?.data?.sheetVersionId}`
            );
            if (data) {
              data.refetch();
            }
            if (!!setImages) {
              setImages([]);
              setForceUpdate(!forceUpdate);
            }
          },
          onError: (err) => {
            setErrorMessage(err.response.data.Errors[0]);
          },
        }
      );
    }
  };

  const handleUpdateLine = () => {
    const validation = validateNewLine.validate(lineForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = lineErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setLineErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      update.reset();
      update.mutate(
        { ...lineForm, lineId: lineId },
        {
          onSuccess: () => {
            reset();
            toast.success("Your line has been updated successfully");
            data.refetch();
          },
          onError: (err) => {
            setErrorMessage(err.response.data.Errors[0]);
          },
        }
      );
    }
  };

  const createLineForm = (errorForm, form) => {
    return (
      <>
        <Div direction="column" gap="16px">
          <Input
            id="lineNumber"
            onChange={handleChange}
            value={form.lineNumber}
            placeholder="Line Number"
            maxLength={100}
            error={errorForm.lineNumber.error}
            helper={
              errorForm.lineNumber.error ? errorForm.lineNumber.message : ""
            }
          />
          <Select
            id="lineDiameter"
            onChange={(e) => handleSelectDiameter(e)}
            value={form.lineDiameter}
            placeholder="Line Diameter"
            options={diameterOptions}
            error={errorForm.lineDiameter.error}
            helper={
              errorForm.lineDiameter.error ? errorForm.lineDiameter.message : ""
            }
            style={{ width: "100%" }}
          />
          <Select
            id="pressureId"
            onChange={(e) => handleSelect(e)}
            value={form.pressureId}
            placeholder="High or Low pressure"
            options={pressureOptions}
            error={errorForm.pressureId.error}
            helper={
              errorForm.pressureId.error ? errorForm.pressureId.message : ""
            }
            style={{ width: "100%" }}
          />
          <Input
            id="description"
            onChange={handleChange}
            value={form.description}
            placeholder="Line Description"
            maxLength={100}
            error={errorForm.description.error}
            helper={
              errorForm.description.error ? errorForm.description.message : ""
            }
          />
        </Div>
      </>
    );
  };

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleClose}
        align={"left"}
        padding={"41px 39px"}
        width={"412px"}
      >
        <Modal.Header padding="16px 0 16px 0" title={"New Line"} />
        <Modal.Body margin="16px 0 24px 0" maxHeight={400}>
          {createLineForm(lineErrorForm, lineForm)}
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          {errorMessage !== "" && (
            <Div m="0 0 20px 0" justify="flex-end">
              <Alert closable message={`${errorMessage}`} type="error" />
            </Div>
          )}
          <Div justify="center" direction="column">
            <Button
              onClick={lineId ? handleUpdateLine : handleCreateLine}
              background={theme.colors.salmon}
              loading={isLoading || isLoadingUpdate}
              width="100%"
            >
              {lineId ? "Update Line" : "Create Line"}
            </Button>
            <Link color={theme.colors.salmon} onClick={handleClose}>
              Cancel
            </Link>
          </Div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
