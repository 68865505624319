import styled from "styled-components";
import { theme } from "../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.light};
  width: 100%;
  height: max-content;
  padding: 30.5px 97px 29px 97px;
  overflow-y: auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height || null};
  width: ${({ width }) => width || null};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
`;

export const Div = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || null};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  gap: ${({ gap }) => gap || null};
  margin: ${({ m }) => m || null};
  padding: ${({ p }) => p || null};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  min-width: ${({ minwidth }) => minwidth || null};
  min-height: ${({ minheight }) => minheight || null};
  max-width: ${({ maxwidth }) => maxwidth || null};
  max-height: ${({ maxheight }) => maxheight || null};
  flex-direction: ${({ direction }) => direction || null};
`;

export const Text = styled.div`
  color: ${(props) => (props.color ? props.color : props.theme.colors.grey1)};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  text-align: ${(props) => props.align};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || null};
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 9px 20px #00000008;
  border-radius: 10px;
  opacity: 1;
`;
