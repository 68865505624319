import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useAllVersionsInfo = (currentPage, pageSize, searchValue, sheetId) => {
  const data = useQuery(
    ["versionsAllInfoList", currentPage, pageSize, searchValue, sheetId],
    () =>
      axiosClient.get(
        `/Sheet/GetSheetAllInfoList?currentPage=${currentPage}&pageSize=${pageSize}&searchValue=${searchValue}&sheetId=${sheetId}`
      )
  );

  return { data };
};
export default useAllVersionsInfo;
