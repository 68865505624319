import styled from "styled-components";
import { theme } from "../../styles/theme";

export const WrapperIcon = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  opacity: 1;
`;
