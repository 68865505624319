import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDocumentType = () => {
  const data = useQuery(["documentTypeList"], () =>
    axiosClient.get(`/DocumentType/GetDocumentTypeList`)
  );

  return data;
};
export default useGetDocumentType;
