import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Title } from "./styles";

const DrawerHeader = ({ pt = 72, title }) => {
  return (
    <Wrapper pt={pt}>
      <Title>{title}</Title>
    </Wrapper>
  );
};

DrawerHeader.propTypes = {
  pt: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default DrawerHeader;
