import React from "react";
import PropTypes from "prop-types";

import { CustomSwitch } from "./styles";

const Switch = ({ onChange, defaultChecked, ...rest }) => {
  return (
    <CustomSwitch
      defaultChecked={defaultChecked}
      onChange={onChange}
      {...rest}
    />
  );
};

Switch.defaultProps = { defaultChecked: false };

Switch.propTypes = {
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
};

export default Switch;
