import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDebounce from "../../core/hooks/useDebounce";
import { Container, Div, Text } from "../../styles/Common";
import useAllVersionsInfo from "./hooks/useAllVersionsInfo";
import searchIcon from "../../assets/icons/search.svg";
import { theme } from "../../styles/theme";
import { Input } from "../../components/Input";
import { Table } from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import edit from "../../assets/icons/edit.svg";
import editDisabled from "../../assets/icons/edit-disabled.svg";
import drawing from "../../assets/icons/drawing.svg";
import drawingDisabled from "../../assets/icons/drawing-disabled.svg";
import back from "../../assets/icons/arrow-left.svg";
import { Link } from "../../components/Link";
import { dateFormat, downloadFile } from "../../utilities/helpers";
import { toast } from "react-hot-toast";
import { axiosClient, getLocalUser } from "../../core/services/axiosInstance";

export const AllVersions = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id, sheetId } = useParams();
  const projectName = new URLSearchParams(search).get("projectName");
  const lineNumber = new URLSearchParams(search).get("lineNumber");
  const projectAFE = new URLSearchParams(search).get("projectAFE");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [dataVersions, setDataVersions] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const getAllVersions = useAllVersionsInfo(
    pages.current,
    perPage,
    searchValue,
    sheetId
  );
  const { data } = getAllVersions;
  const { data: dataAllVersions, isLoading } = data;

  const handleEdit = (sheetId, sheetNumber) => {
    navigate(
      `/projects/${id}/sheet/${sheetId}?projectName=${projectName}&projectAFE=${projectAFE}&sheetNumber=${sheetNumber}`
    );
  };

  const handleExportDrawing = async (sheetVersionId, sheetNumber) => {
    await toast.promise(
      downloadFile(
        `/Item/ExportDrawing?sheetVersionId=${sheetVersionId}&projectId=${id}`,
        `${projectName}-${lineNumber}-${sheetNumber}-${sheetVersionId}.pdf`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  useEffect(() => {
    if (dataAllVersions?.data?.sheets?.items.length > 0) {
      const lastVersion =
        dataAllVersions?.data?.sheets.currentPage === 1
          ? _.maxBy(dataAllVersions.data.sheets.items, "version").version
          : null;

      const dataTable = dataAllVersions.data.sheets.items.map((ele, i) => ({
        ...ele,
        key: `${ele.version}`,
        actions: [
          {
            key: `1${i}`,
            name: "Export Drawing",
            icon: ele.version !== lastVersion ? drawingDisabled : drawing,
            color: theme.colors.dark,
            disabled: ele.version !== lastVersion,
            onClick: () =>
              handleExportDrawing(ele.sheetVersionId, ele.sheetVersion),
          },
          {
            key: `1${i}`,
            name: "Edit",
            icon: ele.version !== lastVersion ? editDisabled : edit,
            color: theme.colors.salmon,
            disabled: ele.version !== lastVersion,
            onClick: () => handleEdit(ele.sheetId, ele.sheetVersion),
          },
        ],
      }));

      setDataVersions(dataTable);
      setTotalItems(dataAllVersions?.data?.sheets.totalItems);
    } else {
      setDataVersions([]);
    }
  }, [dataAllVersions?.data]);

  const tableColumns = [
    {
      title: "Version Date",
      dataIndex: "versionDate",
      key: "versionDate",
      render: (text) => dateFormat(text),
    },
    {
      title: "Sheet #",
      dataIndex: "sheetVersion",
      key: "sheetVersion",
    },
    {
      title: "Version #",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "left",
      width: 400,
      render: (actions) => (
        <Div gap="88px">
          {actions.map((action, i) => (
            <Link
              onClick={action.onClick}
              disabled={action.disabled}
              key={i}
              color={action.disabled ? theme.colors.disabled : action.color}
              icon={
                <img
                  alt={`${action.icon}`}
                  src={action.icon}
                  style={{ marginRight: "8px" }}
                />
              }
              styles={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {action.name}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleChangeTable = (pagination) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
    setForceUpdate(!forceUpdate);
  };

  const handleProjectDetail = () => {
    navigate(
      `/projects/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  return (
    <Container style={{ padding: "0px 97px 29px 97px" }}>
      <Div m="0 0 5px 0">
        <Link
          onClick={handleProjectDetail}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" height="55px" justify="space-between" align="center">
        <Div direction="column" gap="6px">
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            {`Versions Line Number ${lineNumber}`}
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
            {projectName}
          </Text>
        </Div>
        <Div>
          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="324px"
            onChange={handleChangeSearch}
          />
        </Div>
      </Div>
      <Div m="32px 0 0 0">
        <Table
          columns={tableColumns}
          data={dataVersions}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: isLoading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((v, idx) => (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                    key={idx}
                  />
                ))}
              </Div>
            ) : (
              "No data"
            ),
          }}
        />
      </Div>
    </Container>
  );
};
