import React, { useState, useEffect } from "react";
import { CustomCollapse } from "./styles";
import arrowUp from "../../../assets/icons/arrow-up-gray.svg";
import arrowDown from "../../../assets/icons/arrow-down-gray.svg";
import { theme } from "../../../styles/theme";

const { Panel } = CustomCollapse;

export const ItemsCollapse = ({ panels }) => {
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (panels.length > 0) {
      const lastKey = panels[panels.length - 1].key;
      setActiveKey(lastKey);
    }
  }, [panels.length]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  return (
    <CustomCollapse
      accordion
      activeKey={activeKey}
      onChange={handlePanelChange}
      expandIcon={({ isActive }) =>
        isActive ? (
          <img alt="arrow-up" src={arrowUp} />
        ) : (
          <img alt="arrow-down" src={arrowDown} />
        )
      }
      expandIconPosition={"end"}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        background: theme.colors.white,
        border: "none",
        width: "100%",
      }}
    >
      {panels.map((ele, indx) => (
        <Panel
          id={indx}
          key={ele.key}
          header={ele.header}
          extra={ele.extra}
          style={{
            background: theme.colors.light,
            borderRadius: "11px",
          }}
        >
          {ele.panel}
        </Panel>
      ))}
    </CustomCollapse>
  );
};
