import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDiameterList = () => {
  const data = useQuery(["diameterList"], () =>
    axiosClient.get(`/Item/GetDiameterList`)
  );

  return { data: data?.data?.data };
};
export default useDiameterList;
