import styled from "styled-components";
import { theme } from "../../styles/theme";

export const CustomCard = styled.div`
  display: flex;
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: ${({ shadow }) => shadow || "0px 3px 20px #00000014"};
  border-radius: ${({ radius }) => radius || "30px"};
  opacity: 1;
  padding: ${({ padding }) => padding || "47px"};
`;
