import React from "react";
import { Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { CustomTextArea } from "./styles";

export const TextArea = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  height,
  ...rest
}) => {
  return (
    <div>
      <CustomTextArea
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        width={width}
        height={height}
        autoComplete="off"
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.sm} color={theme.colors.salmon} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
