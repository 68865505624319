import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetLineInfo = (id) => {
  const data = useQuery(
    ["getLineInfo", id],
    () => axiosClient.get(`/Line/GetLineInfoByLineId?lineId=${id}`),
    {
      enabled: !!id,
    }
  );
  return data;
};
export default useGetLineInfo;
