import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetCompany = (id) => {
  const data = useQuery(
    ["getCompany", id],
    () => axiosClient.get(`/Company/GetCompanyById?companyId=${id}`),
    {
      enabled: id !== null,
    }
  );
  return { data };
};
export default useGetCompany;
