import React, { useState, useEffect } from "react";
import { Select } from "../Select";

const AsyncSelect = (props) => {
  const {
    index,
    elem,
    optionsDependency,
    optionsDependentSelect,
    currentImage,
    newImages,
    handleSelectProperties,
  } = props;
  const [selectOptions, setSelectOptions] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (elem.options.length === 0) {
          const result = await optionsDependency(elem, optionsDependentSelect);
          if (result.items.length > 0) {
            setSelectOptions(
              result.items.map((ele) => ({
                value: ele.id,
                label: ele.value,
              }))
            );
            setForceUpdate(!forceUpdate);
          } else {
            setSelectOptions([]);
          }
        } else {
          setSelectOptions(elem.options);
          setForceUpdate(!forceUpdate);
        }
      } catch (e) {}
    };

    fetchOptions();
  }, [elem]);

  return (
    <Select
      key={index}
      allowClear
      value={
        newImages.itemPropertiesValue.find(
          (eleme) => eleme.propertyName === elem.name
        )?.id === 0
          ? null
          : newImages.itemPropertiesValue.find(
              (eleme) => eleme.propertyName === elem.name
            )?.id
      }
      placeholder={`Select`}
      options={selectOptions}
      onChange={(e) => handleSelectProperties(e, `${elem.name}`, currentImage)}
      autoComplete="off"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      notFoundContent="Data not found"
      showSearch
      height="30px"
      radius="8px"
      disabled={newImages.name === "" || selectOptions.length === 0}
      style={{
        width: "135px",
        marginBottom: "10px",
      }}
    />
  );
};
export default AsyncSelect;
