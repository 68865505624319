import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.white};
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 -24px;
`;

export const Title = styled.h4`
  color: ${theme.colors.dark};
  font-size: ${theme.fonts.size.h4};
  font-weight: ${theme.fonts.weight.bold};
  margin-bottom: 0;
`;
