import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Card } from "../Card";
import { Div, Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Input } from "../Input";
import { Row, Col } from "antd";
import { Link } from "../Link";
import addIcon from "../../assets/icons/add.svg";
import Switch from "../Switch";
import { Button } from "../Button";
import useCreateTemplate from "../../pages/DigitalizationDocuments/hooks/useCreateTemplate";
import useUpdateTemplate from "../../pages/DigitalizationDocuments/hooks/useUpdateTemplate";
import closeIcon from "../../assets/icons/gray-close.svg";
export const TemplateForm = ({ templateInfo }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const create = useCreateTemplate();
  const update = useUpdateTemplate();
  const [templateForm, setTemplateForm] = useState({
    creationDate: null,
    name: "",
    items: false,
    documentTypeGeneralItemsList: [""],
    itemsList: [""],
  });
  const [errorName, setErrorName] = useState(false);
  const [errorNameMessage, setErrorNameMessage] = useState("");

  useEffect(() => {
    if (templateInfo) {
      setTemplateForm({
        ...templateInfo,
        documentTypeGeneralItemsList: templateInfo?.generalInformation?.length === 0 ? [""] : templateInfo.generalInformation,
        itemsList: templateInfo?.itemsInformation?.length === 0 ? [""] : templateInfo.itemsInformation,
      });
    }
  }, [templateInfo]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newInfo = {...templateForm};

    newInfo[id] = value;
    setTemplateForm(newInfo);
    setErrorName(false);
    setErrorNameMessage("");
  };

  const handleChangeArray = (event, indx) => {
    const { value, id } = event.target;
    const newInfo = { ...templateForm };

    newInfo[id][indx] = value;
    setTemplateForm(newInfo);
  };
  
  const handleSwitch = (value) => {
    const newInfo = {...templateForm};
    if (value === false) {
      setTemplateForm({...newInfo, items: value, itemsList: []});
    } else {
      setTemplateForm({...newInfo, items: value})
    }
  };

  const handleAdd = () => {
    const newInfo = {...templateForm};
    setTemplateForm({...newInfo, documentTypeGeneralItemsList: [...newInfo.documentTypeGeneralItemsList, ""]});
  };

  const handleAddItem = () => {
    const newInfo = {...templateForm};
    setTemplateForm({...newInfo, itemsList: [...newInfo.itemsList, ""]});
  };

  const regexName = /^[a-zA-Z0-9-_ ]*$/
  const maxLengthName = 31

  const nameValidations = (name) => {
    if (name === "") {
      return "Required field";
    }
    if (name.length > maxLengthName) {
      return `The maximum number of characters is ${maxLengthName}`;
    }
    if (!regexName.test(name)) {
      return "Special characters not allowed";
    }
    return "";
  };

  const handleSave = () => {
    const emptyInfo = templateForm.documentTypeGeneralItemsList.length === 1 && templateForm.documentTypeGeneralItemsList[0] === "";
    const emptyItems = templateForm.itemsList.length === 1 && templateForm.itemsList[0] === "";
    const nameError = templateForm.name === "" || templateForm.name.length > maxLengthName || !regexName.test(templateForm.name);

    const orderArray = (array) => {
      return array.map((item, index) => ({
        name: item,
        order: index + 1
      }));
    };

    const payload = {
      name: templateForm.name,
      documentTypeGeneralItemsList: emptyInfo ? [] : orderArray(templateForm.documentTypeGeneralItemsList),
      itemsList: emptyItems ? [] : orderArray(templateForm.itemsList),
    };

    const action = id ? update : create;

    if (nameError) {
      setErrorName(true);
      setErrorNameMessage(nameValidations(templateForm.name));
    } else {
      action.reset();
      action.mutate(id ? {...payload, id: id} : {...payload}, {
        onSuccess: () => {
          setErrorName(false);
          setErrorNameMessage("");
          toast.success(`The tamplate has been ${id ? "updated" : "created"} successfully`);
          navigate("/digitalization-documents");
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      });
    }
  };

  const handleDelete = (id, indx) => {
    const newInfo = templateForm[id].filter((ele, index) => index !== indx);
    setTemplateForm({...templateForm, [id]: [...newInfo]}); 
  };

  return (
    <>
      <Card 
        radius="15px"
        padding={"24px 33px 37px 33px"}
      >
        <Div direction="column" width="100%">
          <Text
            color={theme.colors.dark}
            size={theme.fonts.size.h5}
            weight={theme.fonts.weight.bold}
            mb="24px"
          >
            General Information
          </Text>
          <Input 
            id="name"
            placeholder={"Document Type Name"}
            width={"358px"}
            value={templateForm.name}
            onChange={handleChange}
            error={errorName}
            helper={errorName ? errorNameMessage : ""}
          />
          <Text size={theme.fonts.size.sm} color={theme.colors.grey1} mt="18px" mb="24px">
            Please provide the information for each of the editable fields in the document
          </Text>
          <Row gutter={[16, 24]} style={{ width: "100%", marginBottom: "18px" }}>
            {templateForm?.documentTypeGeneralItemsList?.map((ele, indx) => (
              <Col md={8} key={indx}>
                <Input 
                  id="documentTypeGeneralItemsList"
                  placeholder={`Field ${indx + 1}`}
                  width={"358px"}
                  value={ele} 
                  onChange={(e) => handleChangeArray(e, indx)}
                  suffix={
                    indx !== 0 ? 
                    <img 
                      alt="close" 
                      src={closeIcon} 
                      style={{ cursor: 'pointer' }}
                      onClick={(event) => {
                        handleDelete("documentTypeGeneralItemsList", indx);
                        event.stopPropagation();
                      }}
                    /> 
                    : null
                  }
                />
              </Col>
            ))}
          </Row>
          <Link width="min-content" onClick={handleAdd}>
            <Div align="center" gap="7px">
              <img alt="add" src={addIcon} />
              Add Field
            </Div>
          </Link>
        </Div>
      </Card>
      <Div align="center" gap="10px" m={"38px 0 36px 0"}>
        <Switch checked={templateForm.items} onChange={handleSwitch} />
        <Text size={theme.fonts.size.sm} color={theme.colors.grey1}>
          Does this type of document have any items?
        </Text>
      </Div>
      {templateForm.items && (
        <Card 
          radius="15px"
          padding={"24px 33px 37px 33px"}
        >
          <Div direction="column" width="100%">
            <Text
              color={theme.colors.dark}
              size={theme.fonts.size.h5}
              weight={theme.fonts.weight.bold}
              
            >
              Items Information
            </Text>
            <Text size={theme.fonts.size.sm} color={theme.colors.grey1} mt="9px" mb="24px">
              Specify the information to be completed for each item
            </Text>
            <Row gutter={[16, 24]} style={{ width: "100%", marginBottom: "18px" }}>
              {templateForm?.itemsList?.map((ele, indx) => (
                <Col md={8} key={indx}>
                  <Input 
                    id="itemsList"
                    placeholder={`Field ${indx + 1}`}
                    width={"358px"}
                    value={ele} 
                    onChange={(e) => handleChangeArray(e, indx)}
                    suffix={
                      indx !== 0 ? 
                      <img 
                        alt="close" 
                        src={closeIcon} 
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => {
                          handleDelete("itemsList", indx);
                          event.stopPropagation();
                        }}
                      /> 
                      : null
                    }
                  />
                </Col>
              ))}
            </Row>
            <Link width="min-content" onClick={handleAddItem}>
              <Div align="center" gap="7px">
                <img alt="add" src={addIcon} />
                Add Field
              </Div>
            </Link>
          </Div>
        </Card>
      )}
      <Button 
        background={theme.colors.salmon} 
        onClick={handleSave}
        loading={create.isLoading || update.isLoading}
        style={{ marginTop: templateForm.items ? "60px" : "0px" }}
      >
        {id ? "Update Template" : "Create Template"}
      </Button>
    </>
  )
}
