import styled from "styled-components";
import { Collapse } from "antd";
import { theme } from "../../../styles/theme";

export const CustomCollapse = styled(Collapse)`
  .ant-collapse-item {
    border: none !important;
    .ant-collapse-content {
      background: ${theme.colors.light};
      border-radius: 0 0 11px 11px !important;
      height: max-content;
      max-height: max-content;
      overflow-y: auto;
      padding-bottom: 10px;
      border: 1px solid ${theme.colors.light};

      .ant-collapse-content-box {
        padding-top: 0px;
      }
    }

    .ant-collapse-header {
      border-radius: 0 0 11px 11px !important;
      color: ${theme.colors.grey1};
      font-size: ${theme.fonts.size.default};
      font-weight: ${theme.fonts.weight.regular};
      align-items: center;

      .ant-collapse-arrow {
        font-size: 14px;
      }
    }
  }
`;
