import React, { useState } from "react";
import { Div } from "../../../styles/Common";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { rotatePlugin } from '@react-pdf-viewer/rotate';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

export const PdfViewer = ({ documentUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const rotatePluginInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton, Rotate } = rotatePluginInstance;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <>
      <Div
        width="100%"
        height="100%"
        direction="column"
        justify="center"
        align="center"
        p="10px 0"
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            <RotateBackwardButton />
            <RotateForwardButton />
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <Viewer             
            fileUrl={documentUrl}
            plugins={[zoomPluginInstance, rotatePluginInstance]}
            onLoadSuccess={onDocumentLoadSuccess} 
          />
        </Worker>
      </Div>
    </>
  )
};