import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Board from "./Board";

const DnD = ({
  images,
  setImages,
  handleChangeImages,
  lettersOptions,
  numbersOptions,
  firstTime,
}) => {
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Board
          images={images}
          setImages={setImages}
          handleChangeImages={handleChangeImages}
        />
      </DndProvider>
    </div>
  );
};

export default DnD;
