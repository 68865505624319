import { Col, Row } from "antd";
import React from "react";
import { WrapperItem } from "../../styles/Line";
import { theme } from "../../styles/theme";

export const DrawerContent = ({
  itemsList,
  itemType,
  imageSelected,
  handleSelectImage,
}) => {
  return (
    <Row gutter={[24, 24]} style={{ width: "330px" }}>
      {itemsList.map(
        (ele) =>
          ele.id === itemType &&
          ele.itemDetailList.map((elem, index) => (
            <Col key={index} md={6}>
              <WrapperItem
                onClick={() =>
                  handleSelectImage(ele.id, elem.id, elem.url, ele.name)
                }
                style={{
                  backgroundColor:
                    elem.id === imageSelected
                      ? theme.colors.salmon1
                      : theme.colors.light,
                  border: `1px solid ${
                    elem.id === imageSelected
                      ? theme.colors.salmon
                      : theme.colors.grey3
                  }`,
                  cursor: "pointer",
                }}
              >
                <img alt="item" src={elem.url} />
              </WrapperItem>
            </Col>
          ))
      )}
    </Row>
  );
};
