import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Div, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import { theme } from "../../styles/theme";
import back from "../../assets/icons/arrow-left.svg";
import { dateFormat } from "../../utilities/helpers";
import { TemplateForm } from "../../components/Documents/TemplateForm";

export const CreateTemplate = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(
      `/digitalization-documents`
    );
  };

  const currentDate = new Date();

  return (
    <Container>
      <Div m="0 0 15px 0">
        <Link
          onClick={handleBack}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" justify="space-between" align="center" m="0 0 26px 0">
        <Div
          direction="column"
          gap="6px"
          style={{
            width: "350px",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            New Document Type
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
            Creation date: {dateFormat(currentDate)}
          </Text>
        </Div>
      </Div>
      <TemplateForm />
    </Container>
  )
};