import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteDocument = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.post(
      `/DocumentType/EnableOrDisableDocument?documentId=${formData.documentId}&isEnabled=${formData.isEnabled}`
    );
  });
  return mutation;
};
export default useDeleteDocument;
