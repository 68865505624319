import styled from "styled-components";
import { Button } from "antd";
import { theme } from "../../styles/theme";

export const CustomLink = styled(Button)`
  padding: 0px;
  height: 50px;
  width: min-content;
  color: ${({ color }) => color || theme.colors.salmon};
  font-size: ${({ size }) => size || theme.fonts.size.default};

  && {
    &.ant-btn-link {
      &:hover {
        color: ${({ color }) => color || theme.colors.salmon};
      }
    }

    &.ant-btn-default {
      &:hover {
        color: ${({ color }) => color || theme.colors.salmon};
      }
    }
  }
`;
