import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "common",
  initialState: {
    itemProperties: null,
    itemsNamesList: null,
    items: null,
  },
  reducers: {
    setItemProperties: (state, action) => {
      return {
        ...state,
        itemProperties: action.payload,
      };
    },
    setItemsNamesList: (state, action) => {
      return {
        ...state,
        itemsNamesList: action.payload,
      };
    },
    setItems: (state, action) => {
      return {
        ...state,
        items: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setItemProperties, setItemsNamesList, setItems } = slice.actions;
