import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import common from "./common";
import sheet from "./sheet";

const reducer = combineReducers({
  common,
  sheet,
});

export const store = configureStore({
  reducer,
});
