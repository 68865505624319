import styled from "styled-components";
import { Drawer } from "antd";
import { theme } from "../../styles/theme";

export const CustomDrawer = styled(Drawer)`
  &&& .ant-drawer {
    &-mask {
      backdrop-filter: blur(4px);
    }
    &-header-title {
      justify-content: right;
    }
    &-body {
      padding-top: 0;
    }
    &-content-wrapper {
      overflow: hidden;
      border-radius: 0px 30px 30px 0px !important;
    }
    &-close {
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-weight: ${theme.fonts.weight.medium};
      &:hover {
        color: ${theme.colors.salmon};
      }
      &:before {
        content: "${(props) => props.closelabel}";
        color: ${theme.colors.salmon};
      }
    }
  }
`;
