import styled from "styled-components";
import { Modal } from "antd";
import { theme } from "../../styles/theme";

export const CustomModal = styled(Modal)`
  :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
    .ant-modal-content {
      text-align: ${(props) => props.align};
      background: ${theme.colors.white} 0% 0% no-repeat padding-box;
      box-shadow: 0px 9px 20px #00000008;
      border-radius: 20px;
      opacity: 1;
      width: ${(props) => (props.width ? props.width : "412px")};
    }

    .ant-modal-close {
      &:hover {
        background: transparent;
      }
      &-x {
        color: ${theme.colors.salmon};
      }
    }

    &-body {
      padding: ${(props) => props.padding};
    }
  }
`;
