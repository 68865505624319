import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCompaniesInfo = (currentPage, pageSize, searchValue) => {
  const data = useQuery(
    ["companiesAllInfoList", currentPage, pageSize, searchValue],
    () =>
      axiosClient.get(
        `/Company/GetCompaniesAllInfoList?currentPage=${currentPage}&pageSize=${pageSize}&searchValue=${searchValue}`
      )
  );

  return { data };
};
export default useCompaniesInfo;
