import styled from "styled-components";
import { theme } from "../../styles/theme";

export const FileWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${theme.colors.white};
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
