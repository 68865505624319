import React from "react";

const Square = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: "transparent",
        color: "transparent",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

export default Square;
