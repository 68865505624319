import React from "react";
import { Div } from "../../../styles/Common";
import trash from "../../../assets/icons/trash.svg";

export const TrashButton = ({ handleDelete, index }) => {
  return (
    <Div
      width="max-content" 
      onClick={(event) => {
        event.stopPropagation();
        handleDelete(index);
      }} 
      style={{ cursor: "pointer" }}
    >
      <img alt="trash" src={trash} />
    </Div>
  )
};