export const theme = {
  colors: {
    white: "#FFFFFF",
    dark: "#2B2D42",
    dark2: "#2B2D43",
    salmon: "#ED6D6F",
    salmon1: "#FFEFEF",
    light: "#F9FBFC",
    light2: "#DCE2E6",
    light3: "#DEE4EF",
    light4: "#C6CFE0",
    light5: "#BCC6D8",
    grey: "#5B667A",
    grey1: "#8D99AE",
    grey2: "#8F99AC",
    grey3: "#EDF0F5",
    green: "#63E359",
    disabled: "#bbbcbd",
  },
  fonts: {
    size: {
      h1: "3rem",
      h2: "2.5rem",
      h3: "2rem",
      h4: "1.5rem",
      h5: "1.25rem",
      default: "1rem",
      sm: "0.875rem",
      xs: "0.75rem",
      xxs: "0.625rem",
      xxxs: "0.5rem",
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },
  breakpoints: {
    xs: "320px",
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1200px",
  },
  zIndex: {
    base: 1,
    menu: 2,
    overlay: 3,
    modal: 4,
    toast: 5,
  },
};
