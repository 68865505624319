import React, { useState } from "react";
import { Container, Div, Text } from "../../styles/Common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "../../components/Link";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import searchIcon from "../../assets/icons/search.svg";
import back from "../../assets/icons/arrow-left.svg";
import { ModalDocument } from "../../components/Documents/ModalDocument";
import useDebounce from "../../core/hooks/useDebounce";
import useGetDocumentList from "./hooks/useGetDocumentList";
import trash from "../../assets/icons/trash-salmon.svg";
import download from "../../assets/icons/download.svg";
import edit from "../../assets/icons/edit.svg";
import notesIcon from "../../assets/icons/Notes.svg";
import { Skeleton } from "../../components/Skeleton";
import { Table } from "../../components/Table";
import { useEffect } from "react";
import { dateFormat } from "../../utilities/helpers";
import useDeleteDocument from "./hooks/useDeleteDocument";
import alert from "../../assets/icons/alert.svg";
import { Modal } from "../../components/Modal";
import { Alert } from "antd";
import { ModalNotes } from "../../components/Documents/ModalNotes";
import flagIcon from "../../assets/icons/flag.svg"

export const Documents = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectAFE = new URLSearchParams(search).get("projectAFE");
  const deleteDocument = useDeleteDocument();

  const [errorMessage, setErrorMessage] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalNotes, setOpenModalNotes] = useState(false);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const [notes, setNotes] = useState(null);
  const [docId, setDocId] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const getDocuments = useGetDocumentList(
    pages.current,
    perPage,
    searchValue,
    id
  );
  const { data, isLoading } = getDocuments;
  const [modalInfo, setModalInfo] = useState({
    icon: "",
    title: "",
    message: "",
    okButtonText: "",
    cancelButtonText: "",
    okButton: () => {},
    cancelButton: () => {},
  });

  const handleBack = () => {
    navigate(
      `/projects/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDownload = (url, filename, extensionFile) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.setAttribute("download", `${filename}${extensionFile}`);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleDelete = (id) => {
    deleteDocument.reset();
    deleteDocument.mutate(
      { documentId: id, isEnabled: false },
      {
        onSuccess: () => {
          getDocuments.refetch();
          setOpenModalDelete(false);
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      }
    );
  };

  const handleModalDelete = (id) => {
    setOpenModalDelete(true);
    setModalInfo({
      icon: alert,
      title: "Are you sure to delete the document?",
      okButtonText: "Yes, remove",
      cancelButtonText: "No, cancel",
      okButton: () => handleDelete(id),
      cancelButton: handleClose,
    });
  };

  const handleEdit = (idDoc, docTypeId) => {
    navigate(`/documents/${id}/detail/${idDoc}?docTypeId=${docTypeId}&projectAFE=${projectAFE}&projectName=${projectName}`)
  };

  const handleShowNotes = (notes, id) => {
    setOpenModalNotes(true);
    setNotes(notes);
    setDocId(id);
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    if (data?.data?.documents?.items.length > 0) {
      const dataTable = data?.data.documents.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}`,
            id: ele.id,
            name: "Edit",
            icon: edit,
            color: theme.colors.salmon,
            onClick: () =>
              handleEdit(ele.id, ele.documentTypeId),
          },
          {
            key: `1${i}`,
            id: ele.id,
            name: "Notes",
            icon: notesIcon,
            color: theme.colors.salmon,
            onClick: () =>
              handleShowNotes(ele.notes, ele.id),
          },
          {
            key: `1${i}`,
            id: ele.id,
            name: "Download",
            icon: download,
            color: theme.colors.salmon,
            onClick: () =>
            handleDownload(ele.urlDocument, ele.name, ele.extensionFile),
          },
          {
            key: `1${i}`,
            id: ele.id,
            name: "Delete",
            icon: trash,
            color: theme.colors.salmon,
            onClick: () => handleModalDelete(ele.id),
          },
        ],
      }));

      setDataDocuments(dataTable);
      setTotalItems(data?.data.documents.totalItems);
    } else {
      setDataDocuments([]);
    }
  }, [data?.data]);

  const tableColumns = [
    {
      title: "Creation date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 130,
      render: (text) => dateFormat(text),
    },
    {
      title: "Document type name",
      dataIndex: "documentTypeName",
      key: "documentTypeName",
      width: 180,
    },
    {
      title: "Document name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Original name",
      dataIndex: "originalName",
      key: "originalName",
      width: 150,
    },
    {
      title: "Extension file",
      dataIndex: "extensionFile",
      key: "extensionFile",
      align: "center",
      width: 140,
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      align: "center",
      width: 150,
      render: (text) => (
        <Div align="center" gap="8px">
          {text}
          {text === "Flagged" && (
            <img alt="flag" src={flagIcon} />
          )}
        </Div>
      )
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "left",
      render: (actions) => (
        <Div gap="20px">
          {actions.map((action, i) => (
            <Link
              key={i}
              onClick={action.onClick}
              color={action.color}
              icon={
                <img
                  alt={`${action.icon}`}
                  src={action.icon}
                  style={{ marginRight: "8px" }}
                />
              }
              styles={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {action.name}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleChangeTable = (pagination) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
  };

  const handleCreate = () => {
    setOpenModal(true);
  };

  return (
    <Container>
      <ModalNotes
        openModal={openModalNotes}
        setOpenModal={setOpenModalNotes}
        notes={notes}
        docId={docId}
        source={"table"}
        refetch={getDocuments.refetch}
      />
      <ModalDocument
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        dataList={getDocuments}
      />
      <Modal
        open={openModalDelete}
        onCancel={handleClose}
        align={"center"}
        padding={36}
      >
        <Modal.Header
          icon={modalInfo.icon}
          iconBackground={theme.colors.salmon}
          iconWidth={"24px"}
          iconHeight={"24px"}
          align={"-webkit-center"}
          padding="16px 0 16px 0"
          title={modalInfo.title}
        />
        <Modal.Footer style={{ flexDirection: "column" }}>
          {errorMessage !== "" && (
            <Div m="0 0 20px 0" justify="flex-end">
              <Alert closable message={`${errorMessage}`} type="error" />
            </Div>
          )}
          <Div gap="32px" justify="center">
            {modalInfo.cancelButtonText && (
              <Button
                onClick={modalInfo.cancelButton}
                border={theme.colors.dark}
                color={theme.colors.dark}
              >
                {modalInfo.cancelButtonText}
              </Button>
            )}
            {modalInfo.okButtonText && (
              <Button
                onClick={modalInfo.okButton}
                background={theme.colors.salmon}
                loading={deleteDocument.isLoading}
              >
                {modalInfo.okButtonText}
              </Button>
            )}
          </Div>
        </Modal.Footer>
      </Modal>
      <Div m="0 0 5px 0">
        <Link
          onClick={handleBack}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" justify="space-between" align="center">
        <Div
          direction="column"
          gap="6px"
          style={{
            width: "350px",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            {projectName}
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
            {`Project AFE: ${projectAFE}`}
          </Text>
        </Div>
        <Div gap="24px" align="center">
          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="324px"
            onChange={handleChangeSearch}
          />
          <Button background={theme.colors.salmon} onClick={handleCreate}>
            New Document
          </Button>
        </Div>
      </Div>
      <Div m="32px 0 0 0" width="100%">
        <Table
          columns={tableColumns}
          data={dataDocuments}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: isLoading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((v, idx) => (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                    key={idx}
                  />
                ))}
              </Div>
            ) : (
              "No data"
            ),
          }}
        />
      </Div>
    </Container>
  );
};
