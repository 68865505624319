import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCreateDocument = () => {
  const mutation = useMutation((payload) => {
    const formData = new FormData();
    formData.append("ProjectId", payload.projectId);
    formData.append("Notes", payload.notes);
    formData.append("DocumentTypeId", payload.documentTypeId);

    payload.blobDocuments.map((doc) => formData.append("BlobDocuments", doc));

    return axiosClient.post("/DocumentType/CreateDocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });
  return mutation;
};
export default useCreateDocument;
