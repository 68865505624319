import { createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../services/axiosInstance";

export const slice = createSlice({
  name: "sheet",
  initialState: {
    optionsDependent: [],
    isSuccess: false,
    isLoading: false,
    isFail: false,
  },
  reducers: {
    getOptionsRequest: (state) => {
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        isFail: false,
      };
    },
    getOptionsSuccess: (state, action) => {
      return {
        ...state,
        optionsDependent: action.payload,
        isSuccess: true,
        isLoading: false,
        isFail: false,
      };
    },
    getOptionsFail: (state) => {
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isFail: true,
      };
    },
  },
});

export default slice.reducer;

export const { getOptionsSuccess, getOptionsRequest, getOptionsFail } =
  slice.actions;

export const getOptions = (data) => async (dispatch) => {
  dispatch(getOptionsRequest());
  try {
    await axiosClient.post(`/Item/PropertyValueList`, { ...data });
    dispatch(getOptionsSuccess());
  } catch {
    dispatch(getOptionsFail());
  }
};
