import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useItemPropertiesList = () => {
  const data = useQuery(["itemPropertiesList"], () =>
    axiosClient.get(`/Item/GetItemPropertiesList`)
  );

  return data;
};
export default useItemPropertiesList;
