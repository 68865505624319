import React from "react";
import { CustomCard } from "./styles";

export const Card = ({ 
  children, 
  width, 
  height, 
  padding, 
  radius,
  shadow 
}) => {
  return (
    <CustomCard 
      width={width} 
      height={height} 
      padding={padding} 
      radius={radius}
      shadow={shadow}
    >
      {children}
    </CustomCard>
  );
};
