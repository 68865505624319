import React from "react";
import { Div } from "../../../styles/Common";
import { Input } from "../../../components/Input";

export const GeneralPanel = ({ generalInfo, handleChangeGeneral }) => {
  return (
    <Div direction="column" gap="16px">
      {generalInfo?.map((ele, indx) => (
        <Input 
          key={indx}
          id={`${ele.name}`}
          label={`${ele.name}`}
          placeholder={`${ele.name}`}
          value={ele.value}
          onChange={(e) => handleChangeGeneral(e, indx)}
        />
      ))}
    </Div>
  )
};