import { Alert, Col, Row } from "antd";
import React from "react";
import { Div, Text } from "../../styles/Common";
import { Button } from "../Button";
import { Input } from "../Input";
import { Modal } from "../Modal";
import { Select } from "../Select";
import { theme } from "../../styles/theme";
import pinIcon from "../../assets/icons/map-pin.svg";

export const ModalProjects = ({
  openCreate,
  handleClose,
  newProjectForm,
  newProjectErrorForm,
  handleCreateProject,
  handleChange,
  handleSelect,
  companiesOptions,
  countriesOptions,
  statesOptions,
  isLoading,
  errorMessage,
  handleUpdateProject,
  isLoadingUpdate,
  openUpdate,
}) => {
  const createProjectForm = (errorForm, form) => {
    return (
      <>
        <Text
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
          color={theme.colors.dark}
          mb="16px"
        >
          General Information
        </Text>
        <Row gutter={[32, 16]}>
          <Col md={12}>
            {companiesOptions.length === 1 ? (
              <Input
                id="companyId"
                value={companiesOptions[0].label}
                disabled
              />
            ) : (
              <Select
                id="companyId"
                onChange={(e) => handleSelect("companyId", e)}
                value={form.companyId}
                placeholder="Company Name"
                autoComplete="off"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent="Data not found"
                showSearch
                options={companiesOptions}
                error={errorForm.companyId.error}
                helper={
                  errorForm.companyId.error ? errorForm.companyId.message : ""
                }
                style={{ width: "100%" }}
              />
            )}
          </Col>
          <Col md={12}>
            <Input
              id="ownerCompany"
              onChange={handleChange}
              value={form.ownerCompany}
              placeholder="Owner Company"
              maxLength={150}
              error={errorForm.ownerCompany.error}
              helper={
                errorForm.ownerCompany.error
                  ? errorForm.ownerCompany.message
                  : ""
              }
            />
          </Col>
          <Col md={12}>
            <Input
              id="projectAFE"
              onChange={handleChange}
              value={form.projectAFE}
              placeholder="Project AFE"
              maxLength={150}
              error={errorForm.projectAFE.error}
              helper={
                errorForm.projectAFE.error ? errorForm.projectAFE.message : ""
              }
            />
          </Col>
          <Col md={12}>
            <Input
              id="projectName"
              onChange={handleChange}
              value={form.projectName}
              placeholder="Project Name"
              maxLength={150}
              error={errorForm.projectName.error}
              helper={
                errorForm.projectName.error ? errorForm.projectName.message : ""
              }
            />
          </Col>
          <Col md={12}>
            <Input
              id="projectManager"
              onChange={handleChange}
              value={form.projectManager}
              placeholder="Project Manager"
              maxLength={150}
              error={errorForm.projectManager.error}
              helper={
                errorForm.projectManager.error
                  ? errorForm.projectManager.message
                  : ""
              }
            />
          </Col>
        </Row>
        <Text
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
          color={theme.colors.dark}
          mt="32px"
          mb="16px"
        >
          Project Location
        </Text>
        <Row gutter={[32, 16]}>
          <Col md={12}>
            <Select
              id="country"
              onChange={(e) => handleSelect("country", e)}
              defaultValue={226}
              options={countriesOptions}
              disabled
              placeholder="Country"
            />
          </Col>
          <Col md={12}>
            <Select
              id="stateId"
              onChange={(e) => handleSelect("stateId", e)}
              value={form.stateId}
              options={statesOptions}
              placeholder="State"
              error={errorForm.stateId.error}
              helper={errorForm.stateId.error ? errorForm.stateId.message : ""}
            />
          </Col>
          <Col md={18}>
            <Input
              id="address"
              onChange={handleChange}
              value={form.address}
              placeholder="Address"
              suffix={<img alt="search" src={pinIcon} />}
              maxLength={150}
              error={errorForm.address.error}
              helper={errorForm.address.error ? errorForm.address.message : ""}
            />
          </Col>
          <Col md={6}>
            <Input
              id="addressZipCode"
              onChange={handleChange}
              value={form.addressZipCode}
              placeholder="ZIP Code"
              maxLength={50}
              error={errorForm.addressZipCode.error}
              helper={
                errorForm.addressZipCode.error
                  ? errorForm.addressZipCode.message
                  : ""
              }
            />
          </Col>
        </Row>
        <Text
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
          color={theme.colors.dark}
          mt="32px"
          mb="16px"
        >
          Or...
        </Text>
        <Row gutter={32}>
          <Col md={18}>
            <Input
              id="coordinates"
              onChange={handleChange}
              value={form.coordinates}
              placeholder="Coordinates"
              suffix={<img alt="search" src={pinIcon} />}
              maxLength={150}
              error={errorForm.coordinates.error}
              helper={
                errorForm.coordinates.error ? errorForm.coordinates.message : ""
              }
            />
          </Col>
          <Col md={6}>
            <Input
              id="coordinatesZipCode"
              onChange={handleChange}
              value={form.coordinatesZipCode}
              placeholder="ZIP Code"
              maxLength={50}
              error={errorForm.coordinatesZipCode.error}
              helper={
                errorForm.coordinatesZipCode.error
                  ? errorForm.coordinatesZipCode.message
                  : ""
              }
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal
      open={openCreate}
      onCancel={handleClose}
      align={"left"}
      padding={"41px 39px"}
      width={"776px"}
    >
      <Modal.Header padding="16px 0 16px 0" title={"New Project"} />
      <Modal.Body
        margin="16px 0 24px 0"
        maxHeight={400}
        style={{ paddingRight: "24px" }}
      >
        {createProjectForm(newProjectErrorForm, newProjectForm)}
      </Modal.Body>
      <Modal.Footer style={{ flexDirection: "column" }}>
        {errorMessage !== "" && (
          <Div m="0 0 20px 0" justify="flex-end">
            <Alert closable message={`${errorMessage}`} type="error" />
          </Div>
        )}
        <Div gap="32px" justify="center">
          <Button
            onClick={handleClose}
            border={theme.colors.dark}
            color={theme.colors.dark}
          >
            Cancel
          </Button>
          <Button
            onClick={openUpdate ? handleUpdateProject : handleCreateProject}
            background={theme.colors.salmon}
            loading={isLoading || isLoadingUpdate}
          >
            {openUpdate ? "Update Project" : "Create Project"}
          </Button>
        </Div>
      </Modal.Footer>
    </Modal>
  );
};
