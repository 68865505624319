import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCompanies = () => {
  const data = useQuery(["companiesList"], () =>
    axiosClient.get("/Company/GetCompaniesList")
  );

  return { data: data.data?.data };
};
export default useCompanies;
