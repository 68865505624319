import React from "react";
import Square from "./Square";
import { useDrop } from "react-dnd";

const BoardSquare = ({ x, y, children, images, handleChangeImages }) => {
  const isPositionOccupied = images.some(
    (image) => image.x === x && image.y === y
  );

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "ITEM",
      drop: (move) => {
        if (!isPositionOccupied) {
          const currentImage = move.currentImage;
          currentImage.x = x;
          currentImage.y = y;
          handleChangeImages(currentImage);
        }
      },
      canDrop: () => !isPositionOccupied,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [x, y, images]
  );

  return (
    <div
      ref={drop}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Square>{children}</Square>
      {isOver && canDrop && !isPositionOccupied && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: "yellow",
          }}
        />
      )}
    </div>
  );
};

export default BoardSquare;
