import React, { useEffect, useState } from "react";
import { Div, Text } from "../../styles/Common";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Select } from "../Select";
import { theme } from "../../styles/theme";
import { Link } from "../Link";
import { Alert } from "antd";
import { validateNewDocument } from "../../utilities/validations";
import useCreateDocument from "../../pages/Documents/hooks/useCreateDocument";
import useGetDocumentType from "../../pages/Documents/hooks/useGetDocumentType";
import Upload from "../Upload";
import { TextArea } from "../TextArea";

export const ModalDocument = ({ openModal, setOpenModal, id, dataList }) => {
  const create = useCreateDocument();
  const { isLoading } = create;
  const typesList = useGetDocumentType();
  const { data, isSuccess } = typesList;

  const initialValues = {
    blobDocuments: [],
    documentTypeId: null,
    notes: "",
  };

  const initialValuesError = {
    blobDocuments: {
      error: false,
      message: "",
    },
    documentTypeId: {
      error: false,
      message: "",
    },
    notes: {
      error: false,
      message: "",
    },
  };

  const [documentForm, setDocumentForm] = useState(initialValues);
  const [errorForm, setErrorForm] = useState(initialValuesError);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      setDocumentTypeOptions(
        data?.data.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }))
      );
    }
  }, [isSuccess]);

  const handleUploadDocument = (file, fileList) => {
    const size = 104857600;

    const extension = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const validFiles = fileList.filter(
      (ele) => extension.includes(ele.type) && ele.size < size
    );

    const currentDocumentCount = documentForm.blobDocuments.length;

    if (validFiles.length > 5 - currentDocumentCount) {
      setDocumentForm({
        ...documentForm,
        blobDocuments: [
          ...documentForm.blobDocuments,
          ...validFiles.slice(0, 5 - currentDocumentCount),
        ],
      });
    } else {
      setDocumentForm({
        ...documentForm,
        blobDocuments: [...documentForm.blobDocuments, ...validFiles],
      });
    }

    setForceUpdate(!forceUpdate);
  };

  const reset = () => {
    setOpenModal(false);
    setDocumentForm(initialValues);
    setErrorForm(initialValuesError);
    setErrorMessage("");
    setForceUpdate(!forceUpdate);
  };

  const handleClose = () => {
    reset();
  };

  const handleSelect = (value) => {
    setDocumentForm({ ...documentForm, documentTypeId: value });
    setErrorForm({
      ...errorForm,
      documentTypeId: { error: false, message: "" },
    });
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event) => {
    const { value } = event.target;

    setDocumentForm({ ...documentForm, notes: value });
    setErrorForm({
      ...errorForm,
      notes: { error: false, message: "" },
    });
    setForceUpdate(!forceUpdate);
  };

  const handleCreateDocument = () => {
    const newForm = {
      ...documentForm,
      projectId: Number(id),
    };

    const validation = validateNewDocument.validate(newForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      create.reset();
      create.mutate(newForm, {
        onSuccess: () => {
          reset();
          dataList.refetch();
        },
        onError: (err) => {
          setErrorMessage(err.response.data.Errors[0]);
        },
      });
    }
  };

  const handleDeleteDocument = (id) => {
    const newDocuments = documentForm.blobDocuments.filter(
      (ele) => ele.uid !== id
    );

    setDocumentForm({ ...documentForm, blobDocuments: newDocuments });
    setForceUpdate(!forceUpdate);
  };

  const sortedDocumentTypeOptions = documentTypeOptions.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const createDocumentForm = (errorForm, form) => {
    return (
      <>
        <Div direction="column" gap="16px" height="min-content">
          <Select
            id="documentTypeId"
            onChange={(e) => handleSelect(e)}
            value={form.documentTypeId}
            placeholder="Document type"
            options={sortedDocumentTypeOptions}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            error={errorForm.documentTypeId.error}
            helper={
              errorForm.documentTypeId.error
                ? errorForm.documentTypeId.message
                : ""
            }
            style={{ width: "100%" }}
          />
          <TextArea
            id="notes"
            onChange={handleChange}
            value={form.notes}
            placeholder="Notes"
            maxLength={500}
            error={errorForm.notes.error}
            helper={errorForm.notes.error ? errorForm.notes.message : ""}
            style={{ height: "80px" }}
          />
          <Div direction="column">
            <Text size={theme.fonts.size.sm} color={theme.colors.grey} mb="5px">
              The maximum allowed is five documents of type pdf, doc, or xlsx,
              with a total weight of up to 100MB per document.
            </Text>
            <Upload
              uploadLabel={"Upload documents"}
              onChange={() => {}}
              multiple={true}
              beforeUpload={handleUploadDocument}
              handleRemove={(id) => handleDeleteDocument(id)}
              fileList={form.blobDocuments}
              disabled={form.blobDocuments.length === 5}
            />
            {errorForm.blobDocuments.error && (
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.salmon}
                mt="2px"
              >
                Required field
              </Text>
            )}
          </Div>
        </Div>
      </>
    );
  };

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleClose}
        align={"left"}
        padding={"41px 39px"}
        width={"412px"}
      >
        <Modal.Header padding="16px 0 16px 0" title={"New Document"} />
        <Modal.Body margin="16px 0 24px 0" maxHeight={400}>
          {createDocumentForm(errorForm, documentForm)}
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          {errorMessage !== "" && (
            <Div m="0 0 20px 0" justify="flex-end">
              <Alert closable message={`${errorMessage}`} type="error" />
            </Div>
          )}
          <Div justify="center" align="center" direction="column">
            <Button
              onClick={handleCreateDocument}
              background={theme.colors.salmon}
              loading={isLoading}
              width="100%"
            >
              Create Document
            </Button>
            <Link color={theme.colors.salmon} onClick={handleClose}>
              Cancel
            </Link>
          </Div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
