import React, { useEffect, useState } from "react";
import { Container, Div, Text } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import searchIcon from "../../assets/icons/search.svg";
import { validateNewProject } from "../../utilities/validations";
import useCreateProject from "./hooks/useCreateProject";
import { ModalProjects } from "../../components/Projects/ModalProject";
import useCompanies from "./hooks/useCompanies";
import useCountries from "./hooks/useCountries";
import useStates from "./hooks/useStates";
import useProjectsInfo from "./hooks/UseProjectsInfo";
import useDebounce from "../../core/hooks/useDebounce";
import { Table } from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import { Link } from "../../components/Link";
import show from "../../assets/icons/show.svg";
import edit from "../../assets/icons/edit.svg";
import file from "../../assets/icons/file-text.svg";
import { useNavigate } from "react-router-dom";
import useUpdateProject from "./hooks/useUpdateProject";
import useGetProjectInfo from "./hooks/useGetProjectInfo";

export const Projects = () => {
  const navigate = useNavigate();
  const create = useCreateProject();
  const update = useUpdateProject();
  const { isLoading } = create;
  const { data: companies } = useCompanies();
  const { data: countries } = useCountries();
  const { data: states } = useStates(226);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [dataProjects, setDataProjects] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const getProjects = useProjectsInfo(pages.current, perPage, searchValue);
  const { data } = getProjects;
  const { data: dataProjectsList, isLoading: loading } = data;
  const [openUpdate, setOpenUpdate] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const getProjectInfo = useGetProjectInfo(projectId);
  const {
    data: projectInfo,
    isSuccess,
    isLoading: isLoadingUpdate,
  } = getProjectInfo;

  const initialValues = {
    companyId: null,
    ownerCompany: "",
    projectAFE: "",
    projectName: "",
    projectManager: "",
    stateId: null,
    address: "",
    addressZipCode: "",
    coordinates: "",
    coordinatesZipCode: "",
  };

  const initialValuesError = {
    companyId: {
      error: false,
      message: "",
    },
    ownerCompany: {
      error: false,
      message: "",
    },
    projectAFE: {
      error: false,
      message: "",
    },
    projectName: {
      error: false,
      message: "",
    },
    projectManager: {
      error: false,
      message: "",
    },
    stateId: {
      error: false,
      message: "",
    },
    address: {
      error: false,
      message: "",
    },
    addressZipCode: {
      error: false,
      message: "",
    },
    coordinates: {
      error: false,
      message: "",
    },
    coordinatesZipCode: {
      error: false,
      message: "",
    },
  };

  const [newProjectForm, setNewProjectForm] = useState(initialValues);
  const [newProjectErrorForm, setNewProjectErrorForm] =
    useState(initialValuesError);

  useEffect(() => {
    if (companies?.companies.length === 1) {
      setNewProjectForm({
        ...newProjectForm,
        companyId: companies.companies[0].id,
      });
    }
  }, [companies]);

  useEffect(() => {
    if (isSuccess) {
      setOpenCreate(true);
      setNewProjectForm({
        companyId: projectInfo?.data.companyId,
        ownerCompany: projectInfo?.data.ownerCompany,
        projectAFE: projectInfo?.data.projectAFE,
        projectName: projectInfo?.data.projectName,
        projectManager: projectInfo?.data.projectManager,
        stateId: projectInfo?.data.stateId,
        address: projectInfo?.data.address,
        addressZipCode: projectInfo?.data.addressZipCode,
        coordinates: projectInfo?.data.coordinates,
        coordinatesZipCode: projectInfo?.data.coordinatesZipCode,
      });
    }
  }, [isSuccess]);

  const handleDetail = (id, projectName, projectAFE) => {
    navigate(
      `/projects/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  const handleDocuments = (id, projectAFE, projectName) => {
    navigate(
      `/documents/${id}?projectAFE=${projectAFE}&projectName=${projectName}`
    );
  };

  const handleUpdate = (id) => {
    setProjectId(id);
    setOpenUpdate(true);
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    if (dataProjectsList?.data?.projects?.items.length > 0) {
      const dataTable = dataProjectsList.data.projects.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}`,
            id: ele.id,
            name: "Details",
            icon: show,
            onClick: () =>
              handleDetail(ele.id, ele.projectName, ele.projectAFE),
          },
          {
            key: `1${i}`,
            id: ele.id,
            name: "Edit",
            icon: edit,
            onClick: () => handleUpdate(ele.id),
          },
          {
            key: `1${i}`,
            id: ele.id,
            name: "Documents",
            icon: file,
            onClick: () =>
              handleDocuments(ele.id, ele.projectAFE, ele.projectName),
          },
        ],
      }));

      setDataProjects(dataTable);
      setTotalItems(dataProjectsList?.data?.projects.totalItems);
    } else {
      setDataProjects([]);
    }
  }, [dataProjectsList?.data]);

  useEffect(() => {
    if (companies?.companies.length > 0) {
      setCompaniesOptions(
        companies?.companies.map((ele) => ({
          value: ele.id,
          label: ele.companyName,
        }))
      );
    }
  }, [companies]);

  useEffect(() => {
    if (countries?.length > 0) {
      setCountriesOptions(
        countries?.map((ele) => ({
          value: ele.countryId,
          label: ele.countryName,
        }))
      );
    }
  }, [countries]);

  useEffect(() => {
    if (states?.length > 0) {
      setStatesOptions(
        states.map((ele) => ({
          value: ele.stateId,
          label: ele.stateName,
        }))
      );
    }
  }, [states]);

  const tableColumns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 180,
      render: (text) => <div style={{ width: "180px" }}>{text}</div>,
    },
    {
      title: "Owner Company",
      dataIndex: "ownerCompany",
      key: "ownerCompany",
      width: 180,
      render: (text) => <div style={{ width: "180px" }}>{text}</div>,
    },
    {
      title: "Project AFE",
      dataIndex: "projectAFE",
      key: "projectAFE",
      width: 120,
      render: (text) => <div style={{ width: "120px" }}>{text}</div>,
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: 200,
      render: (text) => <div style={{ width: "200px" }}>{text}</div>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 180,
      render: (actions) => (
        <Div gap="60px">
          {actions.map((action, i) => (
            <Link
              key={i}
              onClick={action.onClick}
              color={theme.colors.salmon}
              icon={
                <img
                  alt={action.name}
                  src={action.icon}
                  style={{ marginRight: "16px" }}
                />
              }
              styles={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {action.name}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleNewProject = () => {
    setOpenCreate(true);
  };

  const reset = () => {
    setOpenCreate(false);
    setNewProjectErrorForm(initialValuesError);
    setNewProjectForm(initialValues);
    setErrorMessage("");
    setOpenUpdate(false);
    getProjectInfo.refetch();
    setForceUpdate(!forceUpdate);
  };

  const handleClose = () => {
    reset();
    setProjectId(null);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = newProjectErrorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setNewProjectErrorForm(newErrorForm);

    const projectForm = newProjectForm;
    projectForm[id] = value;
    setNewProjectForm(projectForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (id, value) => {
    const newErrorForm = newProjectErrorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setNewProjectErrorForm(newErrorForm);

    const projectForm = newProjectForm;
    projectForm[id] = value;
    setNewProjectForm(projectForm);
    setForceUpdate(!forceUpdate);
  };

  const companyName = (id) => {
    const company = companiesOptions.find((ele) => ele.value === id)?.label;

    return company;
  };

  const handleCreateProject = () => {
    const validation = validateNewProject.validate(newProjectForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = newProjectErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setNewProjectErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      create.reset();
      create.mutate(
        {
          ...newProjectForm,
          ownerCompany:
            newProjectForm.ownerCompany === ""
              ? companyName(newProjectForm.companyId)
              : newProjectForm.ownerCompany,
        },
        {
          onSuccess: () => {
            reset();
            data.refetch();
          },
          onError: (err) => {
            setErrorMessage(err.response.data.Errors[0]);
          },
        }
      );
    }
  };

  const handleUpdateProject = () => {
    const validation = validateNewProject.validate(newProjectForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = newProjectErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setNewProjectErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      update.reset();
      update.mutate(
        {
          ...newProjectForm,
          projectId: projectId,
          ownerCompany:
            newProjectForm.ownerCompany === ""
              ? companyName(newProjectForm.companyId)
              : newProjectForm.ownerCompany,
        },
        {
          onSuccess: () => {
            reset();
            data.refetch();
            setProjectId(null);
          },
          onError: (err) => {
            setErrorMessage(err.response.data.Errors[0]);
          },
        }
      );
    }
  };

  const handleChangeTable = (pagination) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container>
      <ModalProjects
        openCreate={openCreate}
        handleClose={handleClose}
        newProjectForm={newProjectForm}
        newProjectErrorForm={newProjectErrorForm}
        handleCreateProject={handleCreateProject}
        handleChange={handleChange}
        handleSelect={handleSelect}
        companiesOptions={companiesOptions}
        countriesOptions={countriesOptions}
        statesOptions={statesOptions}
        isLoading={isLoading}
        errorMessage={errorMessage}
        handleUpdateProject={handleUpdateProject}
        isLoadingUpdate={isLoadingUpdate}
        openUpdate={openUpdate}
      />
      <Div width="100%" height="55px" justify="space-between" align="center">
        <Div direction="column">
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            Your Database
          </Text>
        </Div>
        <Div gap="24px">
          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="324px"
            onChange={handleChangeSearch}
          />
          <Button background={theme.colors.salmon} onClick={handleNewProject}>
            New Project
          </Button>
        </Div>
      </Div>
      <Div m="32px 0 0 0">
        <Table
          columns={tableColumns}
          data={dataProjects}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: loading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((v, idx) => (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                    key={idx}
                  />
                ))}
              </Div>
            ) : (
              "No data"
            ),
          }}
        />
      </Div>
    </Container>
  );
};
