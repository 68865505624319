import decode from "jwt-decode";
import { axiosClient } from "../core/services/axiosInstance";
import axios from "axios";
import _ from "lodash";

export const getUserInfo = () => {
  const user = localStorage.getItem("user")
    ? decode(localStorage.getItem("user"))
    : {
        role: "",
      };
  return user;
};

export const uploadFiles = async (file) => {
  const formData = new FormData();
  formData.append("imagesFile", file);
  return axiosClient.post(`/Company/UploadLogo`, formData);
};

export const downloadFile = async (url, filename, instance, token) =>
  instance
    .post(
      url,
      {},
      {
        responseType: "blob",
        headers: { Authorization: token },
      }
    )
    .then(
      (response) => {
        const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.setAttribute("download", `${filename}`);
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      (e) => {
        return Promise.reject(e);
      }
    );

export const downloadExcel = async (url, filename, instance, token) =>
  instance
    .get(url, {
      responseType: "blob",
      headers: { Authorization: token },
    })
    .then((response) => {
      const excelUrl = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = excelUrl;
      a.setAttribute("download", `${filename}`);
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const dateFormat = (value) => {
  const d = new Date(value);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  if (month.toString().length < 2) month = `0${month}`;
  if (day.toString().length < 2) day = `0${day}`;

  return [day, month, year].join("/");
};

export const compareArrays = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = _.sortBy(array1, (obj) =>
    JSON.stringify(_.omit(obj, "key"))
  );
  const sortedArray2 = _.sortBy(array2, (obj) =>
    JSON.stringify(_.omit(obj, "key"))
  );

  for (let i = 0; i < sortedArray1.length; i++) {
    const obj1 = sortedArray1[i];
    const obj2 = sortedArray2[i];
    if (!_.isEqual(_.omit(obj1, "key"), _.omit(obj2, "key"))) {
      return false;
    }
  }

  return true;
};

const Fraction = require("fraction.js");

export const calculateTotalLength = (valor) => {
  if (valor.every((ele) => ele === null)) {
    return "";
  }

  const validElements = valor.filter((ele) => ele !== null);

  const convert = validElements.map((ele) => {
    const feet = ele.match(/(\d+)'/) && ele.match(/(\d+)'/)[1];

    const inches =
      ele.match(/(?:'|\s)(\d+(?:\/\d+)?)["'-]?/) &&
      ele.match(/(?:'|\s)(\d+(?:\/\d+)?)["'-]?/)[1];

    const fraction =
      ele.match(/(?:\d+(?:'|\s))?(?:(\d+\/\d+)|(\d+(?:-\d+)?"))/) &&
      ele.match(/(?:\d+(?:'|\s))?(?:(\d+\/\d+)|(\d+(?:-\d+)?"))/)[1];

    let fractionToPart = 0;
    let convertFraction = 0;

    if (fraction === undefined || fraction === null) {
      convertFraction = 0;
    } else {
      fractionToPart = fraction.split("/");
      const numerador = parseInt(fractionToPart[0]);
      const denominador = parseInt(fractionToPart[1]);
      convertFraction = numerador / denominador;
    }

    const convertFeetToInches = feet * 12;

    const totalInches =
      Number(convertFeetToInches) + Number(inches) + Number(convertFraction);

    return totalInches;
  });

  const sumTotalInches = _.sum(convert);

  const pies = Math.floor(sumTotalInches / 12);
  const pulgadas = Math.floor(sumTotalInches % 12);
  const fraccion = new Fraction(sumTotalInches % 1).toFraction();

  return `${pies}' ${pulgadas}-${fraccion}"`;
};

export const parseLength = (length) => {
  if (!length || length.trim() === "") {
    return "";
  }

  const parts = length.split("-");
  let integerPart = 0;
  let fraction = 0;

  if (parts.length === 1 && !parts[0].includes("/")) {
    integerPart = parseInt(parts[0]);
  } else if (parts.length === 1 && parts[0].includes("/")) {
    const doublePart = parts[0].split("/");
    const numerator = parseInt(doublePart[0]);
    const denominator = doublePart.length > 1 ? parseInt(doublePart[1]) : 1;
    fraction = numerator / denominator;
  } else {
    integerPart = parseInt(parts[0]);
    const doublePart = parts[1].split("/");
    const numerator = parseInt(doublePart[0]);
    const denominator = doublePart.length > 1 ? parseInt(doublePart[1]) : 1;
    fraction = numerator / denominator;
  }

  const total = integerPart + fraction;

  return total;
};
