import React from "react";

import { TableSkeleton } from "./Skeleton";

import { Wrapper, CustomTable } from "./styles";

export const Table = ({ columns, data, sizerLabels, small, ...rest }) => {
  return (
    <Wrapper>
      <CustomTable
        columns={columns}
        dataSource={data}
        sizerLabels={sizerLabels}
        small={small}
        {...rest}
      />
    </Wrapper>
  );
};

Table.defaultProps = {
  sizerLabels: ["Show", "Results"],
};

Table.Skeleton = TableSkeleton;
