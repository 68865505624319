import styled from "styled-components";

export const WrapperName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 100%;
  background: ${(props) => props.color};
  position: absolute;
  top: ${(props) => props.location};
  left: ${(props) => props.location};
`;

export const WrapperLength = styled.div`
  display: flex;
  min-width: 50px;
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate});
`;
