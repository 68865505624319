import styled from "styled-components";
import { Table } from "antd";
import { theme } from "../../styles/theme";
import next from "../../assets/icons/next.svg";
import previous from "../../assets/icons/previous.svg";

export const Wrapper = styled.div`
  width: 100%;
`;

export const CustomTable = styled(Table)`
  .table-row-light {
    background-color: ${theme.colors.white};
  }
  .table-row-dark {
    background-color: ${theme.colors.light};
  }

  :where(.css-dev-only-do-not-override-sk7ap8).ant-table-wrapper .ant-table-column-sorter {
    position: absolute;
    right: 10px;
  }

  &&& {
    .ant-table {
      &-column-sorters {
        height: 20px;
        display: contents;
      }
      &-thead {
        th {
          padding: ${({ small }) => (small ? "4px 8px" : "10px 16px")};
          color: ${theme.colors.grey};
          font-size: ${({ small }) =>
            small ? theme.fonts.size.xs : theme.fonts.size.default};
          font-weight: ${theme.fonts.weight.regular};
          background-color: ${theme.colors.light3};
          border: 0;
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }

          &:before {
            display: none;
          }
        }
      }
      &-tbody {
        td {
          padding: ${({ small }) => (small ? "6px 8px" : "14px 16px")};
          color: ${theme.colors.grey1};
          font-size: ${({ small }) =>
            small ? theme.fonts.size.xs : theme.fonts.size.default};
          font-weight: ${theme.fonts.weight.regular};
        }
        .ant-table-cell-row-hover {
          background-color: transparent;
        }
      }
      &-column-title {
        flex: auto;
        padding: 16px 0;
      }
    }

    /* ### PAGINATION ### */
    .ant-pagination {
      gap: 4px;
      align-items: center;
      margin-top: 8px;

      &-item,
      &-next,
      &-prev {
        min-width: 40px;
        height: 40px;
        margin: 0;
        display: flex;
        align-items: center;

        &-link {
          width: 30px;
          height: 30px;
          background: ${theme.colors.white} 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 24px #00000015;
          border-radius: 7px;
          opacity: 1;
          align-items: center;

          &:disabled {
            background: ${theme.colors.light2} 0% 0% no-repeat padding-box;
          }

          .anticon-left {
            margin-top: 5px;
            &:after {
              content: url(${previous});
            }
            svg {
              display: none;
            }
          }

          .anticon-right {
            margin-top: 5px;
            &:after {
              content: url(${next});
            }
            svg {
              display: none;
            }
          }
        }
      }

      /* ### PAGINATION - ITEM ### */
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 8px;
        a {
          color: ${theme.colors.salmon};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.regular};
        }
        &:hover {
          background-color: ${theme.colors.light2};
          a {
            color: ${theme.colors.salmon};
          }
        }
        &-active {
          background-color: ${theme.colors.white};
          border: none;

          a {
            color: ${theme.colors.salmon};
          }
        }
      }

      /* ### PAGINATION - ITEMS PER PAGE */
      &-options {
        order: 1;
        margin-right: auto;
        margin-left: 0;

        .ant-select {
          cursor: default;
          display: flex;
          align-items: center;
          &:before {
            content: "${(props) => props.sizerLabels[0]}";
            margin-right: 12px;
          }
          &:after {
            content: "${(props) => props.sizerLabels[1]}";
            margin-left: -8px;
          }
          &:before,
          &:after {
            display: inline-block;
            color: ${theme.colors.grey2};
            font-size: ${theme.fonts.size.sm};
          }
          &-selector {
            cursor: pointer;
            flex: 1;
            border-radius: 7px;
            border: none;
            height: 30px;
            background: ${theme.colors.white} 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 24px #00000015;
          }
          &.ant-select-open {
            .ant-select-arrow {
              .anticon {
                transform: rotate(180deg);
              }
            }
          }
          &-arrow {
            position: relative;
            margin-top: 0;
            right: 28px;
            width: initial;
            height: initial;
            .anticon {
              color: ${theme.colors.salmon};
              font-family: "remixicon";
              font-size: 21px;
              line-height: 1;
              &:after {
                content: "\\ea4e";
              }
              svg {
                display: none;
              }
            }
          }
          &-selection-item {
            color: ${theme.colors.salmon};
            font-size: ${({ theme }) => theme.fonts.size.sm};
          }
          &-dropdown {
            min-width: 80px !important;

            .ant-select-item-option {
              color: ${theme.colors.salmon};

              &:hover {
                background-color: ${theme.colors.light3};
              }

              &-selected {
                background-color: ${theme.colors.light3};
              }
            }
          }
        }
      }

      &-prev {
        order: 2;
      }
      &-jump-prev {
        order: 3;
      }
      &-item {
        order: 3;
      }
      &-jump-next {
        order: 3;
      }
      &-next {
        order: 4;
      }
    }
  }
`;
